import React from 'react'
import styles from './styles.module.scss'

const Skeleton = ({ rows, type = 'invoice' }) => {

	switch (type) {
		case 'invoice':
			return <table className={styles.TableSkeleton}>
				<tbody>
					{Array.apply(null, { length: rows || 10 }).map((x,i) => (
						<tr key={i}>
							<td><div className={["rounded-sm bg-gray-200 block h-6 my-2"].join(' ')}></div></td>
							<td><div className={["rounded-sm bg-gray-200 block h-6 my-2", styles.Box].join(' ')}></div></td>
							<td><div></div></td>
						</tr>
						))
					}
				</tbody>
			</table>	
		
		case 'file': 
			return <div className={[styles.FileSkeleton].join(' ')}>
				{Array.apply(null, { length: rows * 2 || 8 }).map((x,i) => (
					<div key={i} className={["bg-gray-200 rounded shadow pt-32", styles.Box].join(' ')}></div>	
				))}
			</div>
		
		default:
			break;
	}
}

export default Skeleton;
