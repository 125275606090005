import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { FormField, FormFieldIban, RouterPrompt } from '../../../components'
import Button, { COLORS } from '../../../components/button'
import iban from 'iban';

class BankAccountForm extends Component {
	state = {
		initalized: false,
		data: {}
	}
	
	constructor(props) {
		super(props);
		
		this.update = this.update.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
	}

	componentDidUpdate() {
		this.initForm(this.props);
	}
		
	initForm(props) {
		if(!this.state.initalized && !props.loading && props.data) {
			this.setState({
				initalized: true,
				data: props.data
			})
		}
	}
	
	update(values) {
		return new Promise((resolve, reject) => {
			this.props.update(values)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	validate(values) {
  	const errors = {};
  	if(values.iban && !iban.isValid(values.iban)) errors.iban = "Die IBAN ist ungültig"
  	return errors;
  }
	
	render() {
		return (
			<Form 
				onSubmit={this.update}
				initialValues={this.state.data}
				validate={this.validate}
				render={({ handleSubmit, values, valid, form,  pristine, submitting }) => (
					<form onSubmit={e => {
							valid ?
								handleSubmit(e).then(() => form.reset(this.state.data)) : 
								handleSubmit(e); 
						}}>
						
						<div className="flex -mx-3">
							<div className="w-1/2 px-3">
								<FormField name="accountholder" label="Kontoinhaber*in" tag="user.accountholder" />
							</div>
						</div>
						
						<div className="flex -mx-3">
							<div className="w-7/12 px-3">
								<FormFieldIban name="iban" label="IBAN" tag="user.iban" />
							</div>
							<div className="w-5/12 px-3">
								<FormField name="bic" label="BIC/SWIFT" className="tnum" tag="user.bic" />
							</div>
						</div>
						
						<div className="flex -mx-3">
							<div className="w-1/2 px-3">
								<FormField name="accountbank" label="Name der Bank" tag="user.accountbank" />
							</div>
						</div>
						
						<Button
							text="Speichern"
							action="submit"
							disabled={submitting || pristine}
							working={submitting}
							color={COLORS.PRIMARY}
							className="mt-6" />
							
						<RouterPrompt 
							when={true}
							navigate={location => this.props.history.push(location)}
							shouldBlockNavigation={location => {
								if(!pristine) return true;
								return false;
							}} 
							action={() => handleSubmit()} />
					</form>	
				)}
			/>
		)
	}
}

export default BankAccountForm;
