import React from 'react';


export default function Page(props) {
	return (
		<div className="mt-32 mb-20 w-full">
			{props.children}
		</div>
	)
}
