import React, { Component } from 'react'
//import { CardElement, injectStripe } from 'react-stripe-elements'
import Button, { COLORS, SIZES } from '../button'


class CheckoutForm extends Component {
	state = {
				
	}
	
	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);
	}

	submit(e) {
		const { stripePayment, stripe, user } = this.props;
		// this.props.stripe.createToken({name: `${this.props.user.firstname} ${this.props.user.lastname}` })
		// 	.then(({ token }) => {
		// 		if(token) stripePayment(token.id, this.props.user)
		// 	})
			
		stripe.createPaymentMethod('card', {}, {
			billing_details: {
				name: `${user.firstname} ${user.lastname}`,
				email: user.email
			}
		})
		.then(({ paymentMethod, error}) => {
			
			if(paymentMethod) stripePayment(paymentMethod.id, this.props.user)
		})
	}

	render() {
		return (
			<div className="rounded p-4 bg-gray-300">
				{/*<CardElement />*/}
				<Button 
					action={this.submit}
					size={SIZES.LARGE}
					color={COLORS.PRIMARY}
					text="Abo abschließen"></Button>
			</div>
		);
	}
}



//export default injectStripe(CheckoutForm);
export default CheckoutForm;
