import { api, loadFromLocalStorage } from '../../helpers';

// import faker from '@faker-js/faker'
// import * as dayjs from 'dayjs'

const LOAD_CUSTOMERS_BEGIN = 'customers/LOAD_CUSTOMERS_BEGIN';
const LOAD_CUSTOMERS_ERROR = 'customers/LOAD_CUSTOMERS_ERROR';
const LOAD_CUSTOMERS_SUCCESS = 'customers/LOAD_CUSTOMERS_SUCCESS';

const ADD_CUSTOMER = 'customers/ADD_CUSTOMER';
const UPDATE_CUSTOMER = 'customers/UPDATE_CUSTOMER';
const DELETE_CUSTOMER = 'customers/DELETE_CUSTOMER';

const RESET_CUSTOMERS = 'customers/RESET_CUSTOMERS';

const SET_SORT				= 'customers/SET_SORT';

const loadCustomersBegin = () => ({
	type: LOAD_CUSTOMERS_BEGIN
})

const loadCustomersError = error => ({
	type: LOAD_CUSTOMERS_ERROR,
	payload: error
})

const loadCustomersSuccess = customers => ({
	type: LOAD_CUSTOMERS_SUCCESS,
	payload: customers
})

const addCustomer = customer => ({
	type: ADD_CUSTOMER,
	payload: customer
})

const updateCustomer = customer => ({
	type: UPDATE_CUSTOMER,
	payload: customer
})

const deleteCustomer = customer => ({
	type: DELETE_CUSTOMER,
	payload: customer
})

const resetCustomers = () => ({
	type: RESET_CUSTOMERS
})

const setSort = sort => ({
	type: SET_SORT, 
	payload: sort
})


function apiLoadCustomers() {
	
	// console.log("create random customers");
	
	// faker.setLocale('de');
	// for(let i = 2000; i < 3000; i ++) {
		
	// 	api('/customers', 'post', prepareCustomer({
	// 		title: faker.company.companyName(),
	// 		customerid: i,
	// 		company: faker.company.companyName(),
	// 		firstname: faker.name.firstName(),
	// 		lastname:  faker.name.lastName(),
	// 		addresslineone: faker.address.streetAddress(),
	// 		postcode: faker.address.zipCodeByState(),
	// 		city: faker.address.city(),
	// 		country: faker.address.country(),
	// 		// notes: ,
	// 		favorite: faker.datatype.boolean(),
	// 		email: faker.internet.exampleEmail(),
	// 		tel: faker.phone.phoneNumber(),
	// 	}))
	// 	.then(res => console.log(res));
	// }
		
	return dispatch => {
		dispatch(loadCustomersBegin());
		
		return api('/customers')
      .then(res => res.json())
      .then(json => {
      	dispatch(loadCustomersSuccess(json));
      })
      .catch(err => dispatch(loadCustomersError(err)))
	}
}

function apiAddCustomer(props = {}) {
	let status;
	
	return dispatch => {
		return api('/customers', 'post', props)
			.then(res => {
				status = res.status;
				return res.json();
			})
			.then(json => {
      	if(status === 201) {
      		// console.log(json);
	      	dispatch(addCustomer(json));
	      	return json.item;
      	}
      })
      .catch(console.log)
	}
}

function apiUpdateCustomer(id, props) {
	let status;
	
	return dispatch => {
		return api(`/customers/${id}`, 'put', props)
			.then(res => {
				status = res.status;
				return res.json();
			})
      .then(json => {
      	if(status === 200) {
    			dispatch(updateCustomer(json));
    			return json.item;
      	}
      })
      .catch(console.log)
	}
}

function apiDeleteCustomer(id) {
	return dispatch => {
		return api(`/customers/${id}`, 'delete')
      .then(res => res.json())
      .then(json => {
      	dispatch(deleteCustomer({id}));
      })
      .catch(console.log)
	}
}

function dispatchSetSort(sort) {
	return dispatch => {
		dispatch(setSort(sort));
	}
}

export const customerActions = {
	loadCustomers: apiLoadCustomers,
	addCustomer: apiAddCustomer,
	updateCustomer: apiUpdateCustomer,
	deleteCustomer: apiDeleteCustomer,
	resetCustomers: resetCustomers,
	setSort: dispatchSetSort
}


export default function reducer(state = defaultState, { type, payload }) {
	let list = {};
	
	switch(type) {
		case LOAD_CUSTOMERS_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			}
			
		case LOAD_CUSTOMERS_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error,
			}
			
		case LOAD_CUSTOMERS_SUCCESS:
			list = payload.list.reduce((acc, x) => {
				acc[x.id] = x;
				return acc;
			}, {})
			
			return {
				...state,
				loading: false,
				error: null,
				tags: payload.tags,
				list
			}
			
		case ADD_CUSTOMER: 
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state,
				loading: false,
				tags: payload.tags,
				list
			}
			
		case UPDATE_CUSTOMER:
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state,
				loading: false,
				tags: payload.tags,
				list
			}
			
		case DELETE_CUSTOMER:
			list = { ...state.list };
			delete list[payload.id];
			
			return {
				...state,
				list
			}
		
		case RESET_CUSTOMERS:
			return defaultState
			
		case SET_SORT:
			return {
				...state, 
				sortBy: payload
			}
			
			
		default:
			return state
	}
}

const savedSettings = loadFromLocalStorage('customers') || { sortBy: {} };

const defaultState = {
	loading: null, // null = not initated, false = nicht ladend, true = ladend
	path: 'customers',
	error: null,
	tags: [],
	list: {},
	sortBy: {
		...savedSettings.sortBy
	},
}

// selectors

function getCustomerById(state, id = null) {
	return state.customers.list[id] || false;
}

function getCustomersList(state, useSort = false) {
	const customers = state.customers;
	let list = Object.values(customers.list || []);
		
		//filter
		
		//sort
		let sort = !useSort ? customers.sortBy : useSort;
		
		if(Object.keys(sort).length) {
			let numberFields = ["customerid", "favorite"],
				stringFields = ["title"];
				
			let sorter = null;
			
			if(numberFields.indexOf(sort.field) !== -1) {
				// numbers
				list.sort((x, y) => {
					if(y[sort.field] > x[sort.field]) sorter = 1;
					if(y[sort.field] < x[sort.field]) sorter = -1;
					return sorter !== null ? sorter * sort.direction : 0;
				})
			} else if(stringFields.indexOf(sort.field) !== -1) {
				// strings
				list.sort((x, y) => {
					if(!x[sort.field]) return 1 * sort.direction;
					if(!y[sort.field]) return -1 * sort.direction;
					return x[sort.field].localeCompare(y[sort.field]) * sort.direction;
				})
			} 
				
		} else {
			list.sort((x, y) => {
				if(y.id > x.id) return 1;
				if(y.id < x.id) return -1;
				return 1;
			})	
		}
		
		return list;
}

function getCurrentCustomerNumber(state) {
	const customers = Object.values(state.customers.list)
		.filter(x => x.intrash === false);
		
	if(!customers.length) return 0;
	
	return customers.sort((x, y) => x.customerid - y.customerid)
		.pop()
		.customerid;
}

function getCustomerByInvoiceId({ invoices, customers } , invoiceid) {
	let invoice = invoices.list[invoiceid];
	
	if(!invoice || !invoice.customerid) return false;

	return Object.values(customers.list).filter(x => {
		return x.id === invoice.customerid;
	}).pop() || [];
}

export const customerSelectors = {
	getCustomersList,
	getCustomerById,
	getCurrentCustomerNumber,
	getCustomerByInvoiceId
}
