import { germanDateToMysqlDate } from './format';


const api = (url, method = 'get', body) => {
	const API_URL = process.env.REACT_APP_API;
	
	if(process.env.NODE_ENV === 'development') console.log('api:', method.toUpperCase(), API_URL + url, body);
	
	return fetch(API_URL + url, fetchHeader(method, body))
}

const fetchHeader = (method, body) => {
	let token;
	try {
		token = localStorage.getItem('token');
	} catch(err) {
		console.error(err);
	}

	const header = {
		method: method,
		headers: {
			'Content-Type': 'application/json'
		}
	}
	
	if(token) {
		header.headers = {
			...header.headers,
			Authorization: token
		}
	}
	
	if(body) {
		header.body = JSON.stringify(body);
	}
	
	return header;
}

const prepareCustomer = data => {	
	const { title, customerid, company, firstname, lastname, addresslineone, addresslinetwo, postcode, city, country, mannerofaddress, notes, favorite, email, tel, tags } = data;
	
	let preparedCustomer = {
		...(valid({title})),
		...(valid({customerid}, "int")),
		...(valid({company})),
		...(valid({firstname})),
		...(valid({lastname})),
		...(valid({addresslineone})),
		...(valid({addresslinetwo})),
		...(valid({postcode})),
		...(valid({city})),
		...(valid({country})),
		...(valid({mannerofaddress}, "bool")),
		...(valid({notes})),
		...(valid({favorite}, "bool")),
		...(valid({email})),
		...(valid({tel})),
		...(valid({tags}, "array"))
	};
	
	return preparedCustomer;
}

const prepareInvoice = data => {	
	const { title, headline, invoicenumber, invoicedate, receiver, sidebar, header, body, positions, conclusion, footer1, footer2, footer3, customerid, deadline, status, exported, total, tags } = data;
	
	let preparedInvoice = {
		...(valid({title})),
		...(valid({headline})),
		...(valid({invoicenumber}, "int")),
		...(valid({invoicedate: invoicedate !== "" ? germanDateToMysqlDate(invoicedate) : invoicedate})),
		...(valid({receiver})),
		...(valid({sidebar})),
		...(valid({header})),
		...(valid({body})),
		...(valid({positions})),
		...(valid({conclusion})),
		...(valid({footer1})),
		...(valid({footer2})),
		...(valid({footer3})),
		...(valid({customerid}, "int")),
		...(valid({status})),
		...(valid({deadline})),
		...(valid({total}, "float")),
		...(valid({exported: exported !== "" ? germanDateToMysqlDate(exported) : exported})),
		...(valid({tags}, "array"))
	};

	return preparedInvoice;
}

const prepareTemplate = data => {	
	const { title, headline, sidebar, header, receiver, body, positions, conclusion, footer1, footer2, footer3, styles, fonts, currency, deadline } = data;
	
	let preparedTemplate = {
		//...(valid({hash})),
		...(valid({title})),
		...(valid({headline})),
		...(valid({receiver})),
		...(valid({sidebar})),
		...(valid({header})),
		...(valid({body})),
		...(valid({positions})),
		...(valid({conclusion})),
		...(valid({footer1})),
		...(valid({footer2})),
		...(valid({footer3})),
		...(valid({styles})),
		...(valid({fonts})),
		...(valid({currency})),
		...(valid({deadline}, "int")),
	};
	
	return preparedTemplate;
}

const valid = (obj, type) => {
	if(typeof obj !== 'object') return {};
	
	let key = Object.keys(obj)[0],
		val = obj[key];
	
	if(val === undefined || val === null) {
		return {};
	} else if(val === "") {
		return { [key]: null }
	} else {
		switch(type) {
			case "int":
				return { [key]: isNaN(parseInt(val)) ? null : parseInt(val) };
			case "float":
				return { [key]: isNaN(parseFloat(val)) ? null : parseFloat(val) };
			case "bool":
				return { [key]: !!val ? 1 : 0 };
			case "array":
				return { [key]: typeof val === "object" ? val : [val] };
			default:
				return { [key]: val };
		}
	}
}

export { api, prepareInvoice, prepareCustomer, prepareTemplate }
