import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { FormField, RouterPrompt } from '../../../components'
import Button, { COLORS } from '../../../components/button'

class ShareMailForm extends Component {
	state = {
		initalized: false,
		data: {}
	}
	
	constructor(props) {
		super(props);
		
		this.update = this.update.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
	}

	componentDidUpdate() {
		this.initForm(this.props);
	}
		
	initForm(props) {
		if(!this.state.initalized && !props.loading && props.data) {
			this.setState({
				initalized: true,
				data: props.data
			})
		}
	}
	
	update(values) {
		return new Promise((resolve, reject) => {
			this.props.update(values)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	validate(values) {
  	const errors = {};
  	// if(values.iban && !iban.isValid(values.iban)) errors.iban = "Die IBAN ist ungültig"
  	return errors;
  }
	
	render() {
		return (
			<Form 
				onSubmit={this.update}
				initialValues={this.state.data}
				validate={this.validate}
				render={({ handleSubmit, values, valid, form,  pristine, submitting }) => (
					<form onSubmit={e => {
							valid ?
								handleSubmit(e).then(() => form.reset(this.state.data)) : 
								handleSubmit(e); 
						}}>
						
						<div >
							<FormField name="sharemailsubject" label="Betreff"/>
						</div>
						
						<div >
							<FormField name="sharemailbody" type="textarea" label="Inhalt"/>
						</div>

						<Button
							text="Speichern"
							action="submit"
							disabled={submitting || pristine}
							working={submitting}
							color={COLORS.PRIMARY}
							className="mt-6" />
							
						<RouterPrompt 
							when={true}
							navigate={location => this.props.history.push(location)}
							shouldBlockNavigation={location => {
								if(!pristine) return true;
								return false;
							}} 
							action={() => handleSubmit()} />
					</form>	
				)}
			/>
		)
	}
}

export default ShareMailForm;
