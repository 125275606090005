import React from 'react'
import Button, { SIZES, COLORS } from '../../components/button'
import { Icon, Alert } from '../../components'
import styles from './styles.module.scss'

// import {Elements, StripeProvider} from 'react-stripe-elements';


const tiers = [
	{
		tier: "beta",
		monthlyPrice: 0,
		title: "Early-Access",
		cta: "Auswählen",
		description: <ul className="list-disc ml-5">
			<li className="mb-2">max. 20 Kund*innen</li>
			<li className="mb-2">eine Rechnungs-Vorlage</li>
			<li className="mb-2">eigenes Format für Rechnungsnummern</li>
		</ul>,
		active: false
	}, 
	{
		tier: "free",
		monthlyPrice: 0,
		title: "Kostenloser Zugang",
		cta: "Auswählen",
		description: <ul className="list-disc ml-5">
			<li className="mb-2">max. 10 Kund*innen</li>
			<li className="mb-2">eine Rechnungs-Vorlange</li>
		</ul>,
		active: false
	}, 
	{
		tier: "solo",
		monthlyPrice: 7,
		title: "Solo",
		cta: "Auswählen",
		description: <ul className="list-disc ml-5">
			<li className="mb-2">unbegrenzte Kund*innen</li>
			<li className="mb-2">eine Rechnungs-Vorlage</li>
			<li className="mb-2">eigenes Format für Rechnungsnummern</li>
		</ul>,
		active: false
	}
]


const PlanSelector = props => {
	const { user } = props;
	const { tier } = user;
	
	return (
		<div>
			<h3 className="m-0 text-gray-800">Abo</h3>
			<p className="text-lg mb-2 mt-3"><strong>
				<span>Dein aktuelles Paket: </span>
				{tier === "beta" && <span className="text-green-500">Early-Access</span>}
				{tier === "free" && <span className="text-green-500">Test</span>}
				{tier === "solo" && <span className="text-green-500">Solo</span>}
			</strong></p>
			
			<div className="flex gap-5 mt-8">
				{tiers.map(x => (
					<div key={x.tier} className={["flex-1", tier === x.tier ? styles.active : null].join(" ")}>
						<div className={["flex flex-col justify-between h-full bg-gray-200 rounded-lg p-4 transition-colors duration-150 relative", tier === x.tier || x.active === false ? null : "hover:bg-gray-300 cursor-pointer"].join(" ")}>
							<div>
								<div className="flex justify-between items-center mb-3">
									<strong className="text-gray-700 text-lg">{x.title}</strong>
									{tier === x.tier && <div className="bg-green-400 text-white p-1 rounded-full text-sm"><Icon icon="check" svgClassName="w-4 h-4"/></div>}
								</div>
								
								<div className="flex flex-row justify-start items-end">
									<span className="text-green-500 text-5xl leading-tight">{x.monthlyPrice} € </span>	
									<span className="text-gray-600 mb-2 ml-2">/ Monat</span>
								</div>
								<div className="my-4">{x.description}</div>
							</div>
							{tier === x.tier ? 
								<Button 
									text={"Aktiv"}
									disabled={tier === x.tier}
									color={COLORS.PRIMARY}
									size={SIZES.MEDIUM}
									className="mt-4" /> :
								<Button 
									text={x.active ? x.cta : "Kommt bald"}
									disabled={!x.active}
									color={x.active ? COLORS.PRIMARY : COLORS.SECONDARY}
									size={SIZES.MEDIUM}
									className="mt-4" /> }
						</div>
					</div>
				))}
			</div>
			
			<div className="mt-8">
				<Alert>
					<div>Um dein Abo zu kündigen schreibe eine formlose E-Mail mit deinem Nutzernamen und dem gewünschten Kündigungsdatum an <a className="text-underline text-theme-500 font-medium" href="mailto:support@billu.app" rel="noopener noreferrer" target="_blank">support@billu.app</a>.</div>
				</Alert>
			</div>

			{/*<StripeProvider apiKey="pk_test_SFZGAkGT7bMHmmJdxSdws5eE00bCN3LTd7">
				<Elements>
					<CheckoutForm user={user} stripePayment={onStripePayment}/>
				</Elements>
			</StripeProvider>*/}
									
			
			{/*<p><strong>Kostenloser Beta-Zugang</strong></p>
			<Alert>Nächstes Zahlungsdatum</Alert>
			<Alert>Übersicht vergangener Zahlungen + PDF download</Alert>
			<Alert>Kündigung/Optionswechsel</Alert>
								<Alert>Supportkontakt</Alert>*/}
		</div>	
	)
}

export default PlanSelector;
