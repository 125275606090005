import React from 'react'
import { InvoicesForm } from './forms'
import { hasPermissions, path, CropTable } from '../../helpers'
import { ContextMenu } from '../../components'
import { Link } from 'react-router-dom'
import Button, { SIZES, COLORS } from '../../components/button'
import tableStyles from '../../styles/tables.module.scss'
import toast from 'react-hot-toast'

export const InvoicesSettings = props => {
	const { user, history } = props;
	const { onUpdateUser, templates } = props;
	
	const { invoicenumberformat, currentinvoicenumber } = user;
	const maxTemplates = hasPermissions("templates:add_max_1") ? 1 : hasPermissions("templates:add_max_3") ? 3 : hasPermissions("templates:add_max_10") ? 10 : null;
	
	// const allTags = getTags();
	// const invoicenumberTags = {
	// 	...allTags["invoice"],
	// 	...allTags["date"],
	// }
	
	return <div>
		<div className="border-gray-200 border-b-2 border-t-2 mt-8 py-8 flex">
			<div className="w-1/2">
				<h3 className="mt-0 text-gray-800">Rechnungsvorlagen</h3>
				<p className="text-gray-600">In deinem derzeitigen Paket kannst du maximal {maxTemplates} Vorlagen erstellen.</p>
				{maxTemplates !== null ? 
					<p className="my-3 px-2 py-1 rounded bg-green-200 text-green-900 table tracking-wider">{templates.list.length}/{maxTemplates}</p> : null
				}
				{templates.list.length < maxTemplates ?
					<Button
						text="Neue Vorlage erstellen"
						icon="add"
						link={path('templates', 'create')}
						color={COLORS.PRIMARY}
						size={SIZES.LARGE}
						className={["mt-0"]} /> : null
				}
			</div>
			<div className="w-1/2">
					
				{!templates.loading && templates.list.length ?
				<table>
					<thead>
						<tr>
							<th><span>Beschreibung</span></th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{templates.list.map((x, i) => <tr key={i}>
							<td className={tableStyles.Title}>
								<CropTable>
									<Link to={path('templates', x.id)} title={x.title}>{x.title}</Link>
								</CropTable>
							</td>
							<td>{x.id === user.templateid ? 
								<span className="p-1 leading-tight bg-green-200 text-green-900 rounded">Standard</span> : null
							}
							</td>
							<td className={tableStyles.ContextMenu}>
								<div>
									<ContextMenu items={[
										{
											label: 'Bearbeiten',
											icon: 'edit',
											link: path('templates', x.id)
										},
										{
											label: 'Als Standard setzen',
											icon: 'check',
											onClick: () => {
												onUpdateUser({
													templateid: x.id
												})
												.then(() => {
													toast.success(`„${x.title}“ als Standard-Vorlage gespeichert`)
												})
											}
										}
										]}
									/>
								</div>
							</td>
						</tr>)}
					</tbody>
				</table>: null }
			</div>
		</div>
		<div className="border-gray-200 border-b-2 py-8 flex justify-between">
			<div className="w-5/12 pr-8">
				<h3 className="mt-0 text-gray-800">Rechnungsnummer</h3>
				<p className="text-gray-600">Diese Einstellungen dienen als Grundlage für die Erhöhung deiner Rechnungsnummer. Du solltest sie nicht innerhalb eines laufenden Jahres verändern, da dadurch der Automatismus durcheinander kommen kann.</p>
				{/*<p className="text-gray-600">Verfügbare Platzhalter:</p>
				<TagList tags={Object.keys(invoicenumberTags)} />*/}
			</div>
			<div className="w-1/2 ">
				<InvoicesForm
					history={history}
					data={{ invoicenumberformat, currentinvoicenumber }}
					update={values => {
						return new Promise((resolve, reject) => {
							onUpdateUser(values)
								.then(values => {
									toast.success("Einstellungen gespeichert");
									resolve(values)
								});
						})
					}}/>
			</div>
		</div>
	</div>
}
