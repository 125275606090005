import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { FormField, Icon, Alert, RouterPrompt } from '../../../components'
import Button, { COLORS } from '../../../components/button'
import { HashLink } from 'react-router-hash-link'
import { tags, germanDate, hasPermissions } from '../../../helpers'

class InvoicesForm extends Component {	
	state = {
		initalized: false,
		data: {},
		invoiceIndex: 100
	}
	
	constructor(props) {
		super(props);
		
		this.update = this.update.bind(this);
		this.updateInvoiceIndex = this.updateInvoiceIndex.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
	}

	componentDidUpdate() {
		this.initForm(this.props);
	}
		
	initForm(props) {
		if(!this.state.initalized && !props.loading && props.data) {
			this.setState({
				initalized: true,
				data: props.data,
				invoiceIndex: props.data.currentinvoicenumber
			})
		}
	}
	
	update(values) {
		return new Promise((resolve, reject) => {
			this.props.update(values)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	updateInvoiceIndex(value) {
		this.setState({
			invoiceIndex: value
		})
	}
	
	render() {
		const { invoiceIndex } = this.state;
		
		return (
			<Form 
				onSubmit={this.update}
				initialValues={this.state.data}
				render={({ handleSubmit, values, valid, form, pristine, submitting }) => (
					<form onSubmit={e => {
							valid ?
								handleSubmit(e).then(() => form.reset(this.state.data)) : 
								handleSubmit(e); 
						}}>
						
						<h4 className="mt-0">Zähler für automatische Rechnungsnummern</h4>
						<Alert className="bg-red-200">Sei beim Ändern dieser Einstellungen besonders vorsichtig!</Alert>
						<FormField name="currentinvoicenumber" label="Aktuelle Position" className="tnum" tag="#" />
						
						
						<h4>Formatvorlage für deine Rechnungsnummer</h4>
						{/*<Alert>Hier kannst du das Format, in dem deine Rechnungs&shy;nummern in deine Rechnungen eingefügt werden, anpassen. Achte bei einer Änderung darauf, dass trotzdem alle Rechnungsnummern übersichtlich in einer Reihenfolge bleiben.</Alert>*/}
						
						{hasPermissions("settings:edit_invoicenumber") ? 
							<FormField name="invoicenumberformat" label="Format" className="tnum" tag="invoice.number" /> :
							<Alert className="">
								<div className="flex items-center justify-start">
									<Icon icon="lock" className="mr-2 text-red-600" />
									<span>Um das Format für deine Rechnungsnummern zu bearbeiten benötigst du mindestens das <strong>Solo-Paket</strong>. <HashLink smooth to="/settings/account#subscription" className="text-green-600 underline font-medium">Jetzt upgraden</HashLink>
									</span>
								</div>
							</Alert>
						}
						
						<div className="p-3 rounded bg-gray-200 mt-6">
							<h4 className="mt-1">So sieht's später aus:</h4>
							
							<div className="flex justify-between items-center mt-6">
								<div className="w-2/5 FormField">
									<span>R.-Nr.</span>
									<input type="number" defaultValue={values.currentinvoicenumber} onChange={e => this.updateInvoiceIndex(e.target.value)} />
								</div>
								
								<Icon icon="arrowRight" className="mx-2 mt-6 text-gray-500"/>
								
								<div className="w-full">
									<span>Vorschau</span>
									<span className="tnum text-gray-900 bg-gray-300 rounded-sm p-2 mt-1 border block h-10">
										{tags(values.invoicenumberformat || '', { invoice: {
											invoicenumber: invoiceIndex,
											invoicedate: germanDate(new Date())
										}}).string}
									</span>
								</div>
							</div>
						</div>
						
						<Button
							text="Speichern"
							action="submit"
							disabled={submitting || pristine}
							working={submitting}
							color={COLORS.PRIMARY}
							className="mt-6" />
						
						<RouterPrompt 
							when={true}
							navigate={location => this.props.history.push(location)}
							shouldBlockNavigation={location => !pristine}
							action={() => handleSubmit()} />
					</form>	
				)}
			/>
		)
	}
}

export default InvoicesForm;
