import React, { Component } from 'react'
import { Prompt } from 'react-router-dom'
import { Modal } from '../../components'
import Button, { COLORS } from '../../components/button'

export class RouterPrompt extends Component {
	state = {
		modalVisible: false,
		lastLocation: null,
		confirmedNavigation: false,
	}
	
	showModal = (location) => this.setState({
		modalVisible: true,
		lastLocation: location,
	})
	
	closeModal = (callback) => this.setState({
		modalVisible: false
	}, callback)
	
	handleBlockedNavigation = (nextLocation) => {
		const {confirmedNavigation} = this.state
		const {shouldBlockNavigation} = this.props
		
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
			this.showModal(nextLocation)
			return false
		}
		
		return true
	}
	
	handleConfirmNavigationClick = () => this.closeModal(() => {
		const {navigate} = this.props
		const {lastLocation} = this.state
		
		if (lastLocation) {
			this.setState({
				confirmedNavigation: true
			}, () => {
				// Navigate to the previous blocked location with your navigate function     
				// console.log('nicht speichern');
				navigate(lastLocation.pathname)
			})
		}
	})
	
	handleActionClick = () => {
		const { action } = this.props;
		
		action &&	action()
			.then(() => this.handleConfirmNavigationClick())
			.catch(console.error)
	}
	
	render() {
		const { when } = this.props
		const { modalVisible } = this.state
		
		return (
			<React.Fragment>
				<Prompt
					when={when}
					message={this.handleBlockedNavigation}/>
					
				{modalVisible && 
					<Modal width="sm">
						<div>
							<h3 className="mt-0 mb-4">Willst du deine Änderungen speichern?</h3>
							<p className="text-gray-700">Wenn du nicht speicherst gehen alle Änderungen verloren.</p>
							
							<div className="flex justify-between mt-5">
								<Button
									text="Abbrechen"
									action={() => this.closeModal()}
									color={COLORS.SECONDARY} 
									className="mt-0"/>
								<span className="flex-grow"></span>
								<Button
									text="Änderungen verwerfen"
									action={() => this.handleConfirmNavigationClick()}
									color={COLORS.SECONDARY} 
									className="mt-0 mr-3" />
								<Button
									text="Speichern"
									action={() => this.handleActionClick()}
									color={COLORS.PRIMARY}
									className="mt-0"/>
							</div>
						</div>
					</Modal> }
			</React.Fragment>
		)
	}
}

export default RouterPrompt;
