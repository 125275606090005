import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authActions, authSelectors } from '../../redux'
import { Page, Icon, Checkbox, Tag, ScrollToTop } from '../../components'
import Button, { COLORS, SIZES } from '../../components/button'
import { path, tags, germanDate, api } from '../../helpers'
import iban from 'iban'

import { Form, Field } from 'react-final-form'
import { FormField, FormFieldIban } from '../../components'
import { Link} from 'react-router-dom'

import confetti from 'canvas-confetti'

class Onboarding extends Component {
	
	state = {
		initalized: false,
		data: {}
	}
	submitFormFunction = {};
	
	constructor(props) {
		super(props);

		this.onUpdateUser = this.onUpdateUser.bind(this);
		this.getStep = this.getStep.bind(this);
	}
	
	componentDidMount() {		
		// if(!this.props.user.onboardingconfirmed) {
		// 	this.onUpdateUser({ onboardingconfirmed: true })	
		// }
		document.title = "Konto einrichten | Billu";
		this.initForm(this.props);
		
		this.props.history.listen((location, action) => {
			window.scroll({
			  top: 0,
			  behavior: 'smooth'
			});
		})
		
				
		const colors = ["#feb4b4", "#ffe999", "#64ce97", "#90cef4", "#e53e3e"];
		
		this.fire(0.25, {
		  spread: 26,
		  startVelocity: 55,
		  colors
		});
		this.fire(0.2, {
		  spread: 60,
		  colors
		});
		this.fire(0.35, {
		  spread: 100,
		  decay: 0.91,
		  scalar: 0.8,
		  colors
		});
		this.fire(0.1, {
		  spread: 120,
		  startVelocity: 25,
		  decay: 0.92,
		  scalar: 1.2,
		  colors
		});
		this.fire(0.1, {
		  spread: 120,
		  startVelocity: 45,
		  colors
		});
		
	}
	
	fire(particleRatio, opts) {
		var count = 200;
		var defaults = {
		  origin: { y: 0.7 }
		};

	  confetti(Object.assign({}, defaults, opts, {
	    particleCount: Math.floor(count * particleRatio)
	  }));
	}


	componentDidUpdate() {
		this.initForm(this.props);
		
		if(this.props.user.onboardingconfirmed) {
			//this.props.history.push(from);
			this.props.history.push({pathname: '/'});
		}
	}
	
	initForm({ user, loading }) {
		const { company, firstname, lastname, addresslineone, addresslinetwo, postcode, city, currentinvoicenumber, invoicenumberformat, novat, taxnumber, vatid, iban, bic, accountholder, accountbank } = user;
		
		if(!this.state.initalized && !loading && user) {
			this.setState({
				initalized: true,
				data: { company, firstname, lastname, addresslineone, addresslinetwo, postcode, city, currentinvoicenumber, taxnumber, vatid, iban, bic, accountholder, accountbank,
					invoicenumberformat: invoicenumberformat ? invoicenumberformat : "{{###}}",
					novat: novat ? novat : false
				}
			})
		}
	}
	
	goToPage(page, history) {
		history.push(path('setup', page + 1))
	}
	
	getStep(formProps) {
		const { history } = this.props;
		const { page, valid, submitting, values } = formProps;
		const pageCount = 4;
		
		switch (page) {
			
			case 0: 
				return <div className="text-center">
					<h2 className="mt-3 mb-8">Willkommen bei Billu</h2>
					
					<p>Damit du deine Rechnungen einfach und schnell rausschicken kannst brauchen wir zuerst ein paar Angaben von dir.</p>
					<br/>
					<p>Alles, was du jetzt eingibst, kannst du später in den Einstellungen immer wieder korrigieren.</p>
					
					<div className="flex items-center justify-center mt-8">
						<Button
							size={SIZES.LARGE}
							color={COLORS.PRIMARY}
							text="Los gehts"
							icon="arrowRight"
							iconPosition="right"
							className="w-1/2"
							link={path('setup', page + 1)}/>
					</div>
				</div>
				
				
				
			case 6:
				return <React.Fragment>
					<div className="flex items-center justify-between">
						<Link className="hover:text-theme-500 self-center inline-flex align-center text-gray-600" to={path('setup', page - 1)}>
							<Icon icon="chevronLeftSmall" className="mr-1"/>
							<span>Zurück</span>
						</Link>
						<span className="text-gray-600 top-0 right-0">Schritt {page}/{pageCount}</span>
					</div>
						
					
					<h2 className="mt-8 mb-6 text-center">Das wichtigste zuerst</h2>
					
					<p className="text-center text-md text-gray-700">Das Format für deine <strong>Rechnungsnummern</strong> kannst du selbst bestimmen. Dabei kannst du Buchstaben, Zahlen, Sonderzeichen und unsere <a href="https://www.notion.so/ludwigschmidt/Tempalte-Tags-einstellen-und-verwenden-0ae4e89600ed40ba92fbe28cd1e02da7" rel="noopener noreferrer" target="_blank" className="text-theme-500 underline">Platzhalter</a> einsetzen.</p>
					<br/>
					
					<div className="w-full pb-6 border-b-2">
						<table>
							<thead>
								<tr>
									<th><span>Beispielformat</span></th>
									<th><span>Wird zu</span></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td><span>{Tag({tag:"{{####}}"})}</span></td>
									<td><span>0010</span></td>
								</tr>
								<tr>
									<td><span>RE-{Tag({tag:"{{##}}"})}</span></td>
									<td><span>RE-10</span></td>
								</tr>
								<tr>
									<td><span role="img" aria-label="graph emoji">📈{"{{invoice.YY}}"}-{"{{###}}"}</span></td>
									<td><span role="img" aria-label="graph emoji">📈20-010</span></td>
								</tr>
							</tbody>
						</table>
						{/*<a href="#platzhalter" target="_blank" className="text-theme-500 underline mt-2 block">Mehr Beispiele ansehen</a>*/}
					</div>
					
					<div className="w-full">
						<FormField name="invoicenumberformat" label="Dein Format" className="tnum"/>
						<FormField name="currentinvoicenumber" type="number" label="Wo soll der Zähler anfangen?"/>
						
						<div className="p-3 rounded bg-gray-200 mt-6">
							<h4 className="mt-1">Vorschau</h4>
							<span className="tnum text-gray-900 bg-gray-300 rounded-sm p-2 mt-1 border block h-10">
								{tags(values.invoicenumberformat || '', { invoice: {
									invoicenumber: values.currentinvoicenumber || 1,
									invoicedate: germanDate(new Date())
								}}).string}
							</span>
						</div>
						
					</div>
					
					<div className="mt-6">
						<Button
							size={SIZES.LARGE}
							color={COLORS.PRIMARY}
							text="Weiter"
							working={submitting}
							icon="arrowRight"
							iconPosition="right"
							className="mt-0 mx-auto w-1/2"
							action={() => {
								this.submitFormFunction()
									.then(() => history.push(path('setup', page + 1)))
							}}/>
						<Link className="py-2 hover:text-theme-500 mt-3 mx-auto table text-gray-600" to={path('setup', page + 1)}>Überspringen</Link>
					</div>
				</React.Fragment>;

			case 1:
				return <React.Fragment>
					<div className="flex items-center justify-between">
						<Link className="hover:text-theme-500 self-center inline-flex align-center text-gray-600" to={path('setup', page - 1)}>
							<Icon icon="chevronLeftSmall" className="mr-1"/>
							<span>Zurück</span>
						</Link>
						<span className="text-gray-600 top-0 right-0">Schritt {page}/{pageCount}</span>
					</div>
					
					<h2 className="mt-8 mb-6 text-center">Deine Firmendaten</h2>
					
					<p className="text-center text-md mb-8 pb-6 text-gray-700 border-b-2 ">Deine Adresse steht später als Absender auf deinen Rechnungen</p>
					
					<div className="">
						<div>
							<FormField name="company" label="Firmenname" secondLabel="(optional)" className="mb-0"/>
						</div>
						<div className="flex -mx-3 -my-2">
							<div className="w-1/2 px-3">
								<FormField name="firstname" label="Vorname" className="mb-0"/>
							</div>
							<div className="w-1/2 px-3">
								<FormField name="lastname" label="Nachname" className="mb-0"/>
							</div>
						</div>
					
						<div>
							<FormField name="addresslinetwo" label="Adresszusatz" secondLabel="(optional)" placeholder="c/o, z. Hd., …" className="mb-0"/>
						</div>
						
						<div>
							<FormField name="addresslineone" label="Straße, Hausnummer" className="mb-0"/>
						</div>	
						
						<div className="flex -mx-3 -my-2">
							<div className="w-1/3 px-3">
								<FormField name="postcode" label="Postleitzahl" className="tnum" />
							</div>
							<div className="w-2/3 px-3">
								<FormField name="city" label="Stadt"/>
							</div>
						</div>
					</div>
					
					<div className="mt-6">
						<Button
							size={SIZES.LARGE}
							color={COLORS.PRIMARY}
							text="Weiter"
							working={submitting}
							icon="arrowRight"
							iconPosition="right"
							className="mt-0 mx-auto w-1/2"
							action={() => {
								this.submitFormFunction()
									.then(() => history.push(path('setup', page + 1)))
							}}/>
						<Link className="py-2 hover:text-theme-500 mt-3 mx-auto table text-gray-600" to={path('setup', page + 1)}>Überspringen</Link>
					</div>
				</React.Fragment>;
				
			case 2:
				return <React.Fragment>
					<div className="flex items-center justify-between">
						<Link className="hover:text-theme-500 self-center inline-flex align-center text-gray-600" to={path('setup', page - 1)}>
							<Icon icon="chevronLeftSmall" className="mr-1"/>
							<span>Zurück</span>
						</Link>
						<span className="text-gray-600 top-0 right-0">Schritt {page}/{pageCount}</span>
					</div>
					
					<h2 className="mt-8 mb-6 text-center">Nun zum Geld</h2>
					
					<p className="text-center text-md mb-8 pb-6 text-gray-700 border-b-2">Gib die Kontodaten an, auf die deine Kund/innen den Rechnungsbetrag überweisen sollen</p>
					
					<div className="">
						<FormField name="accountholder" label="Kontoinhaber*in" secondLabel="(falls abweichend)"/>
						<FormFieldIban name="iban" label="IBAN"/>
						<FormField name="bic" label="BIC" secondLabel="(optional)"/>
						<FormField name="accountbank" label="Name der Bank" secondLabel="(optional)"/>
					</div>
					
					<div className="mt-6">
						<Button
							size={SIZES.LARGE}
							color={COLORS.PRIMARY}
							text="Weiter"
							working={submitting}
							disabled={!valid}
							icon="arrowRight"
							iconPosition="right"
							className="mt-0 mx-auto w-1/2"
							action={() => {
								this.submitFormFunction()
									.then(() => history.push(path('setup', page + 1)))
							}}/>
						<Link className="py-2 hover:text-theme-500 mt-3 mx-auto table text-gray-600" to={path('setup', page + 1)}>Überspringen</Link>
					</div>
				</React.Fragment>;
				
			case 3:
				return <React.Fragment>
					<div className="flex items-center justify-between">
						<Link className="hover:text-theme-500 self-center inline-flex align-center text-gray-600" to={path('setup', page - 1)}>
							<Icon icon="chevronLeftSmall" className="mr-1"/>
							<span>Zurück</span>
						</Link>
						<span className="text-gray-600 top-0 right-0">Schritt {page}/{pageCount}</span>
					</div>	
					
					<h2 className="mt-8 mb-6 text-center">Fürs Finanzamt</h2>
					<p className="text-center text-md mb-8 pb-6 text-gray-700 border-b-2">Gib an, ob du dieses Jahr Umsatzsteuer berechnest und unter welcher Steuernummer du deine Einnahmen versteuerst.</p>
						
					<Field name="novat" type="radio" value={false} parse={value => value === "true"}>
						{({ input, meta }) => <label htmlFor="novat1" className={["p-4 pr-9 mt-0 mb-2 bg-white rounded-sm border focus-within:border-theme relative"].join(' ')}>
							<Checkbox {...input} name="novat1" id="novat1" value="false" checked={input.checked} type="radio" className="absolute top-0 right-0 mt-2 mr-2"/>
							<strong className="leading-snug">Umsatzsteuer berechnen</strong>
							<p className="mt-1 text-gray-600 leading-snug">Wenn du in deinen Rechnungen die Umsatzsteuer ausweist und an das Finanzamt abführst.</p>
						</label>}
					</Field>
					
					<span className="block text-center text-gray-600">oder</span>
					
					<Field name="novat" type="radio" value={true} parse={value => value === "true"}>
						{({ input, meta }) => <label htmlFor="novat2" className={["p-4 pr-9 mt-2 mb-4 bg-white rounded-sm border focus-within:border-theme relative"].join(' ')}>
							<Checkbox {...input} name="novat2" id="novat2" value="true" checked={input.checked} type="radio" className="absolute top-0 right-0 mt-2 mr-2"/>
							<strong className="leading-snug">Kleinunternehmer*innen-Regelung nutzen</strong>
							<p className=" mt-1 text-gray-600 leading-snug">Wenn dein Umsatz des letzen Jahres unter 17.500 € (22.000 € ab 2020) liegt und du laut <span className="whitespace-no-wrap">§ 19 UStG</span> als Kleinunternehmer*in giltst.</p>
						</label>}
					</Field>	
					
					<FormField name="taxnumber" label="Steuernummer" secondLabel="(optional)" className="tnum" placeholder="00/000/00000"/>
					<FormField name="vatid" label="Umstatzsteuer-ID" secondLabel="(optional)" className="tnum" />
					
					<div className="mt-6">
						<Button
							size={SIZES.LARGE}
							color={COLORS.PRIMARY}
							text="Weiter"
							working={submitting}
							disabled={!valid}
							icon="arrowRight"
							iconPosition="right"
							className="mt-0 mx-auto w-1/2"
							action={() => {
								this.submitFormFunction()
									.then(() => history.push(path('setup', page + 1)))
							}}/>
						<Link className="py-2 hover:text-theme-500 mt-3 mx-auto table text-gray-600" to={path('setup', page + 1)}>Überspringen</Link>
					</div>
				</React.Fragment>;
				
			case 4:
				return <React.Fragment>
					<div className="flex items-center justify-start">
						<Link className="hover:text-theme-500 self-center inline-flex align-center text-gray-600" to={path('setup', page - 1)}>
							<Icon icon="chevronLeftSmall" className="mr-1"/>
							<span>Zurück</span>
						</Link>
					</div>	
					
					<h2 className="mt-8 mb-6 text-center">Geschafft!</h2>
					<p className="text-center text-md mb-8 pb-6 text-gray-700 border-b-2">Die Grundeinrichtung ist fertig und du kannst jetzt deine erste Rechnung schreiben. <br/><br/>🎉Viel Spaß!</p>
						
					
					<div className="mt-6">
						<Button
							size={SIZES.LARGE}
							color={COLORS.PRIMARY}
							text="Jetzt loslegen"
							working={submitting}
							disabled={!valid}
							icon="arrowRight"
							iconPosition="right"
							className="mt-0 mx-auto w-1/2"
							action={() => {
								Promise.all([
									// subscribe to "Created Accounts" list
									api(`/users/subscribe`, 'put', { list: '35afabf3-d1f4-4119-84b7-2e504cc63a64' }),
									this.props.onUpdateUser({ onboardingconfirmed: true })
								])
							}}/>
					</div>
				</React.Fragment>;
				
				
			default: 
				return <React.Fragment>
					<span>Fehler</span>
				</React.Fragment>
		}
	}
	
	onUpdateUser(values) {
		return new Promise((resolve, reject) => {
			this.props.onUpdateUser(values)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	validate(values) {
  	const errors = {};
  	if(values.iban && !iban.isValid(values.iban)) errors.iban = "Die IBAN ist ungültig"
  	return errors;
  }
	
	render() {
		const { match } = this.props;
		
		const page = parseInt(match.params.page) || 0;
		
		return (
			<Page>
				<ScrollToTop />
				<div className="bg-white mx-auto p-10 rounded-lg relative max-w-lg">
					<Form 
						onSubmit={this.onUpdateUser}
						initialValues={this.state.data}
						validate={this.validate}
						render={formProps => {
							const { handleSubmit, valid, form } = formProps;
							this.submitFormFunction = handleSubmit;
							this.form = form;
							
							return (
								<form onSubmit={e => {
									valid ?
										handleSubmit(e).then(() => form.reset(this.state.data)) : 
										handleSubmit(e); 
									}}>
									
									{this.getStep({ ...formProps, page })}
									
									{/*<Step {...formProps} history={history} page={page} state={this.state} />*/}
								</form>)
						}}
					/>
				</div>
			</Page>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		user: authSelectors.getUser(state),
		loading: authSelectors.getAuth(state).loading
	}
}
	
const mapDispatchToProps = {
	onUpdateUser: authActions.updateUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
