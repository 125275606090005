import React, { useState } from 'react'

import Button, { SIZES, TYPES } from '../../components/button'
import { copyToClipboard } from '../../helpers'
import toast from 'react-hot-toast'
import styles from './styles.module.scss'

const tagToString = tag => `{{${tag}}}`;

const Tag = ({ brackets = true, tag, className, copyOnClick = false }) => {
	const tagString = tagToString(tag);
	
	return <span 
		onClick={() => {
			if(!copyOnClick) return false;
			copyToClipboard(tagString)
			toast(<div><Tag tag={tag} /> in die Zwischenablage kopiert</div>)
		}}
		className={[className, styles.Tag, copyOnClick ? "cursor-pointer" : null].join(' ')}>
			{brackets ? tagString : tag}
		</span>
}

const TagHint = ({ tag }) => {
	
	if(!tag) return null;
	
	const tagString = tagToString(tag);
	
	return <div 
		onClick={() => {
			copyToClipboard(tagString)
			toast(<div><Tag tag={tag} /> in die Zwischenablage kopiert</div>)
		}}
		data-tooltip={tagString} 
		className={[styles.Tag, "cursor-pointer"].join(' ')}>
			<span>{'{}'}</span>
		</div>
}

const TagList = ({ tags }) => {
	const [showAll, setShowAll] = useState(false);
	const limit = 4;
	
	if(!tags) return null;
	
	return <>
		<div className="flex flex-wrap gap-2 mt-3">
			{tags.slice(0, !showAll ? limit : 100).map((x, i) => <Tag key={i} tag={x} brackets={true} copyOnClick={true} />)}
		</div>
		<div onClick={() => setShowAll(!showAll)}>
			{tags.length > limit ?
				showAll ? 
					<Button text="Weniger anzeigen" type={TYPES.GHOST} size={SIZES.SMALL} icon="chevronUpSmall" /> :
					<Button text="Alle anzeigen" type={TYPES.GHOST} size={SIZES.SMALL} icon="chevronDownSmall" />
				: null
			}
		</div>
	</>
}

export { Tag, TagHint, TagList }
