import React from 'react'
import { ReactComponent as Checkmark } from './checkmark.svg'
import { ReactComponent as Deselect } from './deselect.svg'
import styles from './styles.module.scss'


const Checkbox = props => {
	const { name, id, label, checked, deselectAll = false, onChange, className, type = "checkbox", ...input } = props;
	
	return (
		<label className={["flex items-center", styles.Checkbox, className].join(' ')}>
			<div className={["p-2 hover:bg-gray-300 focus-within:bg-gray-300", styles.inputWrap].join(' ')}>
				<input 
					{...input}
					id={id}
					name={name}
					checked={checked}
					type={type} 
					onChange={onChange} 
					className={["m-0 focus:border-gray-500", styles.input, deselectAll ? styles.deselectAll : null].join(' ')}/>
				<span className={styles.svg}>
					{deselectAll ?
						<Deselect /> :
						<Checkmark />
					}
				</span>
			</div>
			{label &&
				<span className={["ml-1", styles.label].join(' ')}>{label}</span>
			}
		</label>
	)
}

export default Checkbox;

// inline-block w-auto mr-2 mt-0
