import React, { useState } from 'react'
import * as jwtDecode from 'jwt-decode'
import { LoadingIndicator, Skeleton, ContextMenu as ContextMenuComponent, Icon, Modal, SmallSelect, Alert, SortHeader, SelectionCheckbox } from '../../components'
import Button, { COLORS, SIZES, TYPES } from '../../components/button'
import { copyToClipboard, timestampToDate, getPrivateDownloadLink, getPublicDownloadLink, hasPermissions, path, CropTable, timeAgo, mailtoLink, tags } from '../../helpers'
import { HashLink } from 'react-router-hash-link'
import toast from 'react-hot-toast'
import { customerSelectors } from '../../redux'

import styles from './styles.module.scss'
import tableStyles from '../../styles/tables.module.scss'

const ReceiverMailInput = props => {
	const { receivermail, setReceivermail } = props;
	
	return <input 
		key="receivermail" 
		type="email" 
		id="receivermail" 
		placeholder="Empfänger E-Mail-Adresse" 
		value={receivermail} 
		onChange={e => setReceivermail(e.target.value)} 
		style={{marginTop: 0}} 
		className="bg-gray-100 mt-0" />
}

const Title = ({ href, title }) => {
	return <h4 className="my-0 block leading-tight">
		<a href={href} target="_blank" rel="noopener noreferrer" className={styles.Title} title={title}>
			<span className="inline-table align-baseline">{title}</span>
			<Icon icon="arrowRightUp" className="inline-block align-middle text-sm"/>
		</a>
	</h4>
}
	

const ListItemGrid = props => {
	const { x, user } = props;
	
	return <div className="flex flex-col p-4 bg-white border-2 border-gray-200 rounded-lg relative">
		<Title href={getPrivateDownloadLink(x.s3key, user.token, x.title)} title={x.title} />
		<div className="mt-2 flex-grow">
			<CreatedAt {...props} />
		</div>
		
		<div className="absolute top-0 right-0 mt-2 mr-2">
			<ContextMenu {...props} />
		</div>

		<div className="mt-3">
			<Actions {...props} />
		</div>
	</div>
}

const ListItemTable = props => {
	const { x, user, showSelection } = props;
	
	return <>
		<tr>
			{showSelection ? <td className={tableStyles.Select}>
					<SelectionCheckbox id={x.id} {...props} />
				</td> : null
			}
			<td className={tableStyles.Title}>
				<CropTable>
					<Title href={getPrivateDownloadLink(x.s3key, user.token, x.title)} title={x.title} />
				</CropTable>
				</td>
			<td className={tableStyles.Nowrap}>
				<CreatedAt {...props} />
			</td>
			<td className={tableStyles.Actions}>
				<Actions {...props} />
			</td>
			<td className={tableStyles.ContextMenu}>
				<ContextMenu {...props} />
			</td>
		</tr>
	</>
}

const ListItem = props => {
	const { x, listView, user } = props;
	
	const [showSharingModal, setShowSharingModal] = useState(false);
	
	let active = false,
		decodedToken = null,
		expires = false;
	
	try {
		decodedToken = jwtDecode(x.token);
		expires = timestampToDate(decodedToken.exp);
		active = decodedToken !== null && new Date() < expires;
	} catch {
		active = false;
	}
	
	const created = new Date(x.created),
		recently = new Date();
	recently.setMinutes(recently.getMinutes() - 5)
	
	const createdRecently = created > recently;
	
	const listItemProps = {
		setShowSharingModal,
		active, 
		expires,
		created,
		createdRecently,
		token: !!x.token
	}
	
	return <>
		{listView === "grid" ?
			<ListItemGrid {...props} {...listItemProps} /> :
			<ListItemTable {...props} {...listItemProps} />
		}
		{showSharingModal ? <SharingModal {...props} {...listItemProps} file={x} authToken={user.token} /> : null}
	</>
}


const List = props => {
	const { loading, list, limit, listView } = props;
	
	return <>
		{loading ? <Skeleton type="file" /> : null}
		{!loading ?
			list.slice(0, limit || 100).map((x, i) => {
				return <React.Fragment key={x.id}>
					<ListItem
						{...props} 
						x={x}
						/> 
					{listView === "grid" && list.length === 1 ? <span key="spacer"></span> : null}
				</React.Fragment>
			}) : null
		}
	</>
}

const CreatedAt = ({ createdRecently, created }) => {
	return <span className="block flex-grow">
		<div className={[tableStyles.TimeAgo, "inline-block", createdRecently ? "text-theme-500 font-medium" : "text-gray-600"].join(' ')}>
			{timeAgo(created)}
		</div>
	</span>
}

const ContextMenu = ({ x, user, active, setShowSharingModal, onUpdateFile }) => {
	return	<ContextMenuComponent items={[
		{
			label: active ? 'Freigabe bearbeiten' : 'Freigeben',
			icon: 'share',
			onClick: () => setShowSharingModal(true)
		},
		{
			label: 'PDF herunterladen',
			icon: 'download',
			link: getPrivateDownloadLink(x.s3key, user.token, x.title, true),
			target: '_blank'
		},
		{
			label: 'Freigabelink kopieren',
			icon: 'clipboard',
			hide: !active,
			onClick: () => {
				copyToClipboard(getPublicDownloadLink(x.shortlink));
			}
		},
		{
			label: 'Rechnung bearbeiten',
			hide: !x.invoiceid,
			icon: 'edit',
			link: path('invoices', 'edit', x.invoiceid)
		},
		{
			label: 'In den Papierkorb',
			icon: 'delete',
			onClick: () => onUpdateFile(x.id , { intrash: true, token: null }),
			color: 'red'
		}]} 
		position={["bottom right", "top right"]} />
}

const Actions = props => {
	const { x, token, active, setShowSharingModal } = props;
	
	return <React.Fragment>
		<div className="flex justify-start items-center">
			<Button 
				action={(e) => setShowSharingModal(true)}
				text={!token ? "Freigeben" : (
					!active ? "Freigabe abgelaufen" : "Freigabe aktiv"
				)}
				icon={!x.token ? "link" : (
					!active ? "brokenLink" : "link"
				)}
				className="mt-0"
				color={active ? COLORS.PRIMARY : COLORS.SECONDARY}
				size={SIZES.SMALL}/>
		</div>
		
	</React.Fragment>
}

const CreateLink = props => {
	const { id, sharing, canSetSharingDuration = true, sharingModalExpireDate, setSharingModalExpireDate, onShareFile } = props;
	
	return <React.Fragment>
		<div>
			<span className="inline-block">Erstelle einen sicheren Freigabelink, der für </span>
			<SmallSelect
				className="inline-block w-40 mx-2"
				defaultValue={sharingModalExpireDate}
				value={sharingModalExpireDate}
				options={[
					{value: "24h", label: "Einen Tag", isDisabled: !canSetSharingDuration},
					{value: "7d", label: "Eine Woche"},
					{value: "30d", label: "Einen Monat", isDisabled: !canSetSharingDuration},
					{value: "999d", label: "Für immer", isDisabled: !canSetSharingDuration}
					// {value: "", label: ""}
				]}
				onChange={val => setSharingModalExpireDate(val)}/>
			<span className="inline-block">gültig ist.</span>
			{!canSetSharingDuration ?
				<Alert className="">
					<div className="flex items-center justify-start">
						<Icon icon="lock" className="mr-2 text-red-600" />
						<span>Um eine andere Laufzeit für die Dateifreigabe einzustellen benötigst du mindestens das <strong>Solo-Paket</strong>. <HashLink smooth to="/settings/account#subscription" className="text-green-600 underline font-medium">Jetzt upgraden</HashLink></span>
					</div>
				</Alert> : null
			}
		</div>
		<Button 
			action={(e) => {
				onShareFile(id, sharingModalExpireDate.value, true)
					.then(file => {
						if(file.shortlink) {
							copyToClipboard(getPublicDownloadLink(file.shortlink))
							toast.success('Link in die Zwischenablage kopiert')
						} else {
							toast.error('Fehler beim erstellen des Links')
						}
					})
			}}
			text="Freigabelink erstellen"
			working={sharing === true}
			icon="link"
			size={SIZES.MEDIUM}
			color={COLORS.PRIMARY}
			className="mr-3"
			/>
	</React.Fragment>
}


const SharingModal = props => {
	
	const { file, authToken, token, active, expires, setShowSharingModal, onUpdateFile, customers, invoices, user } = props;
	const { id, title, sharing, shortlink, invoiceid } = file;
	
	const shareMailSubject = tags(user.sharemailsubject, { file }).string;
	const shareMailBody = tags(user.sharemailbody, { file }).string;
	
	const customer = customerSelectors.getCustomerByInvoiceId({ invoices, customers }, invoiceid);
	
	const [receivermail, setReceivermail] = useState(customer.email);
	const [sharingModalExpireDate, setSharingModalExpireDate] = useState({value: "7d", label: "Eine Woche"});
	
	const publicDownloadLink = getPublicDownloadLink(shortlink);
	const privateDownloadLink = getPrivateDownloadLink(file.s3key, authToken, title, true);
	
	let canSetSharingDuration = hasPermissions("files:set_sharing_duration");

	return ( 
		<Modal closeOnDocumentClick={true} closeOnEscape={true} onClose={() => setShowSharingModal(false)} className="relative" width="md">
			<h3 className="leading-tight mb-2 mr-6 mt-0">Freigeben</h3>
			
			<Button
				text={`${title} herunterladen`}
				icon="download"
				size={SIZES.SMALL}
				link={privateDownloadLink}
				target="_self"
				/>
				
			<div className="pt-6 mt-6 border-t"></div>
			
			<h4 className="mt-0 mb-2">Freigabelink erstellen</h4>
			{token ? <div>
				{active ? <div>
					<p className="mb-2 flex items-center">
						<Icon icon="check" className="text-green-500 mr-2" />
						<span>Diese Datei ist über einen sicheren Link erreichbar.</span>
					</p>
					
					<div className="flex justify-between my-2 gap-4">
						<div className="FormField text-gray-800 w-full">
							<input type="text" onFocus={e => e.target.select()} readOnly value={publicDownloadLink} style={{marginTop: 0}} className="bg-gray-100" />
						</div>
						<Button 
							action={(e) => {
								e.currentTarget.classList.remove('copied');
								void e.currentTarget.offsetWidth;
								e.currentTarget.classList.add('copied');
								copyToClipboard(publicDownloadLink)
								toast.success('Link in die Zwischenablage kopiert');
							}}
							text="Link kopieren"
							icon="clipboard"
							size={SIZES.MEDIUM}
							color={COLORS.PRIMARY}
							className="flex-shrink-0 mt-0 block"
							/>
					</div>
					
					<div className="flex justify-between items-center mt-1">
						<Button 
							action={() => onUpdateFile(id , { token: null, downloaded: null })}
							text="Freigabe beenden"
							icon="brokenLink"
							type={TYPES.GHOST}
							size={SIZES.SMALL}
							color={COLORS.DANGER}
							className="mt-0"
							/>
							
						<p className="text-gray-600 leading-tight"><span>Läuft</span> {timeAgo(expires)} <span> automatisch ab.</span></p>
						
					</div>
					<div>
						<div className="pt-6 mt-4 border-t"></div>
						<h4 className="mt-0 mb-2">Link per E-Mail verschicken <span className="text-sm p-1 bg-green-200 text-green-700 rounded leading-snug align-baseline ml-1">NEU</span></h4>
						<p>Erstellt einen neuen E-Mail-Entwurf in deinem E-Mail-Client.</p>
						<div className="flex justify-between my-2 gap-4  my-2">
							<div className="FormField text-gray-800 w-full">
								{/*<div className="mt-2 mb-1">
									<label htmlFor="receivermail" className="leading-tight my-0 whitespace-no-wrap">Empfänger E-Mail-Adresse (mehrere durch Komma getrennt)</label>
								</div>*/}
								<ReceiverMailInput receivermail={receivermail} setReceivermail={setReceivermail} />
							</div>
							<Button 
								link={mailtoLink(receivermail, shareMailSubject, shareMailBody)}
								target="_blank"
								// action={() => {
								// 	let url = mailtoLink(document.getElementById("receivermail").value, title, publicDownloadLink);
								// 	window.open(url, '_blank').focus();
								// }}
								text="E-Mail erstellen"
								icon="arrowRightUp"
								iconPosition="right"
								size={SIZES.MEDIUM}
								color={COLORS.PRIMARY}
								className="flex-shrink-0 mt-0 block"
								/>
						</div>
						<p className="text-gray-600">Du kannst die <HashLink smooth to="/settings/files#sharemail" className="text-blue-400 hover:text-blue-500 underline">Vorlage für die E-Mail bearbeiten.</HashLink></p>
					</div>
				</div> : <div>
					<Alert className="mb-4 mt-0"><p>Der Freigabelink für diese Datei ist {timeAgo(expires)} abgelaufen und sie ist jetzt nicht mehr von anderen Personen erreichbar.</p></Alert>
					
					<div>
						<CreateLink id={id} sharing={sharing} canSetSharingDuration={canSetSharingDuration} sharingModalExpireDate={sharingModalExpireDate} setSharingModalExpireDate={setSharingModalExpireDate} {...props} />
					</div>
				</div>}
			</div> : <div>
				<CreateLink id={id} sharing={sharing} canSetSharingDuration={canSetSharingDuration} sharingModalExpireDate={sharingModalExpireDate} setSharingModalExpireDate={setSharingModalExpireDate} {...props} />
			</div>}
			
			
			<div className="absolute top-0 right-0 mt-2 mr-2">
				<button className="flex items-center justify-around text-lg cursor-pointer rounded-sm w-8 h-8 text-gray-600 hover:bg-gray-300 hover:text-gray-800" onClick={() => setShowSharingModal(false)}>
					<Icon icon="cancel"/>
				</button>
			</div>
		</Modal>
	)
}



const FileList = props => {
	const { error, list, limit, aws, viewBy, sortBy, setSort } = props;
	
	const { showSortHeader, showSelection, showFooter, showGridToggle = false } = props;
	
	const listView = showGridToggle && viewBy === "list" ? "list" : "grid";
	
	return (
		<div className="relative">
			{error ? <p>{error}</p> : null}
			
			{listView === "grid" ? 
				<div className={["mt-4", listView === "grid" ? styles.FileList : ""].join(' ')}>
				
					{aws.loading ? <div className="flex flex-col p-4 bg-white border-2 border-gray-200 rounded-lg relative h-24"><LoadingIndicator /></div> : null}
					{aws.error ? <div className="flex flex-col justify-center p-4 bg-white border-2 bg-red-100 border-red-200 rounded-lg text-red-700 relative h-24"><strong>Fehler beim erstellen der Datei:</strong> <span className="font-mono">{aws.error}</span></div> : null}
					
					{!!list.length ? 
						<List 
							{...props} 
							listView={listView} 
							onUpdateFile={props.onUpdateFile} 
							/> : 
						aws.loading || aws.error ? null :
							<div className="mt-4">
								<span>Keine Dateien gefunden.</span>
							</div> 
					}
				</div> : <table>
					<thead>
						<tr>
							{showSelection ? 
								<th className={[tableStyles.Select, tableStyles.SelectHeader].join(' ')}>
									<SelectionCheckbox isHeader={true} {...props} />
								</th> : null
							}
							<th>
								{showSortHeader ? <SortHeader title="Dateiname" field="title" {...{sortBy, setSort}} /> : <span>Dateiname</span>}
							</th>
							<th>
								{showSortHeader ? <SortHeader title="Erstellt" field="created" {...{sortBy, setSort}} /> : <span>Erstellt</span>}
							</th>
							<th>
								{showSortHeader ? <SortHeader title="Freigabe" field="token" {...{sortBy, setSort}} /> : <span>Freigabe</span>}
							</th>
							<th className={tableStyles.ContextMenu}></th>
						</tr>
					</thead>
					<tbody>
						{!!list.length ? 
							<List 
								{...props} 
								listView={listView} 
								onUpdateFile={props.onUpdateFile} 
								/> :
							<tr>
								<td colSpan={10}>
									<span>Keine Dateien gefunden.</span>
								</td>
							</tr>
						}
					</tbody>
					{showFooter ?
						<tfoot>
							<tr className="text-gray-500">
								<td colSpan={showSelection ? 5 : 4} className="">
									<span><span className="text-gray-700">{list.length}</span> Dateien gefunden</span>
									{list.length > limit && <span className="ml-1">({limit} angezeigt)</span>}
								</td>
							</tr>
						</tfoot> : null
					}
				</table>
			}
		</div>
	);
}


export default FileList;
