import React from 'react'
import { germanDate } from '../../helpers'
import Button, { SIZES } from '../../components/button'
import { NotificationForm } from './forms'
import toast from 'react-hot-toast'

import PlanSelector from './planSelector'

export const AccountSettings = props => {
	
	const onUpdateUser = values => {
		return new Promise((resolve, reject) => {
			props.onUpdateUser(values)
				.then(values => {
					toast.success("Einstellungen gespeichert");
					resolve(values)
				});
		})
	}
	
	const { user, history } = props;
	
	const { username, email, registered, notificationsettings } = user;	
	
	return <div>
		<div className="border-gray-200 border-b-2 border-t-2 mt-8 py-8 flex">
			<div className="w-1/2">
				<h3 className="m-0 text-gray-800">Allgemein</h3>
			</div>
			<div className="w-1/2 leading-loose">
				<p><strong>Nutzername:</strong> {username}</p>
				<p><strong>E-Mail:</strong> {email}</p>
				{/*<Alert>Passwort ändern</Alert>*/}
				<p><strong>Registriert seit:</strong> {germanDate(registered)}</p>

				<p><strong>Passwort:</strong> ******* </p>
				<Button
					icon="changePassword"
					text="Passwort ändern"
					size={SIZES.MEDIUM}
					link="/reset-password"
				/>
			</div>
		</div>
		
		<div className="border-gray-200 border-b-2 py-8 flex">
			<div className="w-1/2">
				<h3 className="mt-0 text-gray-800 flex gap-2 items-center"><span>Benachrichtigungen</span> <span className="text-sm p-1 bg-green-200 text-green-700 rounded leading-snug align-baseline ml-1">NEU</span></h3>
			</div>
			<div className="w-1/2 leading-loose">
				<NotificationForm 
					userId={user.id}
					data={{ notificationsettings }}
					update={onUpdateUser}
					history={history} />
			</div>
		</div>
		
		
		<div className="border-gray-200 border-b-2 py-8 " id="subscription">
			<PlanSelector 
				user={user} />
		</div>	
	</div>
}
