import React, { Component } from 'react'
import { Field } from 'react-final-form'
import { Checkbox } from '../'


class FormFieldPassword extends Component {
	state = {
		showPassword: false
	}
	
	constructor(props) {
		super(props);
		this.toggleShowPassword = this.toggleShowPassword.bind(this);
	}
	
	toggleShowPassword(e) {
		this.setState({
			showPassword: e.target.checked
		})
	}
	
	render() {
		const { name, label, strength, className} = this.props;

		return (
			<Field name={name} type="password" {...this.props.parse}>
				{({ input, meta: { error, submitError, touched, dirty } }) => {

					let strengthColor = ['bg-red-400', 'bg-orange-400', 'bg-yellow-400', 'bg-green-400', 'bg-green-400', 'bg-green-400'][strength],
						strengthText = 		['sehr schwach', 'schwach', 'gut', 'stark', 'sehr stark', 'sehr stark'][strength];
					
					return (
						<div className={[(touched && (error || submitError)) ? "has-error" : null, className, 'FormField'].join(' ')} >
		        	<div className="mt-4 mb-2">
		        		<div className="flex justify-between items-end">
			        		<label htmlFor={name} className="leading-tight my-0">{label}</label>
			        		{(error || submitError) && touched && 
			        			<span className="text-red-500 text-sm leading-tight">{error}</span>
			        		}
		        		</div>
		        	
			        	<div className="relative">
		        			<div>
			            	<input {...input} id={name}type={this.state.showPassword ? 'text' : 'password'}/>
			            	
										<Checkbox 
											id="togglePassword" 
											type="checkbox" 
											label="Passwort einblenden"
											onChange={this.toggleShowPassword} 
											className="select-none text-gray-700 -ml-2"/>
			            	
										<div className="flex -mx-1 items-center mt-4">
											<span className={["w-6 h-1 mx-1 rounded", (dirty && strength >= 0 ? strengthColor : 'bg-gray-400')].join(' ')}></span>
											<span className={["w-6 h-1 mx-1 rounded", (dirty && strength >= 1 ? strengthColor : 'bg-gray-400')].join(' ')}></span>
											<span className={["w-6 h-1 mx-1 rounded", (dirty && strength >= 2 ? strengthColor : 'bg-gray-400')].join(' ')}></span>
											<span className={["w-6 h-1 mx-1 rounded", (dirty && strength >= 3 ? strengthColor : 'bg-gray-400')].join(' ')}></span>
											<span className={["w-6 h-1 mx-1 rounded", (dirty && strength >= 4 ? strengthColor : 'bg-gray-400')].join(' ')}></span>
											<span className="pl-3 h-4 leading-none text-sm tracking-loose text-gray-600">{dirty && strengthText}</span>
										</div>
		        			</div>	
			        	</div>
			        </div>
		        </div>
				)}}
			</Field>
		)
	}
}

// function Status({ color }) {
// 	return <span className={
// 		["inline-block w-2 h-2 rounded-full mr-2",
// 		(color === "red" ? "bg-red-500" : "bg-green-500")].join(' ')}
// 	/>
// }

export default FormFieldPassword;
