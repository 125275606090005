import React from 'react'
import toast from 'react-hot-toast'

import { AddressForm, BankAccountForm, TaxesForm, ContactForm } from './forms'

export const DataSettings = props => {
	
	const onUpdateUser = values => {
		return new Promise((resolve, reject) => {
			props.onUpdateUser(values)
				.then(values => {
					toast.success("Einstellungen gespeichert");
					resolve(values)
				});
		})
	}
	
	const { user, history } = props;
	
	const { company, firstname, lastname, addresslineone, addresslinetwo, postcode, city, website, tel, taxnumber, vatid, iban, bic, accountholder, accountbank, novat, contactemail } = user;

	
	return <div>
		<div className="border-gray-200 border-b-2 border-t-2 mt-8 py-8 flex">
			<div className="w-1/2">
				<h3 className="m-0 text-gray-800">Deine Adresse</h3>
			</div>
			<div className="w-1/2 ">
				<AddressForm 
					userId={user.id} 
					data={{ company, firstname, lastname, addresslineone, addresslinetwo, postcode, city }}
					update={onUpdateUser} 
					history={history} />
			</div>
		</div>
		
		<div className="border-gray-200 border-b-2 py-8 flex">
			<div className="w-1/2">
				<h3 className="m-0 text-gray-800">Deine Kontaktdaten</h3>
			</div>
			<div className="w-1/2">
				<ContactForm 
					userId={user.id} 
					data={{ website, tel, contactemail }}
					update={onUpdateUser} 
					history={history} />
			</div>
		</div>
		
		<div className="border-gray-200 border-b-2 py-8 flex">
			<div className="w-1/2">
				<h3 className="m-0 text-gray-800">Deine Bankverbindung</h3>
			</div>
			<div className="w-1/2">
				<BankAccountForm 
					userId={user.id} 
					data={{ iban, bic, accountholder, accountbank }}
					update={onUpdateUser} 
					history={history} />
			</div>
		</div>
		
		<div className="border-gray-200 border-b-2 py-8 flex">
			<div className="w-1/2">
				<h3 className="m-0 text-gray-800">Steuer</h3>
			</div>
			<div className="w-1/2">
				<TaxesForm 
					userId={user.id} 
					data={{ taxnumber, vatid, novat }}
					update={onUpdateUser}
					history={history} />
			</div>
		</div>
		
	</div>
}
