import React, { Component } from 'react'
import { Page, Skeleton, ScrollToTop, SmallSelect, FileList, FileTrash } from '../../components'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { fileActions, fileSelectors } from '../../redux'
import Header from './header'

class Files extends Component {
	state = {
		selection: []
	}
	
	onUpdateSelection = selection => {
		this.setState({
			selection: selection
		})
	}
	
	render() {
		const { files } = this.props;
		const { loading, filterBy } = files;
		const { selection } = this.state;
		const { setFilter } = this.props;
				
		// const limit = 300;
		const isTrash = filterBy.trash.indexOf(true) !== -1;
		
			const years = [{value: 'auto', label: <span className="flex items-center gap-1">{/*<Icon icon="auto" svgClassName="w-4 h-4"/> */}Aktuelles Jahr</span>, color: 'var(--green-300)'}, {value: 2023, label: "2023"}, {value: 2022, label: "2022"}, {value: 2021, label: "2021"}, {value: 2020, label: "2020"}, {value: 2019, label: "2019"}],
				months = [{value: 'auto', label: "Aktueller Monat", color: 'var(--green-300)'}, {value: 0, label: "Januar"}, {value: 1, label: "Februar"}, {value: 2, label: "März"}, {value: 3, label: "April"}, {value: 4, label: "Mai"}, {value: 5, label: "Juni"}, {value: 6, label: "Juli"}, {value: 7, label: "August"}, {value: 8, label: "September"}, {value: 9, label: "Oktober"}, {value: 10, label: "November"}, {value: 11, label: "Dezember"}],
				sharing = [{value: true, label: "Freigabe aktiv", color: 'var(--green-300)'}, {value: false, label: "Freigabe inaktiv"}];
		
		return (
			<Page>
				<ScrollToTop />
				<Helmet>
					<title>Dateien | Billu</title>
				</Helmet>
				<div>
					<Header trash={isTrash} {...files} {...this.props} selection={selection} onUpdateSelection={this.onUpdateSelection} />
					
					{!isTrash &&
						<div className="w-full flex mb-6">
							<SmallSelect
								className="w-1/3" 
								defaultValue={years.filter(x => filterBy.year.indexOf(x.value) !== -1)}
								onChange={val => {
									if(val) {
										setFilter({ year: val.map(x => x.value) });
									} else {
										setFilter({ year: [] });
									}
								}}
								options={years}
								isMulti={true} 
								placeholder="Alle Jahre" />
								
							<SmallSelect
								className="w-1/3 ml-3" 
								defaultValue={months.filter(x => filterBy.month.indexOf(x.value) !== -1)}
								onChange={val => {
									if(val) {
										setFilter({ month: val.map(x => x.value) });
									} else {
										setFilter({ month: [] });
									}
								}}
								options={months}
								isMulti={true} 
								placeholder="Alle Monate" />
									
							<SmallSelect
								className="w-1/3 ml-3" 
								defaultValue={sharing.filter(x => filterBy.sharing.indexOf(x.value) !== -1)}
								onChange={val => {
									if(val) {
										setFilter({ sharing: val.map(x => x.value) });
									} else {
										setFilter({ sharing: [] });
									}
								}}
								// defaultValue={[stadiums[0], stadiums[2]]}
								options={sharing}
								isMulti={true} 
								placeholder="Alle Freigaben" />
							{/*<button onClick={() => setFilter({ year: [], status: [] })}>Filter zurücksetzen</button>*/}
						</div>
					}
					
					{loading ? (
						<Skeleton rows={10}/>
					) : (!isTrash ?
							<FileList 
								{...this.props}
								{...files}
								selection={this.state.selection}
								showGridToggle={true}
								showFooter={true}
								showSelection={true}
								showSortHeader={true}
								onUpdateSelection={this.onUpdateSelection}
								limit="200"
								/> :
							<FileTrash
								{...this.props} 
								{...files}
								selection={this.state.selection}
								onUpdateSelection={this.onUpdateSelection} />
					)}
					
				</div>
				<div></div>
			</Page>
		)
	}
}


const mapStateToProps = (state, props) => {
	const { viewBy, filterBy } = state.files;
	const listFilter = filterBy.trash.indexOf(true) !== -1 ? { year: [], month: [], sharing: [], trash: [true] } : {};
	
	return {
		invoices: state.invoices,
		customers: state.customers,
		user: state.auth.user,
		files: {
			...state.files,
			list: fileSelectors.getFilesList(state, listFilter, viewBy === "grid" ? {} : false)
		}
	}
}

const mapActionsToProps = {
	onDeleteFile: fileActions.deleteFile,
	onUpdateFile: fileActions.updateFile,
	onShareFile: fileActions.shareFile,
	setView: fileActions.setView,
	setFilter: fileActions.setFilter,
	setSort: fileActions.setSort
}

export default connect(mapStateToProps, mapActionsToProps)(Files);
