import React, { Component } from 'react'
import { Field } from 'react-final-form'
import { Checkbox, TagHint } from '../../components'

class FormField extends Component {
	
	render() {
		const { name, label, secondLabel, checkboxLabel, placeholder, type = "text", rows = 10, options , className, disabled, autoFocus, tag, removeChars } = this.props;
		
		return (
			<Field name={name} id={name} type={type} parse={value => {
				if(removeChars) value = value.replace(removeChars, "");
				if(typeof value === "string") return value.normalize("NFKC");
				return value;
			}}>
				{({ input, meta }) => (
	        <div className={[
	        	'FormField',
	        	(meta.touched && (meta.error || meta.submitError)) ? 'has-error' : null,
	        	disabled ? 'disabled' : '', 
	        	className].join(' ')} >
	        	<div className={[label ? "mt-6 mb-2" : ""].join(' ')}>
	        		{label && <div className="flex justify-between items-end mt-2 mb-1">
	        			<div className="flex items-center flex-wrap gap-1">
			        		<label htmlFor={name} className="leading-tight my-0 whitespace-no-wrap">
			        			{label}
			        			{secondLabel ? <span className="text-gray-500 ml-1">{secondLabel}</span> : null}
		        			</label>
			        			
			        		<TagHint tag={tag} />
		        		</div>	
		        		{(meta.error || meta.submitError) && meta.touched && 
		        			<span className="text-red-500 text-sm leading-tight">{meta.error || meta.submitError}</span>	
		        		}
	        		</div>}
		        	
		        	<div className="relative">
		        		{type === "text" && <input {...input} id={name} placeholder={placeholder} type="text" autoFocus={autoFocus} />}
		        		{type === "number" && <input {...input} id={name} placeholder={placeholder} type="number" autoFocus={autoFocus} />}
		        		{type === "textarea" && <textarea {...input} id={name} placeholder={placeholder} rows={rows} autoFocus={autoFocus}></textarea>}
		        		{type === "code" && (
		        			<textarea 
		        				{...input} 
		        				id={name} 
		        				placeholder={placeholder} 
		        				autoFocus={autoFocus} 
		        				rows={rows} 
		        				className="CodeEditor"
		        				onKeyDown={e => {
		        					if (e.key === 'Tab') {
										    e.preventDefault();
										    var start = e.target.selectionStart;
										    var end = e.target.selectionEnd;

										    // set textarea value to: text before caret + tab + text after caret
										    e.target.value = e.target.value.substring(0, start) + "\t" + e.target.value.substring(end);

										    // put caret at right position again
										    e.target.selectionStart =
										    e.target.selectionEnd = start + 1;
										    input.onChange(e.target.value)
										  }
		        				}}></textarea>
		        		)}
		        		{type === "checkbox" && (
		        			<Checkbox 
		        				{...input} 
		        				label={checkboxLabel || label}
		        				id={name} 
		        				checked={input.checked}/>
		        		)}
		        		
		        		{type === "password" && <input {...input} id={name} placeholder={placeholder} type="password"/>}
		        		{type === "select" && (
		        			<select {...input} id={name}>
		        				<option value={null}>Kund/in auswählen ...</option>
		        				{options && Object.keys(options).map(x => (
		        					<option value={x} key={x}>{options[x]}</option>
		        				))}
		        			</select>
		        		)}
		        	</div>
		        </div>
	        </div>
	      )}
			</Field>
		)
	}
}

export default FormField;
