import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import utc from 'dayjs/plugin/utc.js'
import 'dayjs/locale/de'

dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.locale('de')

const dateFormat = 'DD.MM.YYYY';


// numbers
const isNumber = val => isNaN(parseFloat(val)) ? 0 : parseFloat(val);
export const toFormattedNumber = (val, dec = ",") => {
  val = !!val ? val : 0;
  val = val.toString().replace(',', '.');
  return isNumber(val).toFixed(2).toString().replace('.', dec)
}

// euro
export const removeCurrency = val => val.toString().replace(/,/g, '.').replace(/(€|EUR|$|Euro)/g, '')
export const euroToNumber = val => {
  val = !!val ? val : 0;
  return parseFloat(removeCurrency(val)) || 0
}
export const numberToEuro = (val = "", currency = '€', dec = ",") => {
	return `${toFormattedNumber(val, dec)} ${currency}`
}

// const formatCurrency = number => {
// 	return new Intl.NumberFormat('de-DE', { style: 'currency', }).format(number)
// }

// percentage
export const percentToNumber = val => {
  val = !!val ? val : 0;
  return parseFloat(val.toString().replace(',', '.')) || 0;
}
export const numberToPercent = val => {
  val = !!val ? val : 0;
  return `${isNumber(val).toString().replace('.', ',')} %`;
}

// hours
export const hoursToNumber = val => parseFloat(val.replace('Stunden', '').replace(',', '.').trim())

export const formatHours = val => {
  let x = hoursToNumber(val),
      y = x.toString().replace('.', ',')
  return (x === 0 || isNaN(x)) ? '' : (x === 1 ? `${y} Stunde` : `${y} Stunden`)
}

// invoice number
export const formatRechnungsnummer = val => {
  let n = "0000" + val,
    year = new Date().getFullYear().toString().substr(-2)
  return `${year}-${n.slice(n.length-3)}`
}

// taxes
export const bruttoToNetto = val => val / 119 * 100
export const nettoToBrutto = val => val * 119 / 100

// date
export const germanDate = string => dayjs(string).isValid() ? dayjs(string).local().format(dateFormat) : '-'

export const germanDateToDate = string => {
	let date = dayjs(string, ['D.M.YY', 'D.M.YYYY', 'D.MM.YY', 'D.MM.YYYY', 'DD.M.YY', 'DD.M.YYYY', 'DD.MM.YY', 'DD.MM.YYYY'], 'de', true)
	return  date;
}

export const germanDateToMysqlDate = string => {
	if(!string) return null;
	let date = germanDateToDate(string) //.utc();
	if(!date.isValid()) return null;

	return date.format("YYYY-MM-DD");
	// return date.format("YYYY-MM-DD HH:mm:ss");
	// return date.toISOString().split('T')[0];
}

export const utcToLocal = date => {
	if(!date) return null;
	return dayjs(date).local();
}


export const timestampToDate = timestamp => {
  let d = new Date(0);
  d.setUTCSeconds(timestamp);
  return d;
}

export const dueDate = (date, deadline, germandate = false) => {
	let dayDate;
	if(germandate) {
		dayDate = germanDateToDate(date)
	} else {
		dayDate = dayjs(date)
	}
	
	if(!dayDate.isValid()) return false;
	
	let due = dayDate.add(deadline || 0, 'day');
	
	return {
		date: due.format(dateFormat),
		fromNow: due.fromNow(),
		overdue: !due.isBefore(dayjs()),
	};
}

export const timeAgo = _date => {
	let date = dayjs(_date);
	if(!date.isValid()) return false;
	
	return date.fromNow();
}



// address
export const formatAddress = user => {
	if(!user) return '';
	const { company, firstname, lastname, addresslineone, addresslinetwo, postcode, city, country } = user;
  let address = "";
  if(company) address += `${company}`;
  
  if(company && (firstname || lastname)) address += '\n';
  if(firstname) address += `${firstname} `;
  if(lastname) address += `${lastname}`;
  
  if(addresslineone) address += `\n${addresslineone}`;
  if(addresslinetwo) address += `\n${addresslinetwo}`;
  
  if(postcode || city) address += '\n';
  if(postcode) address += `${postcode} `;
  if(city) address += `${city}`;
  
  if(country) address += `\n${country}`;
  
  return address;
	// return`${company && company + '\n'}${(firstname && lastname) && firstname + ' ' + lastname + '\n'}${addresslineone && addresslineone + '\n'}${addresslinetwo && addresslinetwo}\n${(postcode && city) && postcode + ' ' + city + '\n'}`
};

export const formatAddressShort = user => {
  if(!user) return '';
  
  const { company, firstname, lastname, addresslineone, addresslinetwo, postcode, city } = user;
  let address = [];
  if(company) address.push(company);
  if(firstname || lastname) address.push(`${firstname} ${lastname}`.trim());
  if(addresslineone) address.push(addresslineone);
  if(addresslinetwo) address.push(addresslinetwo);
  if(postcode || city) address.push(`${postcode} ${city}`.trim());
  
  return address.join(', ');
}


// mailto 
export const mailtoLink = (mail, subject, body) => {
  return `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}
