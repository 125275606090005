import { combineReducers } from 'redux'

import auth from './auth'
import invoices from './invoices'
import files from './files'
import customers from './customers'
import templates from './templates'
import notifications from './notifications'

const appReducer = combineReducers({
	invoices,
	files,
	customers,
	auth,
	templates,
	notifications
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;
