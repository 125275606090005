const colors = {
  'gray': {
		'100': 'hsl(204, 45%, 98%)',
		'200': 'hsl(210, 38%, 95%)',
		'300': 'hsl(214, 32%, 91%)',
		'400': 'hsl(211, 25%, 84%)',
		'500': 'hsl(214, 20%, 69%)',
		'600': 'hsl(216, 15%, 52%)',
		'700': 'hsl(218, 17%, 35%)',
		'800': 'hsl(218, 23%, 23%)',
		'900': 'hsl(220, 26%, 14%)',
  },
  green: {
		'100': 'hsl(139, 52%, 95%)',
		'200': 'hsl(142, 52%, 85%)',
		'300': 'hsl(145, 52%, 75%)',
		'400': 'hsl(149, 52%, 60%)',
		'500': 'hsl(154, 52%, 46%)',
		'600': 'hsl(154, 52%, 40%)',
		'700': 'hsl(154, 52%, 31%)',
		'800': 'hsl(154, 52%, 25%)',
		'900': 'hsl(154, 52%, 19%)',
  },
  'blue': {
		'100': 'hsl(201, 100%, 96%)',
		'200': 'hsl(202, 81%, 86%)',
		'300': 'hsl(203, 82%, 76%)',
		'400': 'hsl(205, 79%, 66%)',
		'500': 'hsl(207, 73%, 57%)',
		'600': 'hsl(209, 62%, 50%)',
		'700': 'hsl(211, 61%, 43%)',
		'800': 'hsl(213, 49%, 34%)',
		'900': 'hsl(215, 41%, 28%)',
  },
  'red': {
		'100': 'hsl(0, 100%, 98%)',
		'200': 'hsl(0, 95%, 92%)',
		'300': 'hsl(0, 97%, 85%)',
		'400': 'hsl(0, 95%, 75%)',
		'500': 'hsl(0, 88%, 68%)',
		'600': 'hsl(0, 76%, 57%)',
		'700': 'hsl(0, 61%, 48%)',
		'800': 'hsl(0, 56%, 39%)',
		'900': 'hsl(0, 47%, 31%)',
  },
  yellow: {
		'100': 'hsl(48, 100%, 95%)',
		'200': 'hsl(47, 100%, 89%)',
		'300': 'hsl(47, 100%, 80%)',
		'400': 'hsl(46, 100%, 70%)',
		'500': 'hsl(45, 100%, 60%)',
		'600': 'hsl(42, 100%, 47%)',
		'700': 'hsl(40, 100%, 40%)',
		'800': 'hsl(39, 100%, 30%)',
		'900': 'hsl(36, 100%, 20%)',
  },
}

export default colors;
