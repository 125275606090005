import React from 'react'
import { dueDate } from '../../helpers'
import { Icon } from '../../components'

import styles from './styles.module.scss'

const Status = props => {
	const { status, invoicedate } = props;
	let deadline = props.deadline !== null ? props.deadline : 0;
	
	let due = {
		date: null,
		overdue: false
	}
	
	if(invoicedate) {
		due = dueDate(invoicedate, deadline);
	}

	const stadiums = {
		0: {
			label: 'Entwurf',
			svg: <Icon icon="status1" svgClassName="w-full h-full" />
		},
		1: {
			label: 'Entwurf',
			svg: <Icon icon="status1" svgClassName="w-full h-full" />
		},
		2: {
			label: `Fällig ${due.fromNow}`,
			svg: <Icon icon="status2" svgClassName="w-full h-full" />
		},
		3: {
			label: 'Bezahlt',
			svg: <Icon icon="status3" svgClassName="w-full h-full" />
		},
		4: {
			label: 'Storniert',
			svg: <Icon icon="status4" svgClassName="w-full h-full" />
		},
		5: {
			label: `Fällig ${due.fromNow}`,
			svg: <Icon icon="status5" svgClassName="w-full h-full" />
		}
	}
	
	const stadium = status !== 5 ? (
		(props.status === 2 && !due.overdue) ? 
			stadiums[5] : 
			stadiums[status]
	) : stadiums[4];
	
	// if(props.status === 5) {
	// 	// cancelled
	// 	status = stadiums[4]
	// } else {
	// 	if(props.status === 2 && deadline && !due.overdue) {
	// 		// overdue
	// 		status = stadiums[5];
	// 	} else {
	// 		// other
	// 		status = stadiums[props.status];
	// 	}
	// }
	
	return (
		<div className={["flex items-center", styles.Status].join(' ')}>
			<span className="inline-block w-5 h-5 rounded-full mr-2">
				{stadium.svg}
			</span>
			<span className="leading-none">{stadium.label}</span>
		</div>
	)
}

export default Status;
