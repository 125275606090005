import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { PageEditor, LoadingIndicator, FormField, RouterPrompt } from '../../../components'
import Button, { COLORS } from '../../../components/button'
import { loadTemplate } from '../../../templates'


class TemplateForm extends Component {	
	
	state = {
		mounted: false,
		initalized: false,
		data: {},
		template: {
			loading: true
		}
	}
	
	constructor(props) {
		super(props);
		
		this.update = this.update.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
		
		if(this.state.mounted !== true) {
			loadTemplate(this.props.user.template)
				.then(templateFile => {
					this.setState({ 
						mounted: true,
						template: {
							loading: false,
							...templateFile
						}
					})
				})	
		}	
	}
	
	componentWillUnmount() {
		this.setState({
			mounted: false
		})
	}

	componentDidUpdate() {
		this.initForm(this.props);
	}
		
	initForm({ template }) {
		if(!this.state.initalized) {
			this.setState({
				initalized: true,
				data: template
			})
		}
	}
	
	update(values) {
		return new Promise((resolve, reject) => {
			this.props.update(values)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	render() {	
		return <Form 
			onSubmit={this.update}
			initialValues={this.state.data}
			mutators={{
				setTotal: ([value], state, { changeValue }) => {
				  changeValue(state, "total", () => value)
				}
			}}
			render={({ handleSubmit, values, valid, form, pristine, submitting }) => {
				return <form onSubmit={e => {
						valid ?
							handleSubmit(e).then(() => form.reset(this.state.data)) : 
							handleSubmit(e); 
					}}>
					
					<div className="bg-gray-500 p-10">
						{this.state.template.loading ?
							<LoadingIndicator /> :
							<PageEditor
								key="2"
								currentInvoiceNumber={10}
								user={this.props.user} 
								values={values}
								defaultTaxRate={19}
								template={this.state.template}
								styles={this.state.template.styles}
								userStyles={this.state.data.styles}
								setMissingTags={this.setMissingTags}
								form={form} />}
					</div>
					
					<div>
						<FormField label="Styles" name="styles" type="textarea" />
					</div>
					
					<Button
						text="Speichern"
						action="submit"
						disabled={submitting || pristine}
						working={submitting}
						color={COLORS.PRIMARY}
						className="mt-6" />
					
					<RouterPrompt 
						when={true}
						navigate={location => this.props.history.push(location)}
						shouldBlockNavigation={location => {
							if(!pristine) return true;
							return false;
						}} 
						action={() => handleSubmit()} />
				</form>	
			}}
		/>
	}
}

export default TemplateForm;
