import React from 'react'
import { Helmet } from 'react-helmet'

const HelmetHeader = ({ location }) => {
	switch (location.pathname) {
		case "/invoices/create":
			return <Helmet>
				<title>Dashboard | Billu</title>
				<link rel="apple-touch-icon" sizes="57x57" href="/create-apple-icon-57x57.png" />
				<link rel="apple-touch-icon" sizes="60x60" href="/create-apple-icon-60x60.png" />
				<link rel="apple-touch-icon" sizes="72x72" href="/create-apple-icon-72x72.png" />
				<link rel="apple-touch-icon" sizes="76x76" href="/create-apple-icon-76x76.png" />
				<link rel="apple-touch-icon" sizes="114x114" href="/create-apple-icon-114x114.png" />
				<link rel="apple-touch-icon" sizes="120x120" href="/create-apple-icon-120x120.png" />
				<link rel="apple-touch-icon" sizes="144x144" href="/create-apple-icon-144x144.png" />
				<link rel="apple-touch-icon" sizes="152x152" href="/create-apple-icon-152x152.png" />
				<link rel="apple-touch-icon" sizes="180x180" href="/create-apple-icon-180x180.png" />
				<link rel="shortcut icon" href="/create-favicon.ico" />
				<link rel="icon" type="image/png" sizes="16x16" href="/create-favicon-16x16.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/create-favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="96x96" href="/create-favicon-96x96.png" />
				<link rel="icon" type="image/png" sizes="192x192"  href="/create-android-icon-192x192.png" />
				<meta name="msapplication-TileImage" content="/create-ms-icon-144x144.png" />
			</Helmet>
			
		default: 
			return <Helmet>
				<title>Dashboard | Billu</title>
			</Helmet>
	}
}

export default HelmetHeader;
