import React, { useState } from 'react'
import Button, { COLORS, SIZES } from '../button'
import { Page, ScrollToTop } from '../'
import { path } from '../../helpers'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'

const CsvImport = props => {
	const [inputs, setInputs] = useState({ csv: "", seperator: "	", header: "title;positions;receiver;headline" });
	const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
	
	const inputFields = inputs.csv.trim().split(inputs.seperator);
	const mapFields = inputs.header.trim().split(";")
	
	const sanatizedFields = inputFields.reduce((x, y) => {
		let sanatized = y.trim().replaceAll(/(?:\r\n|\r|\n)/g, '----')
			.replaceAll(/^(")(.*)"($)/g, "$2")
			.replaceAll("----", "\n")
		x.push(sanatized); 
		return x;
	}, [])
	
	console.log(sanatizedFields)
	
	const parsedObject = {};
	mapFields.forEach((x, i) => parsedObject[x] = sanatizedFields[i]);
	
	const allowTab = e => {
		if (e.key === 'Tab') {
	    e.preventDefault();
	    var start = e.target.selectionStart;
	    var end = e.target.selectionEnd;

	    e.target.value = e.target.value.substring(0, start) + "\t" + e.target.value.substring(end);

	    e.target.selectionStart =
	    e.target.selectionEnd = start + 1;
	    handleChange(e)
	  }
	}
	
	return (
		<Page>
			<ScrollToTop />
			<Helmet>
				<title>CSV Importieren</title>
			</Helmet>
			<div>
				<h1 className="m-0">CSV Importieren</h1>
				<div className="FormField">
					<label htmlFor="csv">CSV</label>
					<textarea name="csv" className="CodeEditor" id="csv" value={inputs.csv} onChange={handleChange} onKeyDown={allowTab} placeholder="CSV hier einfügen …"></textarea>
				</div>
				<div className="FormField">
					<label htmlFor="seperator">Trennzeichen</label>
					<input type="text" className="CodeEditor" name="seperator" id="seperator" value={inputs.seperator} onChange={handleChange} onKeyDown={allowTab}></input>
				</div>
				<div className="FormField">
					<label htmlFor="header">Header</label>
					<input type="text" className="CodeEditor" name="header" id="header" value={inputs.header} onChange={handleChange} onKeyDown={allowTab}></input>
				</div>
			</div>
			
			<h2>{Object.keys(parsedObject).length} Erkannte Felder</h2>
			<table>
				<thead>
					<tr>
						<th className="w-40"><span>Feld</span></th>
						<th><span>Wert</span></th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(parsedObject).map((x, i) => <tr key={i}>
						<td><span>{x}</span></td>
						<td><span className="text-blue-800 text-sm font-mono leading-normal">
							{parsedObject[x] ? parsedObject[x].replaceAll(/\n/g, "↵") : null}
						</span></td>
					</tr>)}
				</tbody>
			</table>
			
			<Button 
				text="Rechnung mit erkannten Werten erstellen"
				icon="add"
				disabled={!inputs.csv.length}
				color={COLORS.PRIMARY}
				size={SIZES.LARGE}
				link={path('invoices', 'create', `?action=create&${queryString.stringify(parsedObject)}`)}
			/>
		</Page>
				
	)
}

export default CsvImport;
