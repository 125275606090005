import React, { Component } from 'react'
// import styles from './styles.module.scss'

// color
// size

class Alert extends Component {
	
	render() {
		const { children, className } = this.props;
		
		return (
			<div className={["bg-gray-300 leading-snug my-3 p-3 rounded text-gray-900", className].join(' ')}>
				<div className="flex justify-between align-center">
					{children}
				</div>
			</div>	
		)
	}
}

export default Alert;
