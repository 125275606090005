import React, { Component } from 'react'
import { connect } from 'react-redux'

import { templateActions } from '../../redux'
import { prepareTemplate, path } from '../../helpers'
import { LoadingIndicator } from '../../components'

class TemplateCreator extends Component {
	constructor(props) {
		super(props);
		
		this.createTemplate = this.createTemplate.bind(this);
	}
	
	componentDidMount() {
		this.createTemplate();
	}
	
	createTemplate() {
		const { onAddTemplate } = this.props;
		
		let template = prepareTemplate({
			name: 'Neues Template'
		});
	
		onAddTemplate(template).then(template => {
			console.log("created", template);
			this.props.history.push(path('templates', template.id));
		})
	}
	
	render() {
		return <LoadingIndicator />
	}	
}

const mapActionsToProps = {
	onAddTemplate: templateActions.addTemplate
}

export default connect(null, mapActionsToProps)(TemplateCreator);
