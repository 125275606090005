import { api, loadFromLocalStorage } from '../../helpers';

const LOAD_FILES_BEGIN 		= 'files/LOAD_FILES_BEGIN';
const LOAD_FILES_SUCCESS 	= 'files/LOAD_FILES_SUCCESS';
const LOAD_FILES_ERROR 		= 'files/LOAD_FILES_ERROR';

const UPDATE_FILE_BEGIN 	= 'files/UPDATE_FILE';


const SHARE_FILE_BEGIN 		= 'files/SHARE_FILE_BEGIN';
const SHARE_FILE_SUCCESS 	= 'files/SHARE_FILE_SUCCESS';
const SHARE_FILE_ERROR 		= 'files/SHARE_FILE_ERROR';


const DELETE_FILE 				= 'files/DELETE_FILE';

const S3_ADD_FILE_BEGIN		= 'files/S3_ADD_FILE_BEGIN';
const S3_ADD_FILE_SUCCESS	= 'files/S3_ADD_FILE_SUCCESS';
const S3_ADD_FILE_ERROR		= 'files/S3_ADD_FILE_ERROR';

const RESET_FILES 				= 'files/RESET_FILES';

const SET_VIEW						= 'files/SET_VIEW';
const SET_FILTER					= 'files/SET_FILTER';
const SET_SORT						= 'files/SET_SORT';


// load
const loadFilesBegin = () => ({
	type: LOAD_FILES_BEGIN
})

const loadFilesSuccess = files => ({
	type: LOAD_FILES_SUCCESS,
	payload: files
})

const loadFilesError = error => ({
	type: LOAD_FILES_ERROR,
	payload: error
})


// update 
const updateFileBegin = file => ({
	type: UPDATE_FILE_BEGIN,
	payload: file
})


// share

const shareFileBegin = file => ({
	type: SHARE_FILE_BEGIN,
	payload: file
})

const shareFileSuccess = file => ({
	type: SHARE_FILE_SUCCESS,
	payload: file
})

const shareFileError = file => ({
	type: SHARE_FILE_ERROR,
	payload: file
})




// delete
const deleteFile = file => ({
	type: DELETE_FILE,
	payload: file
})


const s3AddFileBegin = () => ({
	type: S3_ADD_FILE_BEGIN
})

const s3AddFileSuccess = file => ({
	type: S3_ADD_FILE_SUCCESS,
	payload: file
})

const s3AddFileError = error => ({
	type: S3_ADD_FILE_ERROR,
	payload: error
})

const resetFiles = () => ({
	type: RESET_FILES
})

const setView = view => ({
	type: SET_VIEW,
	payload: view
})


const setFilter = (filter, override = false) => ({
	type: SET_FILTER,
	payload: {
		filter, 
		override
	}
})

const setSort = sort => ({
	type: SET_SORT, 
	payload: sort
})


function apiLoadFiles() {
	return dispatch => {
		dispatch(loadFilesBegin());
		
		return api('/files')
			.then(res => res.json())
			.then(json => {
				dispatch(loadFilesSuccess(json));
			})
			.catch(err => dispatch(loadFilesError(err)))
	}
}


function startFileExport() {
	return dispatch => {
		dispatch(s3AddFileBegin())
	}
}

function apiAddFile(user, invoiceid, filename, fileContents) {
	return dispatch => {
		const fileName = filename + '.pdf';
		
		return api('/upload', 'post', { file: fileName, userId: user.id, header: { 'Content-Type': 'application/pdf' } })
			.then(res => res.json())
			.then(json => {
				const { url: fileUrl, key: s3key } = json;
				
				Promise.all([
					new Promise((resolve, reject) => {
						fetch(json.signedRequest, { method: 'PUT', body: fileContents })
							.then(res => {
								if(res.status === 200) {
									return api('/files', 'post', { title: fileName, path: fileUrl, invoiceid: invoiceid, s3key: s3key })
										.then(res => res.json())
										.then(json => resolve(json))
										.catch(err => {
											reject(err);
										})
								} else {
									reject(res);
								}
							})
							.catch(reject)
					}),
					new Promise((resolve, reject) => { 
					  setTimeout(resolve, 1200); 
					})
				])
				.then(ret => {
					dispatch(s3AddFileSuccess(ret[0]))
				})
				.catch(err => {
					dispatch(s3AddFileError(err))
				})
			})
			.catch(console.error)
	}
	
	//let status;
	
	// return dispatch => {
	// 	return api('/files', 'post', { invoiceid, invoiceHash, fileHandle, fileContents })
	// 	.then(res => {
	// 		status = res.status;
	// 		return res.json();
	// 	})
	// 	.then(json => {
	// 		if(status === 201) {
	// 			dispatch(addFile(json));
	// 		}
	// 	})
	// 	.catch(console.log)
	// }
}


function apiUpdateFile(id, props) {
	let status;
	
	return dispatch => {
		return api(`/files/${id}`, 'put', props)
			.then(res => {
				status = res.status;
				return res.json();
			})
			.then(json => {
				if(status === 200) {
					dispatch(updateFileBegin(json));
					return json.item;
				} else {
					return json;
				}
			})
	}
}


function apiDeleteFile(id) {
	return dispatch => {
		return api(`/files/${id}`, 'delete')
			.then(res => res.json())
			.then(json => {
				dispatch(deleteFile({id}));
			})
			.catch(console.log)
	}
}

function apiShareFile(id, expires = "24h", override = false) {
	return dispatch => {
		dispatch(shareFileBegin({ id }))
		// console.log('share file', id);
		
		return api(`/files/share`, 'post', { id, expires, override, downloaded: null })
			.then(res => res.json())
			.then(json => {
				if(json.error) {
					dispatch(shareFileError(id));
				} else {
					dispatch(shareFileSuccess(json));
					return json.item;
				}
			})
			.catch(console.log)
	}
}

function dispatchSetView(view) {
	return dispatch => {
		dispatch(setView(view));
	}
}


function dispatchSetFilter(filter, override) {
	return dispatch => {
		dispatch(setFilter(filter, override));
	}
}

function dispatchSetSort(sort) {
	return dispatch => {
		dispatch(setSort(sort));
	}
}

export const fileActions = {
	loadFiles: apiLoadFiles,
	addFile: apiAddFile,
	deleteFile: apiDeleteFile,
	updateFile: apiUpdateFile,
	resetFiles: resetFiles,
	shareFile: apiShareFile,
	startFileExport: startFileExport,
	setView: dispatchSetView,
	setFilter: dispatchSetFilter,
	setSort: dispatchSetSort
}


export default function reducer(state = defaultState, { type, payload }) {
	let list = {};
	
	switch(type) {
		
		// load
		case LOAD_FILES_BEGIN:
			return {
				...state,
				loading: true,
				error: null
			}
			
		case LOAD_FILES_SUCCESS:
			list = payload.list.reduce((acc, x) => {
				acc[x.id] = x;
				return acc;
			}, {})
			
			return {
				...state,
				loading: false,
				error: null,
				list
			}
			
		case LOAD_FILES_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error
			}
			
		// s3
		case S3_ADD_FILE_BEGIN:
			return {
				...state,
				aws: {
					loading: true,
					error: null
				}
			}
				
		case S3_ADD_FILE_SUCCESS:
		
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state,
				list,
				aws: {
					loading: false,
					error: null,
					latestItem: payload.item
				}
			}
				
		case S3_ADD_FILE_ERROR:
			return {
				...state,
				aws: {
					loading: false,
					error: payload.message
				}
			}
				
		// update	
		case UPDATE_FILE_BEGIN:
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state, 
				list
			}
			
			
		// share
		case SHARE_FILE_BEGIN:
			list = { ...state.list };
			list[payload.id] = { ...list[payload.id], sharing: true }
			
			return {
				...state, 
				list
			}
			
		case SHARE_FILE_SUCCESS:
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state, 
				list
			}
			
		case SHARE_FILE_ERROR:
			list = { ...state.list };
			list[payload] = { ...list[payload], sharing: false }
			
			return {
				...state, 
				list
			}
				
		// delete
		case DELETE_FILE:
			list = { ...state.list };
			delete list[payload.id];
			
			return {
				...state,
				list
			}
			
		case SET_VIEW: 
			return {
				...state, 
				viewBy: payload
			}
			
		case SET_FILTER:
			let filter = payload.override ? {
				...defaultState.filterBy,
				...payload.filter
			} : {
				...state.filterBy, 
				...payload.filter
			};
			
			if(!payload.filter.trash) filter.trash = [];
			
			Object.keys(filter).forEach(x => filter[x] = typeof filter[x] == "object" ? filter[x] : [filter[x]])
			
			return {
				...state, 
				filterBy: filter
			}
			
		case SET_SORT:
			return {
				...state, 
				sortBy: payload
			}
			
			
		// reset
		case RESET_FILES:
			return defaultState
			
		default:
			return state
	}
}

const savedSettings = loadFromLocalStorage('files') || { viewBy: "", filterBy: {}, sortBy: {} };

const defaultState = {
	loading: false,
	error: null,
	list: {},
	aws: {
		loading: false,
		error: null,
	},
	viewBy: savedSettings.viewBy || "grid",
	filterBy: {
		year: [],
		month: [],
		sharing: [],
		trash: [false],
		...savedSettings.filterBy
	},
	sortBy: {
		...savedSettings.sortBy
	},
}

function getFilesList(state, useFilter = {}, useSort = false) {
	const files = state.files;
	
	let list = Object.values(files.list) || [];
	
	
	if(!list.length) return [];	
	
	// filter
	if(Object.keys(files.filterBy)) {
		let filter = { ...files.filterBy, ...useFilter };
		
		const filterYears = [...filter.year];
		(filterYears.length && filter.year.indexOf('auto') !== -1) && filterYears.push(new Date().getFullYear());
		
		const filterMonth = [...filter.month];
		(filterMonth.length && filter.month.indexOf('auto') !== -1) && filterMonth.push(new Date().getMonth());
		
		
		list = list.filter(x => {
			if(filterYears.length && filterYears.indexOf(new Date(x.created).getFullYear()) === -1) return false;
			if(filterMonth.length && filterMonth.indexOf(new Date(x.created).getMonth()) === -1) return false;
			
			// if(filter.status.length && filter.status.indexOf(x.status) === -1) return false;
			if(filter.sharing.length && filter.sharing.indexOf(!!x.token) === -1) return false; 
			
			if(filter.trash.length && filter.trash.indexOf(x.intrash)) return false; 
			if(!filter.trash.length && x.intrash === true) return false;

			return true;
		})
	}
	
	//sort
	let sort = !useSort ? files.sortBy : useSort;
	
	if(Object.keys(sort).length) {	
		
		let numberFields = [],
			stringFields = ["title"],
			dateFields = ["created"],
			booleanFields = ["token"];
			
		// string fields
		// date fields
		
		let sorter = null;
		
		if(numberFields.indexOf(sort.field) !== -1) {
			// numbers
			
			list.sort((x, y) => {
				if(y[sort.field] > x[sort.field]) sorter = 1;
				if(y[sort.field] < x[sort.field]) sorter = -1;
				
				// if(new Date(y.updated) > new Date(x.updated)) return 1;
				// if(new Date(y.updated) < new Date(x.updated)) return -1;
				return sorter !== null ? sorter * sort.direction : 0;
			})
		} else if(stringFields.indexOf(sort.field) !== -1) {
			// strings
			list.sort((x, y) => {
				if(!x[sort.field]) return 1 * sort.direction;
				if(!y[sort.field]) return -1 * sort.direction;
				return x[sort.field].localeCompare(y[sort.field]) * sort.direction;
			})
		} else if(dateFields.indexOf(sort.field) !== -1) {
			// dates
			list.sort((x, y) => {
				if(new Date(y[sort.field]) > new Date(x[sort.field])) sorter = -1;
				if(new Date(y[sort.field]) < new Date(x[sort.field])) sorter = 1;
				
				return sorter !== null ? sorter * sort.direction : 0;
			})
		} else if(booleanFields.indexOf(sort.field) !== -1) {
			list.sort((x, y) => {
				if(!!y[sort.field] > !!x[sort.field]) sorter = 1;
				if(!!y[sort.field] < !!x[sort.field]) sorter = -1;
				
				return sorter !== null ? sorter * sort.direction : 0;
			})
		}
	} else {
		list.sort((x, y) => {
			if(new Date(y.created) > new Date(x.created)) return 1;
			if(new Date(y.created) < new Date(x.created)) return -1;
			return 1;
		})
	}
	
	return list; 
}

function getFilesByInvoiceId(state, invoiceid, intrash = false) {
	return Object.values(state.files.list)
		.filter(x => x.invoiceid === invoiceid && x.intrash === intrash)
		.sort((x, y) => new Date(y.created) - new Date(x.created)) || [];
}

function getLastFileByInvoiceId(state, invoiceid) {
	const list = Object.values(state.files.list)
		.filter(x => x.invoiceid === invoiceid);
	return list;
		/*.sort((x,y) => {
			console.log()
		})*/
}

export const fileSelectors = {
	getFilesList,
	getFilesByInvoiceId,
	getLastFileByInvoiceId
}
