import React from 'react'
import { FilenameForm, ShareMailForm } from './forms'
import toast from 'react-hot-toast'
// import { TagList } from '../../components'
// import { getTags } from '../../helpers'

export const FilesSettings = props => {
	const { user, onUpdateUser, history } = props;
	
	const { filenameformat, sharemailbody, sharemailsubject } = user;
	
	/*const allTags = getTags();
	const filenameTags = {
		...allTags["invoice"],
		...allTags["date"],
		...allTags["user"],
		...allTags["customer"],
	}
	const sharemailTags = {
		...allTags["file"],
	}*/
	
	return <div>
		<div className="border-gray-200 border-b-2 border-t-2 mt-8 py-8 flex justify-between" id="filename">
			<div className="w-5/12 pr-8">
				<h3 className="mt-0 text-gray-800">Dateinamen</h3>
				<p className="text-gray-600">Hier kannst du angeben, wie die PDF-Dateien, die du exportierst, benannt werden sollen.</p>
				
				{/*<p className="text-gray-600">Verfügbare Platzhalter:</p>
				<TagList tags={Object.keys(filenameTags)} />*/}
			</div>
			<div className="w-1/2 ">
				<FilenameForm 
					userId={user.id} 
					data={{ filenameformat }}
					user={user}
					history={history}
					update={values => {
						return new Promise((resolve, reject) => {
							onUpdateUser(values)
								.then(values => {
									toast.success("Einstellungen gespeichert");
									resolve(values)
								});
						})
					}}/>
			</div>
		</div>
		
		<div className="border-gray-200 border-b-2 py-8 flex justify-between" id="sharemail">
			<div className="w-5/12 pr-8">
				<h3 className="mt-0 text-gray-800 flex gap-2 items-center"><span>Per E-Mail freigeben</span> <span className="text-sm p-1 bg-green-200 text-green-700 rounded leading-snug align-baseline ml-1">NEU</span></h3>
				{/*<p className="text-gray-600">Verfügbare Platzhalter:</p>
				<TagList tags={Object.keys(sharemailTags)} />*/}
			</div>
			<div className="w-1/2 ">
				<ShareMailForm 
					userId={user.id} 
					data={{ sharemailbody, sharemailsubject }}
					user={user}
					history={history}
					update={values => {
						return new Promise((resolve, reject) => {
							onUpdateUser(values)
								.then(values => {
									toast.success("Einstellungen gespeichert");
									resolve(values)
								});
						})
					}}/>
			</div>
		</div>		
	</div>
}
