import React, { Component } from 'react'
import { LoadingIndicator, Skeleton, SortHeader, SelectionCheckbox } from '../../components'
import { CropTable, timeAgo } from '../../helpers'
import Button, { COLORS, SIZES } from '../../components/button'
import styles from '../fileList/styles.module.scss'
import tableStyles from '../../styles/tables.module.scss'

class FileTrash extends Component {	
	
	Actions = ({ x }) => {
		return <div className="flex gap-3">
			<Button
				text="Wiederherstellen"
				icon="restore"
				action={() => this.props.onUpdateFile(x.id, { intrash: false })}
				color={COLORS.SECONDARY}
				size={SIZES.SMALL}
				className={["m-0"]}/>
			<Button
				text="Endgültig löschen"
				icon="delete-2"
				action={() => this.props.onDeleteFile(x.id, true)}
				color={COLORS.DANGER}
				size={SIZES.SMALL}
				className="mt-0" />
		</div>
	}
	
	Title = ({ title }) => {
		return <h4 className="my-0 block leading-tight" title={title}>
			<div className={styles.Title}>
				<span className="inline-table align-middle">{title}</span>
			</div>
		</h4>
	}

	CreatedAt = ({ createdRecently, created }) => {
		return <span className="block flex-grow">
			<div className={["inline-block", createdRecently ? "text-theme-500 font-medium" : "text-gray-600"].join(' ')}>
				{timeAgo(created)}
			</div>
		</span>
	}
	
	
	render() {
		const { list, aws, error, viewBy, loading, limit, sortBy, setSort } = this.props;
		
		const List = () => {
			return <React.Fragment>
				{loading ? (<Skeleton type="file" />) : (
					!!list.length ? <React.Fragment>
						{list.slice(0, limit || 100).map((x, i) => {

							const created = new Date(x.created),
								recently = new Date();
							recently.setMinutes(recently.getMinutes() - 5)
							
							const createdRecently = created > recently;
							
							return (
								viewBy === "grid" ? <React.Fragment key={i}>
									<div className="flex flex-col p-3 bg-white shadow rounded relative whitespace-nowrap">
										<this.Title title={x.title} />
										<div className="mt-2 flex-grow"><this.CreatedAt created={created} createdRecently={createdRecently} /></div>
										
										<div className="mt-3">
											<this.Actions x={x} />
										</div>
									</div>
									{list.length === 1 && <span></span>}
								</React.Fragment> : (
									<tr key={i}>
										<td className={tableStyles.Select}>
											<SelectionCheckbox id={x.id} {...this.props} />
										</td>
										<td className={tableStyles.Title}>
											<CropTable>
												<this.Title title={x.title} />
											</CropTable>
										</td>
										<td className={tableStyles.Nowrap}><this.CreatedAt created={created} createdRecently={createdRecently} /></td>
										<td className={tableStyles.Actions}><this.Actions x={x} /></td>
									</tr>
								)
							)}
						)}
					</React.Fragment> : 
						viewBy === "grid" ? <div className="mt-4">
							<span>Keine Dateien gefunden.</span>
						</div> : 
						<tr>
							<td colSpan={10}>
								<span>Keine Dateien gefunden.</span>
							</td>
						</tr>
				)}
			</React.Fragment>
		}
		
		return <div className="relateive">
			{error && <p>{error}</p>}
			
			{viewBy === "grid" ? 
				<div className={["mt-4", styles.FileList].join(' ')}>
					{aws.loading && <div className="relative bg-gray-200 rounded shadow pt-32"><LoadingIndicator /></div>}
					<List />
				</div> : <table>
					<thead>
						<tr>
							<th className={[tableStyles.Select, tableStyles.SelectHeader].join(' ')}>
								<SelectionCheckbox isHeader={true} {...this.props} />
							</th>
							<th>
								<SortHeader title="Dateiname" field="title" {...{sortBy, setSort}} />
							</th>
							<th>
								<SortHeader title="Erstellt" field="created" {...{sortBy, setSort}} />
							</th>
							<th>
								<span>Aktionen</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<List />
					</tbody>
					<tfoot>
						<tr className="text-gray-500">
							<td colSpan="5" className="">
								<span><span className="text-gray-700">{list.length}</span> Dateien gefunden</span>
								{list.length > limit && <span className="ml-1">({limit} angezeigt)</span>}
							</td>
						</tr>
					</tfoot>
				</table>
			}
		</div>
	}	
}

export default FileTrash;
