import React, { Component } from 'react'
import { ConfirmModal, Alert } from '../../../components'
import Button, { COLORS, SIZES, TYPES } from '../../../components/button'
import { path, api, downloadLink, getLastFileDownload } from '../../../helpers'
import toast from 'react-hot-toast'

function buttonString(length) {
	return `${length} Rechnung${length > 1 ? "en" : ""}`;
}

function getInvoicesWithFiles(list, selection) {
	if(!list || !selection) return [];
	
	return list
		.filter(x => selection.indexOf(x.id) !== -1)
		.filter(x => [0,1,4].indexOf(x.status) === -1)
}


class InvoicesHeader extends Component {
	state = {
		showConfirmTrashModal: false,
		showConfirmDeleteModal: false,
		showConfirmRestoreModal: false,
		deletePhrase: "",
		deleteKeyword: "LÖSCHEN"
	}
	
	onShowConfirmTrashModal(val) {
		this.setState({ showConfirmTrashModal: val })
	}
	
	onShowConfirmDeleteModal(val) {
		this.setState({ showConfirmDeleteModal: val })
	}
	
	onShowConfirmRestoreModal(val) {
		this.setState({ showConfirmRestoreModal: val })
	}
	
	onTypeDeleteInvoices(e) {
		this.setState({
			deletePhrase: e.target.value
		})
	}
	
	render() {
		const { invoices, files, trash = false, selection, onUpdateInvoice, onDeleteInvoice, onUpdateSelection, setFilter } = this.props;
		const { showConfirmTrashModal, showConfirmDeleteModal, showConfirmRestoreModal } = this.state;
		
		const invoicesWithFiles = getInvoicesWithFiles(invoices, selection);
		
		return (
			<div>
				<header className="flex flex-col mb-5">
					<div className="flex items-start justify-between">
						<h1 className="m-0">{!trash ? "Rechnungen" : "Papierkorb"}</h1>
						{!selection.length ?
							<Button
								text="Rechnung erstellen"
								icon="add"
								link={path('invoices', 'create')}
								color={COLORS.PRIMARY}
								size={SIZES.LARGE}
								className={["mt-0"]}/> : null}
					</div>
					
					{selection.length <= 0 ? <div>
						{!trash ? <div className="flex my-4 gap-3">
							<Button
								text="Papierkorb"
								icon="delete"
								action={() => setFilter({ trash: true })}
								type={TYPES.GHOST} 
								size={SIZES.SMALL}
								className="mt-0"
								/>
							<Button 
								text="Monatsübersicht"
								icon="calendar"
								link="/statements"
								type={TYPES.GHOST} 
								size={SIZES.SMALL}
								className="mt-0"
								/>
						</div> : <div className="flex my-4 gap-3">
							<Button
								text="Zurück zur Liste"
								icon="arrowLeft"
								action={() => setFilter({ trash: false })}
								type={TYPES.GHOST} 
								size={SIZES.SMALL}
								className="mt-0"
								/>
						</div>}
					</div> : <div>
						{!trash ? <div className="flex my-4 gap-3">
							{/*<Button
								text="Status auf Offen"
								icon="delete"
								action={() => {
									selection.forEach(x => onUpdateInvoice(x, { status: 2 }))
								}}
								color={COLORS.PRIMARY}
								size={SIZES.MEDIUM}
								className={["mt-0"]}/>
							<Button
								text="Status auf Bezahlt"
								icon="delete"
								action={() => {
									selection.forEach(x => {
										onUpdateInvoice(x, { status: 3 });
									})
								}}
								color={COLORS.PRIMARY}
								size={SIZES.MEDIUM}
								className={["mt-0"]}/>*/}
								
							<Button
								text={`${buttonString(selection.length)} in den Papierkorb verschieben`}
								icon="delete"
								action={() => {
									this.onShowConfirmTrashModal(true);
								}}
								color={COLORS.DANGER}
								size={SIZES.SMALL}
								className={["mt-0"]}/>
								
								{showConfirmTrashModal && <ConfirmModal>
									<h3 className="mt-0 mb-4">Willst du wirklich {buttonString(selection.length)} in den Papierkorb verschieben?</h3>
									<div className="flex justify-between mt-5">
										<Button
											text="Abbrechen"
											action={() => this.onShowConfirmTrashModal(false)}
											color={COLORS.SECONDARY} 
											className="mt-0"/>
										<span className="flex-grow"></span>
										<Button
											text={`${buttonString(selection.length)} in den Papierkorb verschieben`}
											icon="delete"
											action={() => {
												this.onShowConfirmTrashModal(false)
												selection.forEach(x => onUpdateInvoice(x, { intrash: true }));
												toast.success(`${buttonString(selection.length)} in den Papierkorb verschoben`)
												onUpdateSelection([], true);
											}}
											color={COLORS.DANGER}
											className={["mt-0"]}/>
									</div>
								</ConfirmModal>}
								
						{invoicesWithFiles.length ? 
							<Button
								text={`Dateien von ${buttonString(invoicesWithFiles.length)} herunterladen`}
								icon="download"
								action={() => {
									const downloadFiles = invoicesWithFiles.map(x => getLastFileDownload(files, x.id)).filter(x => x)
									console.log("invoices", invoicesWithFiles, downloadFiles.map(x => x.id))
									
									const fileDownlaod = api('/download/zip', 'post', { files: downloadFiles.map(x => x.id) })
										.then(res => {
											return res.blob().then((data) => {
												return {
													data: data,
													filename: res.headers.get('Content-Disposition'),
												};
											});
								    })
								    .then(({ data, filename }) => {
								        const blob = new Blob([data], { type: 'application/zip' });
								        const downloadUrl = URL.createObjectURL(blob);
								        
								        downloadLink(downloadUrl, filename.split("=")[1]);
								        console.log("done");
								    })
										.catch(err => console.error)
									toast.promise(fileDownlaod, {
										loading: "Dateien werden zum Download vorbereitet",
										success: "Dateien heruntergeladen",
										error: "Fehler beim herunterladen"
									})
								}}
								color={COLORS.PRIMARY}
								size={SIZES.SMALL}
								className="mt-0"
								/> : null
							}
								
						</div> : <div>
							<div className="flex gap-3 my-4">
								<Button
									text={`${buttonString(selection.length)} wiederherstellen`}
									icon="restore"
									action={() => {
										this.onShowConfirmRestoreModal(true);
									}}
									color={COLORS.SECONDARY}
									size={SIZES.SMALL}
									className={["mt-0"]}/>
								{showConfirmRestoreModal && <ConfirmModal>
										<h3 className="mt-0 mb-4">Willst du wirklich {buttonString(selection.length)} wiederherstellen?</h3>
										<div className="flex justify-between mt-5">
											<Button
												text="Abbrechen"
												action={() => this.onShowConfirmRestoreModal(false)}
												color={COLORS.SECONDARY} 
												className="mt-0"/>
											<span className="flex-grow"></span>
											<Button
												text={`${buttonString(selection.length)} wiederherstellen`}
												icon="restore"
												action={() => {
													this.onShowConfirmRestoreModal(false)
													selection.forEach(x => onUpdateInvoice(x, { intrash: false }));
													toast.success(`${buttonString(selection.length)} wiederhergestellt`)
													onUpdateSelection([], true);
												}}
												color={COLORS.PRIMARY}
												className={["mt-0"]}/>
										</div>
									</ConfirmModal>}
									
								<Button
									text={`${buttonString(selection.length)} endgültig löschen`}
									icon="delete-2"
									action={() => {
										this.onShowConfirmDeleteModal(true);
									}}
									color={COLORS.DANGER}
									size={SIZES.SMALL}
									className={["mt-0"]}/>
									
								{showConfirmDeleteModal && <ConfirmModal>
									<h3 className="mt-0 mb-4">Willst du wirklich {buttonString(selection.length)} endgültig löschen?</h3>
									
									<div className="FormField">
										<p>Schreibe <span className="text-red-600 bg-gray-200 rounded-sm p-1 leading-snug tracking-wider text-sm font-medium">{this.state.deleteKeyword}</span> in das Textfeld, um das Löschen zu bestätigen.</p>
										<Alert>Dadurch werden sowohl die Rechnungen und auch die dazugehörigen Dateien gelöscht.</Alert>
										<input type="text" placeholder={this.state.deleteKeyword} onChange={e => this.onTypeDeleteInvoices(e)} />
									</div>
									<div className="flex justify-between mt-5">
										<Button
											text="Abbrechen"
											action={() => this.onShowConfirmDeleteModal(false)}
											color={COLORS.SECONDARY} 
											className="mt-0"/>
										<span className="flex-grow"></span>
										<Button
											disabled={this.state.deletePhrase !== this.state.deleteKeyword}
											text={`${buttonString(selection.length)} endgültig Löschen`}
											icon="delete"
											action={() => {
												this.onShowConfirmDeleteModal(false)
												selection.forEach(x => onDeleteInvoice(x));
												onUpdateSelection([], true);
											}}
											color={COLORS.DANGER}
											className={["mt-0"]}/>
									</div>
								</ConfirmModal>}
							</div>
						</div>}
					</div>}
				</header>
			</div>
		);
	}
}

export default InvoicesHeader;
