import React from 'react'
import styles from './styles.module.scss'
// import styles from './myicons.module.scss'


const icons = {
	customers: 'book-bookmark.1',
	// invoices: 'invoice.1',
	invoices: 'invoice-accounting-document',
	// files: 'folders-08',
	// files: 'document-content.6',
	files: 'file-new',
	// home: 'home-house.1',
	home: 'interface-essential-162',
	delete: 'trash-delete-bin',
	'delete-2': 'trash-delete-remove',
	edit: 'interface-essential-221', 
	// restore: 'design-48',
	// settings: 'design-55',
	settings: 'interface-essential-270',
	arrowRight: 'interface-essential-237',
	arrowRightUp: 'arrows-diagrams-10',
	arrowLeft: 'interface-essential-182',
	arrowUp: 'arrows-diagrams-19',
	arrowDown: 'arrows-diagrams-30',
	arrowUpDown: 'arrows-diagrams-02',
	meatballs: 'meatballs-menu',
	add: 'add-plus',
	'drag-handle': 'drag-handle',
	cancel: 'delete-cross',
	copy: 'copy-paste-select-add-plus',
	// clipboard: 'interface-essential-97',
	info: 'info-information-circle',
	clipboard: 'notes',
	check: 'interface-essential-112',
	error: 'interface-essential-107',
	chevronDown: 'interface-essential-33',
	chevronRight: 'interface-essential-35',
	chevronLeft: 'chevronLeft',
	chevronDownSmall: 'arrows-diagrams-08',
	chevronUpSmall: 'arrows-diagrams-11',
	chevronLeftSmall: 'arrows-diagrams-06',
	chevronRightSmall: 'interface-essential-30',
	chat: 'chat-messages-bubble-question',
	support: 'protection-06',
	folders: 'media-library-folder',
	file: 'files-07',
	// lock: 'interface-essential-188'
	mailSent: 'interface-essential-258',
	lock: 'lock',
	link: 'interface-essential-47',
	brokenLink: 'broken-link-unlink-attachment.7',
	auto: 'synchronize',
	share: 'interface-essential-276', //278
	hand1: 'hand-select.5',
	logo: 'logo',
	logoIcon: 'logo-icon',
	restore: 'interface-essential-48',
	download: 'interface-essential-116',
	login: 'login',
	logout: 'interface-essential-282',
	account: 'users-02',
	changePassword: 'input-password-rotection',
	star: 'interface-essential-289',
	starFull: 'star-full',
	grid: 'grid-layout-10',
	list: 'grid-layout-6.1',
	privacy: 'shield-protected-checkmark',
	company: 'bank.5',
	search: 'interface-essential-251',
	status1: 'status-1',
	status2: 'status-2',
	status3: 'status-3',
	status4: 'status-4',
	status5: 'status-5',
	calendar: 'calendar-schedule.3'
}

const Icon = props => {
	const { icon, className, svgClassName, width = 24, height = 24, stroke = true, fill = false } = props;
	
	return (
		<span className={[className, styles.Icon, "icon"].join(' ')}>
			<svg className={[styles.Svg, svgClassName, stroke ? null : styles.noStroke, fill ? styles.fill : null].join(" ")} width={width} height={height}><use xlinkHref={`#${icons[icon] ? icons[icon] : icon}`}></use></svg>
		</span>
		// <span className={[styles.icon, styles[`icon-${icon}`], className].join(' ')}></span>
	)
}

export default Icon;
