import React, { useReducer, useEffect, useState } from 'react'
import { Page, Icon, Alert } from '../../components'
import styles from './styles.module.scss'
import Button, { COLORS, SIZES, TYPES } from '../../components/button'
import theme from './theme'

// import resolveConfig from 'tailwindcss/resolveConfig'
// import tailwindConfig from '../../../tailwind.config.js'


function Colors() {
	// const colors = Object.keys(theme),
	const steps = [100, 200, 300, 400, 500, 600, 700, 800, 900 ];
	
	const colorsReducer = (state, action) => {
		return {
			...state,
			[action.color]: {
				...state[action.color],
				[action.step]: {
					...action.obj
				}
			}
		}
	};
	
	const [colors, setColors] = useReducer(colorsReducer, {});
	const [cssRules, setCssRules] = useState([]);
	
	// const { theme } = resolveConfig('../../../../tailwind.config.js');
	// console.log(theme.colors);
	
	useEffect(() => {
		Object.keys(theme).forEach(color => {
			Object.keys(theme[color]).forEach(step => {
				let hsl = theme[color][step].split(/(\(|,|\))/g);
				let hex = hslToHex(hsl[2].trim(), hsl[4].trim().replace("%", ""), hsl[6].trim().replace("%", ""));

				Promise.all([
					fetch(`https://webaim.org/resources/contrastchecker/?fcolor=000000&bcolor=${hex}&api`).then(res => res.json()),
					fetch(`https://webaim.org/resources/contrastchecker/?fcolor=ffffff&bcolor=${hex}&api`).then(res => res.json())
				]).then(all => {
					let obj = {
							hsl: theme[color][step],
							hex,
							'black': all[0],
							'white': all[1],
						}
					setColors({ color, step, obj });
				})
			})
		})
		
		setCssRules(Object.values(document.styleSheets).reduce((arr, x) => {
			arr = [...arr, ...Object.values(x.rules).map(y => y.selectorText)]
			return arr;
			//return arr.push(Object.values(x.rules).map(y => y.selectorText));
		}, []));
	
		
	}, [])
	
	return (
		<Page>
			<h2>Logo</h2>
			<hr className="my-6"/>
			
			<Icon icon="logo" className={[styles.Logo, "w-32 h-16 mb-10 block"].join(' ')}/>
			{/*<Icon icon="logoIcon" className={[styles.Logo, "w-20 h-20 mb-10 block"].join(' ')}/>*/}
			
			<h2>Typografie</h2>
			<hr className="my-6"/>
			
			<h1 className="mb-4">Überschrift 1</h1>
			<p className="max-w-2xl">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae inventore aut molestiae obcaecati iusto asperiores aperiam, ab unde eos quas.</p>
			<h2>Überschrift 2</h2>
			<p className="max-w-2xl">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error ex libero veritatis nulla consectetur labore doloribus tempora asperiores harum praesentium.</p>
			<h3>Überschrift 3</h3>
			<p className="max-w-2xl">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed iste modi quia veritatis assumenda magnam explicabo. Veritatis exercitationem maxime, a sint corporis, ipsum excepturi, quaerat in fugiat tenetur odio assumenda!</p>
			<small>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos, alias.</small>
			<br/><br/>
			
			<div className={[styles.ColorGrid, "p-6 -mx-6 bg-white"].join(' ')}>
				{steps.map((step, i) => <header className="relative header" key={i}><div className="absolute top-0 left-0 w-full h-full pl-2 text-gray-500">{step}</div></header>)}
				
				{Object.keys(colors).map(color => {
					return Object.keys(colors[color]).map((step, i) => {
						let obj = colors[color][step];
						
						return <div key={i} className="relative rounded" style={{ backgroundColor: `var(--${color}-${step})` }}>
							<div className={["absolute top-0 left-0 w-full h-full p-2 rounded flex flex-col justify-between", 
								(((color === 'green' && step === "500") || 
									(color === 'yellow' && step === "200") || 
									(color === 'red' && step === "200")) ? "border-2 border-gray-700" : ""),
									obj.black.AA === 'pass' ? 'text-black' : 'text-white'].join(' ')}>
								
								<div>
									<span className="tnum font-mono">#{obj.hex}</span>
								</div>
							
								<div className="flex justify-between items-center">
									{obj.black.AA === 'pass' ? (
										obj.black.AAA === 'pass' ? <span className="text-black">AAA</span> : <span className="text-black">AA</span>
									) : null}
									{obj.white.AA === 'pass' ? (
										obj.white.AAA === 'pass' ? <span className="text-white">AAA</span> : <span className="text-white">AA</span>
									) : null}
									{cssRules.indexOf(`.bg-${color}-${step}`) !== -1 ? <Icon icon="check" className="block w-4 h-4" svgClassName="w-full h-full" /> : null}
								</div>
							</div>
						</div>
					}
				)})}
			</div>
			
			<div className="mt-6">
				<h2>Buttons</h2>
				<hr className="my-6"/>
				<h3>Größen</h3>
				<div className="flex items-center">
					<Button 
						key={1}
						text="Small"
						icon=""
						iconPosition=""
						disabled={false}
						working={false}
						color=""
						type=""
						size={SIZES.SMALL}
						className="mr-2"
					/>
					<Button 
						key={2}
						text="Medium"
						icon=""
						iconPosition=""
						disabled={false}
						working={false}
						color=""
						type=""
						size={SIZES.MEDIUM}
						className="mr-2"
					/>
					<Button
						key={3}
						text="Large"
						icon=""
						iconPosition=""
						disabled={false}
						working={false}
						color=""
						type=""
						size={SIZES.LARGE}
						className="mr-2"
					/>
				</div>
				
				<h3>Farben</h3>
				
				<div className="flex items-center">
					<Button 
						key={1}
						text="Primary"
						icon=""
						iconPosition=""
						disabled={false}
						working={false}
						color={COLORS.PRIMARY}
						type={TYPES.BUTTON}
						size={SIZES.SMALL}
						className="mr-2"
					/>
					<Button 
						key={2}
						text="Secondary"
						icon=""
						iconPosition=""
						disabled={false}
						working={false}
						color={COLORS.SECONDARY}
						type={TYPES.BUTTON}
						size={SIZES.SMALL}
						className="mr-2"
					/>
					<Button 
						key={3}
						text="Danger"
						icon=""
						iconPosition=""
						disabled={false}
						working={false}
						color={COLORS.DANGER}
						type={TYPES.BUTTON}
						size={SIZES.SMALL}
						className="mr-2"
					/>
				</div>
				
				<h3>Icons</h3>
				
				<div className="flex items-center">
					<Button 
						key={1}
						text="Icon Left"
						icon="delete"
						disabled={false}
						working={false}
						color={COLORS.PRIMARY}
						type={TYPES.BUTTON}
						size={SIZES.SMALL}
						className="mr-2"
					/>
					<Button 
						key={2}
						text="Icon Right"
						icon="delete"
						iconPosition="right"
						disabled={false}
						working={false}
						color={COLORS.PRIMARY}
						type={TYPES.BUTTON}
						size={SIZES.SMALL}
						className="mr-2"
					/>
				</div>
				
				<h3>Typen</h3>
				
				<div className="flex items-center">
					<Button 
						key={1}
						text="Button"
						icon=""
						disabled={false}
						working={false}
						color={COLORS.PRIMARY}
						type={TYPES.BUTTON}
						size={SIZES.SMALL}
						className="mr-2"
					/>
					<Button 
						key={4}
						text="Ghost Button mit Icon"
						icon="arrowLeft"
						disabled={false}
						working={false}
						color={COLORS.SECONDARY}
						type={TYPES.GHOST}
						size={SIZES.SMALL}
						className="mr-2 mt-2"
					/>
					<Button 
						key={5}
						text="Ghost Button mit Icon rechts"
						icon="arrowRight"
						disabled={false}
						working={false}
						iconPosition="right"
						color={COLORS.SECONDARY}
						type={TYPES.GHOST}
						size={SIZES.SMALL}
						className="mr-2 mt-2"
					/>
				</div>
				
				<h3>Stadien</h3>
				
				<div className="flex items-center">
					<Button 
						key={1}
						text="Disabled"
						icon=""
						iconPosition=""
						disabled={true}
						working={false}
						color={COLORS.PRIMARY}
						type={TYPES.BUTTON}
						size={SIZES.SMALL}
						className="mr-2"
					/>
					<Button 
						key={2}
						text="Working"
						icon=""
						iconPosition=""
						disabled={false}
						working={true}
						color={COLORS.PRIMARY}
						type={TYPES.BUTTON}
						size={SIZES.SMALL}
						className="mr-2"
					/>
				</div>
			</div>
			
			<div className="mt-6">
				<h2>Eingabefelder</h2>
				
				<div className="flex flex-wrap -mx-6">
					<div className="px-6 flex-1">
						<div className="FormField mt-6 mb-2">
							<div className="flex justify-between items-end mt-2 mb-1">
								<label htmlFor="testfield" className="leading-tight my-0 whitespace-no-wrap">
									Label
								</label>
							</div>
							<input type="text" id="testfield"/>
						</div>
						<div className="FormField mt-6 mb-2">
							<div className="flex justify-between items-end mt-2 mb-1">
								<label htmlFor="testfield2" className="leading-tight my-0 whitespace-no-wrap">
									Label
									<span className="text-gray-600 ml-1">(zusätzliches Label)</span>
								</label>
							</div>
							<input type="text" id="testfield2" placeholder="placeholder"/>
						</div>
					</div>
					<div className="px-6 flex-1">
						<div className="FormField mt-6 mb-2">
							<div className="flex justify-between items-end mt-2 mb-1">
								<label htmlFor="testfield3" className="leading-tight my-0 whitespace-no-wrap">
									Label
									<span className="text-gray-600 ml-1"></span>
								</label>
								<span className="text-red-500 text-sm leading-tight">Error Mitteilung!</span>	
							</div>
							<input type="text" id="testfield3"/>
						</div>
					</div>
				</div>
			</div>
			
			<div className="mt-6">
				<h2>Meldungen</h2>
				<hr className="my-6"/>
				<Alert className="mt-5">Neutrale Meldung</Alert>
				<Alert className="mt-5 bg-green-200">Positive Meldung</Alert>
				<Alert className="mt-5 bg-red-200">Negative Meldung</Alert>
				<Alert className="mt-5">
					<div className="flex items-center justify-start">
						<Icon icon="delete" className="mr-2 text-gray-600"/>
						<span>Meldung mit Icon</span>
					</div>
				</Alert>
			</div>
		</Page>	
	)
}


export default Colors;



function hslToHex(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `${toHex(r)}${toHex(g)}${toHex(b)}`;
}
