import React, { Component } from 'react'
import styles from './styles.module.scss'
import PositionsList from '../positionsList'

import { autoHeightTextarea, /*scalePage,*/ germanDate, tags, formatAddressShort/*, parseTemplateStyles*/ } from '../../helpers'
import { Field } from 'react-final-form'
import createPagePreview from './previewPage'
import cssToObject from 'css-to-object'

const objectToCss = require('json-to-css')


// const parseStyles = styles => styles
//     .split(';')
//     .filter(style => style.split(':')[0] && style.split(':')[1])
//     .map(style => [
//         style.split(':')[0].trim().replace(/-./g, c => c.substr(1).toUpperCase()),
//         style.split(':').slice(1).join(':').trim()
//     ])
//     .reduce((styleObj, style) => ({
//         ...styleObj,
//         [style[0]]: style[1],
//     }), {});
    

const PageField = (props) => {
	const { name, resize = false, template, children, user } = props;
	const { styles: templateStyles = {}, strings = {}, placeholders = {} } = template;
	
	const templateStyle = typeof templateStyles === 'object' ? 
		(name in templateStyles) ? 
			templateStyles[name] : '' 
		: '';
	
	return (
		<div className={["PageField", name, styles.PageField, templateStyles.PageField, templateStyle].join(' ')}>
			{(name in strings) && <span>{tags(strings[name], { user }).string}</span>}
			{children}
			{!children && 
				<Field name={name} parse={value=>value.normalize("NFKC")}>
					{({ input, meta }) => (
						<textarea {...input}  
							placeholder={placeholders[name] ? placeholders[name] : ''} 
							noresize={!resize ? "true" : ""}
							onBlur={e => {
								resize && autoHeightTextarea(e.target);
							}}></textarea>
					)}
				</Field>}
		</div>
	)
}


class PageEditor extends Component {
	handleBlur = e => autoHeightTextarea(e.target);
	styleWrapperClass = "PageStyleWrapper";
	
	state = {
		previewInitalized: false
	}
	
	componentDidUpdate() {
		if(this.props.previewMode && !this.state.previewInitalized) {
			this.createPreviewPage();
		} else if(!this.props.previewMode && this.state.previewInitalized) {
			this.removePreviewPage();
		}
	}
	
	createPreviewPage() {
		const { values, user, customer, currentInvoiceNumber, setPreviewValues } = this.props;
		
		this.setState({
			previewInitalized: true
		})

		createPagePreview({...values, 
			invoicenumber: values.invoicenumber ? values.invoicenumber : currentInvoiceNumber + 1,
			invoicedate: values.invoicedate ? values.invoicedate : germanDate(new Date())
		}, user, customer).then(previewValues => {
			setPreviewValues(previewValues);
		})	
		.catch(console.log)
	}
	
	
	removePreviewPage() {
		this.setState({
			previewInitalized: false
		})
		let pagePreview = document.querySelector('.previewPage');
		if(pagePreview) pagePreview.innerHTML = "";
	}
	
	componentDidMount() {
		// window.addEventListener("resize", () =>	window.requestAnimationFrame(scalePage));
		// scalePage();
		setTimeout(() => document.querySelectorAll('.page textarea:not([noresize="true"])').forEach(x => autoHeightTextarea(x)), 0)
	}
	
	
	prefixStyles(styleString) {
		if(!styleString) return false;
		
		let parsedStyles = {},
			prefixedStyles,
			prefixedCssString = "";
		
		try {
			parsedStyles = cssToObject(styleString);
			
			prefixedStyles = Object.keys(parsedStyles).reduce((ret, key) => {
				ret[`.${this.styleWrapperClass} ${key}`] = parsedStyles[key];
				return ret;
			}, {});
			
			prefixedCssString = objectToCss.of(prefixedStyles)
		}	catch (err) {
			console.error(err);
		}
		
		return prefixedCssString;
	}
	
	
	
	render() {
		const { user, defaultTaxRate, template, styles: templateStyles = {}, userStyles, form } = this.props;
		let { strings = {}, fields = [] } = template;
		
		// if(typeof template.styles === "string") template.styles = parseStyles(template.styles);
		
		strings = {
			footer1: "Anschrift",
			positionsText: "Beschreibung",
			positionsPrice: "Preis",
			positionsTax: "MwSt.",
			positionsSubtotal: "Zwischensumme",
			positionsTotal: "Gesamt",
			...strings
		}
		
		return <div className={this.styleWrapperClass}>
			<style dangerouslySetInnerHTML={{__html: this.prefixStyles(userStyles)}} />
			
			<div className={[styles.Page, templateStyles.Template, 'page Template', this.props.previewMode ? "pointer-events-none shadow-2xl" : ""].join(' ')}>	
				<div className={[styles.Grid, templateStyles.Grid, 'Grid grid'].join(' ')}>
					<div className={[templateStyles.absender, 'absender'].join(' ')}>
						<span>{formatAddressShort(user)}</span>
					</div>
					
					<PageField name="receiver" template={template} resize={true} user={user} />
					<PageField name="header" template={template} resize={true} user={user} />
					<PageField name="footer1" template={template} resize={true} user={user} />
					<PageField name="footer2" template={template} resize={true} user={user} />
					<PageField name="footer3" template={template} resize={true} user={user} />
					<PageField name="headline" template={template} resize={true} user={user} />
					<PageField name="body" template={template} resize={true} user={user} />
					<PageField name="conclusion" template={template} resize={true} user={user} />
					<PageField name="sidebar" template={template} resize={true} user={user} />
					<PageField name="positions" template={template}>
						<Field name="positions" parse={({positions, total}) => {
							form.mutators.setTotal(total);
							return JSON.stringify(positions).normalize("NFKC")}
						}>
							{({ input, meta }) => (
								<PositionsList 
									positions={input.value}
									onUpdate={input.onChange} 
									styles={templateStyles} 
									fields={fields}
									currency={template.currency} 
									defaultTaxRate={defaultTaxRate} 
									strings={strings}
									novat={user.novat} />
							)}
						</Field>						
					</PageField>
					
					<Field name="total">
						{({ input }) => (
							<input {...input} type="hidden" className="text-left"/>
						)}
					</Field>
					
					{template.actions && template.actions.layout && <template.actions.layout />}
				</div>
				
				<div className={["previewPage", styles.PreviewPage, (!this.props.previewMode ? 'hidden' : null)].join(' ')}></div>
			</div>
		</div>
	}
}

export default PageEditor;
