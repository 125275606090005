import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import Button, { COLORS } from '../../components/button'


function CookieNotice({ accept }) {
	useEffect(() => {
		toast(
			t => <div>
				<p>Wir verwenden Cookies, um für dich die Benutzung von Billu so angenehm wie möglich zu machen. Weitere Infos dazu findest du in unserer <a href="https://billu.app/datenschutzerklaerung/" rel="noopener noreferrer" target="_blank" className="text-theme-600">Datenschutzerklärung</a></p>
				<Button 
					text="Cookies Akzeptieren"
					icon="check"
					className="w-full mt-2"
					color={COLORS.PRIMARY}
					action={() => {
						accept({ cookiesAccepted: true });
						toast.dismiss(t.id);
					}} 
					/>
			</div>,
			{duration: Infinity}
		)
  }, [accept]);
	
	return null;
}

export default CookieNotice;
