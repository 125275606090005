import React from 'react'
import { Page } from '../../components'

const NotFound = () => {
	return (
		<Page>
			<h1>404</h1>
		</Page>	
	)
}

export default NotFound;
