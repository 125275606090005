import { api } from '../../helpers'

// ACTIONS
const VALIDATE_TOKEN_SUCCESS 	= 'auth/VALIDATE_TOKEN_SUCCESS';
const VALIDATE_TOKEN_ERROR 		= 'auth/VALIDATE_TOKEN_ERROR';

const LOGIN 									= 'auth/LOGIN';
const LOGOUT 									= 'auth/LOGOUT';
const LOGIN_ERROR 						= 'auth/LOGIN_ERROR';

const SIGNUP_BEGIN						= 'auth/SIGNUP_BEGIN';
const SIGNUP_SUCCESS					= 'auth/SIGNUP_SUCCESS';
const SIGNUP_ERROR						= 'auth/SIGNUP_ERROR';

const PAYMENT_BEGIN						= 'auth/PAYMENT_BEGIN';
const PAYMENT_SUCCESS					= 'auth/PAYMENT_SUCCESS';
const PAYMENT_ERROR						= 'auth/PAYMENT_ERROR';

const UPDATE_USER							= 'users/UPDATE_USER';


const login = user => ({
	type: LOGIN,
	payload: user
})

const logout = () => ({
	type: LOGOUT
})

const validateTokenSuccess = user => ({
	type: VALIDATE_TOKEN_SUCCESS,
	payload: user
})

const validateTokenError = () => ({
	type: VALIDATE_TOKEN_ERROR
})

const loginError = error => ({
	type: LOGIN_ERROR,
	payload: error
})


const signupBegin = () => ({
	type: SIGNUP_BEGIN
})

const signupSuccess = user => ({
	type: SIGNUP_SUCCESS,
	payload: user
})

const signupError = error => ({
	type: SIGNUP_ERROR,
	payload: error
})


const paymentBegin = () => ({
	type: PAYMENT_BEGIN
})

const paymentSuccess = payment => ({
	type: PAYMENT_SUCCESS,
	payload: payment
})

const paymentError = error => ({
	type: PAYMENT_ERROR,
	payload: error
})


const updateUser = user => ({
	type: UPDATE_USER,
	payload: user
})



// const validateToken = user => ({
// 	type: VALIDATE_TOKEN,
// 	payload: user
// })


// API FUNCTIONS

function apiLogin(username, password) {	
	let status;
	return dispatch => {
		return api('/auth', 'post', { username, password })
			.then(res => {
				status = res.status;
				return res.json();
			})
			.then(json => {
				if(json.error) {
					dispatch(loginError({ ...json.error, status }));
					// return { ...json.error, status };
				} else {
					dispatch(login(json));
					// return json;
				}
				return json;
			})
			.catch(err => dispatch(loginError(err)))
	}
}

function apiSignup(username, email, password) {
	let status;
	return dispatch => {
		dispatch(signupBegin());
		return api('/users', 'post', { username, email, password })
			.then(res => {
				status = res.status;
				return res.json();
			})
			.then(json => {
				if(json.error) {
					dispatch(signupError({ ...json.error, status }))
				} else {
					dispatch(signupSuccess(json))
				}
				return json;
			})
			.catch(err => dispatch(signupError(err)))
	}
}

function apiLogout() {
	return dispatch => {
		dispatch(logout());
	}
}


function apiUpdateUser(props) {
	let status;
	return dispatch => {
		return api(`/users`, 'put', props)
			.then(res => {
				status = res.status;
				return res.json();
			})
			.then(json => {
				if(status === 200) {
					dispatch(updateUser(json));
					return json.user;
				} else {
					return json;
				}
			})
			.catch(console.log)
	}
}

function apiStripePayment(paymentMethod, user) {
	let status;
	
	return dispatch => {
		dispatch(paymentBegin());
		return api('/payments', 'post', { paymentMethod, user })
			.then(res => {
				status = res.status;
				return res.json();
			})
			.then(json => {
				if(json.error) {
					dispatch(paymentError({ ...json.error, status }))
				} else {
					dispatch(paymentSuccess(json))
				}
				return json;
			})
			.catch(err => dispatch(paymentError(err)))
	}
}


function apiValidateToken() {
	let token;
	try {
		token = localStorage.getItem('token');
	} catch(err) {
		console.error(err);
	}
	
	return dispatch => {
		if(token) {
			return api('/auth')
				.catch(err => dispatch(validateTokenError()))
				.then(res => res.json())
				.then(json => {
					if(json.error) {
						dispatch(validateTokenError());
					} else {
						dispatch(validateTokenSuccess(json));
					}
				})
				.catch(console.log)
		} else {
			dispatch(validateTokenError());
		}
	}
}


export const authActions = {
	login: apiLogin,
	logout: apiLogout,
	signup: apiSignup,
	validateToken: apiValidateToken,
	updateUser: apiUpdateUser,
	stripePayment: apiStripePayment
}

export const authActionCreators = {
	updateUser
}


export default function reducer(state = defaultState, { type, payload }) {
	switch(type) {
		case LOGIN:
			return {
				...state,
				loading: false,
				error: false,
				loggedIn: true,
				user: payload
			}
			
		case LOGIN_ERROR:
			return {
				...state,
				loading: false,
				error: payload
			}
			
		case LOGOUT:
			return {
				...defaultState,
				loading: false
			}
			
		case VALIDATE_TOKEN_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				loggedIn: true,
				user: payload
			}
			
		case VALIDATE_TOKEN_ERROR:
			return {
				...state,
				loading: false,
				error: false,
				loggedIn: false,
				user: {
					...state.user,
					token: null
				}
			}
			
			case SIGNUP_BEGIN:
				return {
					...state,
					signup: {
						loading: true,
						error: false,
						status: false
					}
				}
				
			case SIGNUP_SUCCESS:
				return {
					...state,
					loggedIn: false,
					user: payload,
					signup: {
						loading: false,
						error: false,
						status: true
					}
				}
				// return {
				// 	...state,
				// 	loggedIn: true,
				// 	user: payload,
				// 	signup: {
				// 		loading: false,
				// 		error: false
				// 	}
				// }
				
			case SIGNUP_ERROR:
				return {
					...state,
					signup: {
						loading: false,
						error: payload,
						status: false
					}
				}
				
			case UPDATE_USER: 
				return {
					...state,
					user: {
						...state.user,
						...payload.user
					}
				}
			
			case PAYMENT_BEGIN:
				return {
					...state,
					subscription: {
						loading: true,
						error: false,
						active: false
					}
				}
				
			case PAYMENT_SUCCESS:
				return {
					...state,
					subscription: {
						loading: false,
						error: false,
						active: payload
					}
				}
				
			case PAYMENT_ERROR:
				return {
					...state,
					subscription: {
						loading: false,
						error: payload,
						active: false
					}
				}
			
		default: 
			return state;		
	}
}


const defaultState = {
	loading: true,
	error: false,
	loggedIn: false,
	user: {
		template: 'default',
	},
	signup: {
		loading: false,
		error: false,
		status: false
	},
	subscription: {
		loading: false,
		error: false,
		active: false
	}
}


// selectors

function getAuth(state) {
	return state.auth;	
}

function getUser(state) {
	return state.auth.user;	
}


export const authSelectors = {
	getUser,
	getAuth
}

export function getToken() {
	return (dispatch, getState) => {
		const state = getState();

		const authToken = state.auth.user.token;

		// Pass the token to the server
		return authToken;
	}
}


let localToken = false;

export function updateLocalStore({ loggedIn, user }) {
	let prevToken = localToken;
	localToken = user.token;
	
	if(prevToken !== localToken) {
		if(loggedIn && user.token) {
			try {
				localStorage.setItem('token', user.token);
			} catch(err) {
				console.error(err);
			}
		} else {
			try {
				localStorage.removeItem('token');
			} catch(err) {
				console.error(err);
			}
		}
	}
}
