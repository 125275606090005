import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authActions, templateActions, templateSelectors } from '../../redux'
import { Page, Tabs, Icon} from '../../components'
import { path } from '../../helpers'
import { Route, Switch, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { AccountSettings } from './AccountSettings'
import { InvoicesSettings } from './InvoicesSettings'
import { FilesSettings } from './FilesSettings'
import { DataSettings } from './DataSettings'


class Settings extends Component {
	render() {
		const tabs = [
			{
				name: "Dein Konto",
				link: "/settings/account",
				icon: "account",
			},
			{
				name: "Firmendaten",
				link: "/settings/your-data",
				icon: "company",
			},
			{
				name: "Rechnungen",
				link: "/settings/invoices",
				icon: "invoices",
			},
			{
				name: "Dateien",
				link: "/settings/files",
				icon: "files",
			}
		];
			
		return <Page>
			<Helmet>
				<title>Einstellungen | Billu</title>
			</Helmet>
		
			<div>
				<h1 className="mb-5">Einstellungen</h1>
				
				<Tabs tabs={tabs} />
			</div>
			
			<Switch>
				<Route exact path="/settings/" render={() => <div className="grid grid-cols-2 gap-5 mt-10">
					<Link to={path("settings", "account")} className="hover:no-underline bg-gray-200 hover:bg-gray-300 transition p-4 rounded-lg text-gray-500 hover:text-green-500">
						<Icon icon="account" className="" svgClassName="w-8 h-8" />
						<h3 className="text-gray-900 mb-2">Dein Konto</h3>
						<p className="text-gray-600">Passwort ändern, Abo</p>
					</Link>
					<Link to={path("settings", "your-data")} className="hover:no-underline bg-gray-200 hover:bg-gray-300 transition p-4 rounded-lg text-gray-500 hover:text-green-500">
						<Icon icon="company" className="" svgClassName="w-8 h-8" />
						<h3 className="text-gray-900 mb-2">Firmendaten</h3>
						<p className="text-gray-600">Adresse, Kontaktdaten, Bankverbindung, Steuersatz</p>
					</Link>
					<Link to={path("settings", "invoices")} className="hover:no-underline bg-gray-200 hover:bg-gray-300 transition p-4 rounded-lg text-gray-500 hover:text-green-500">
						<Icon icon="invoices" className="" svgClassName="w-8 h-8" />
						<h3 className="text-gray-900 mb-2">Rechnungen</h3>
						<p className="text-gray-600">Rechnungsnummern, Rechnungs-Vorlagen</p>
					</Link>
					<Link to={path("settings", "files")} className="hover:no-underline bg-gray-200 hover:bg-gray-300 transition p-4 rounded-lg text-gray-500 hover:text-green-500">
						<Icon icon="files" className="" svgClassName="w-8 h-8" />
						<h3 className="text-gray-900 mb-2">Dateien</h3>
						<p className="text-gray-600">PDF-Einstellungen</p>
					</Link>
				</div>} />
				<Route exact path="/settings/account" render={() => <AccountSettings {...this.props} />} />
				<Route exact path="/settings/your-data" render={() => <DataSettings {...this.props} />} />
				<Route exact path="/settings/invoices" render={() => <InvoicesSettings {...this.props} />} />
				<Route exact path="/settings/files" render={() => <FilesSettings {...this.props} />} />
			</Switch>
		</Page>;
	}
}


const mapStateToProps = (state, props) => {
	return {
		user: state.auth.user,
		templates: {
			...state.templates,
			list: templateSelectors.getTemplatesList(state),
		}
	}
}
	
const mapDispatchToProps = {
	onLogout: authActions.logout,
	onUpdateUser: authActions.updateUser,
	onUpdateTemplate: templateActions.updateTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
