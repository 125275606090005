import { loadFromLocalStorage } from '../../helpers';



const SET_NOTIFICATION = 	'notifications/SET_NOTIFICATION';

const setNotificationAction = value => ({
	type: SET_NOTIFICATION,
	payload: value
})

const setNotification = value => {
	return dispatch => {
		dispatch(setNotificationAction(value));
	}
}

export const notificationActions = {
	setNotification
}

export default function reducer(state = defaultState, { type, payload }) {
	switch(type) {
		case SET_NOTIFICATION: 
			return {
				...state,
				...payload
			}
			
		default: 
			return state	
	}
}

const savedSettings = loadFromLocalStorage('notifications') || { cookiesAccepted: false };

const defaultState = {
	...savedSettings
}

// selectors

function getNotifications(state) {
	return state.notifications;
}

export const notificationSelectors = {
	getNotifications
}
