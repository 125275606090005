import { tags, autoHeightTextarea } from '../../helpers'


export default function createPagePreview( invoice, user, customer ) {
	return new Promise((resolve, reject) => {
		//document.documentElement.style.setProperty('--pageScale', 1);
		
		// console.log(this);
		
		// const placeholderData = [
		// 	{[PLACEHOLDERS.mail]: user.email},
		// 	{[PLACEHOLDERS.tel]: user.tel},
		// 	{[PLACEHOLDERS.address]: formatAddress(user)},
		// 	{[PLACEHOLDERS.date]: germanDate(new Date())},
		// 	{[PLACEHOLDERS.rnr]: invoice.invoicenumber},
		// 	{[PLACEHOLDERS.anschrift]: formatAddress(customer)},
		// 	{[PLACEHOLDERS.url]: user.website},
		// 	{[PLACEHOLDERS.iban]: user.iban},
		// 	{[PLACEHOLDERS.bic]: user.bic},
		// 	{[PLACEHOLDERS.accountholder]: user.accountholder},
		// 	{[PLACEHOLDERS.stnr]: user.taxnumber},
		// 	{[PLACEHOLDERS.deadline]: invoice.deadline}
		// ]
		
		const grid = document.querySelector('.Grid'),
			previewPage = document.querySelector('.previewPage');
		
		previewPage.innerHTML = grid.outerHTML;
		
		const fields = [
			...previewPage.querySelectorAll('input, textarea'),
			...previewPage.querySelectorAll('strong')
			//...previewPage.querySelectorAll('[class*="absender"] span, .total span')
		];
		
		let missing = [],
			previewValues = {};
			
		fields.forEach(x => {
			// let el = document.createElement("textarea");
			// el.style.left = x.getBoundingClientRect().left - pageOffsetX + 'px';
			// el.style.top = x.getBoundingClientRect().top - pageOffsetY + 'px';
			// el.style.width = x.clientWidth + 'px';
			// el.style.height = x.clientHeight + 'px';
			// el.style.padding = getComputedStyle(x)["padding"];
			// el.style.textAlign = getComputedStyle(x)["text-align"];
			// el.style.lineHeight = getComputedStyle(x)["line-height"];
			// el.style.fontSize = getComputedStyle(x)["font-size"];
			// el.style.fontWeight = getComputedStyle(x)["font-weight"];
			// el.style.color = getComputedStyle(x)["color"];
			// // el.style.textTransform = getComputedStyle(x)["text-transform"];
			// el.style.letterSpacing = getComputedStyle(x)["letter-spacing"];
			// el.style.borderWidth = getComputedStyle(x)["border-width"];
			// el.style.borderStyle = getComputedStyle(x)["border-style"];
			// el.style.borderColor = getComputedStyle(x)["border-color"];
			// el.style.borderRadius = getComputedStyle(x)["border-radius"];
			

			//el.value = x.value || x.innerHTML;
						
			//if(getComputedStyle(x)["text-transform"] === "uppercase") el.value = el.value.toUpperCase();
			
			//autoHeightTextarea(el);
			//previewPage.appendChild(el);
			
			
			// previewData.push({
			// 	el: x,
			// 	val: x.value
			// });
			
			x.setAttribute('placeholder', '');
			
			let replacedTags = tags(x.value, { invoice, user, customer });
			
			x.value = replacedTags.string; //.replace(/\*\*(.+)\*\*/g, "<strong>$1</strong>");
			missing = [...missing, ...replacedTags.missing];
			
			previewValues[x.name] = replacedTags;
			
			// const d = document.createElement('div');
			// d.classList.add('export-field');
			// d.classList.add('whitespace-pre');
			// d.innerHTML = x.value;
			// x.parentNode.replaceChild(d, x);
		
			x.value && autoHeightTextarea(x);
			
		})
		resolve(previewValues);
	})
}
