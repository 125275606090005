import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import reducers from './ducks/reducers'
import { updateLocalStore } from './ducks/auth'
import { saveToLocalStorage } from '../helpers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;



const localStorageMiddleware = store => next => action => {
	const result = next(action)
	
	const persistInvoicesActions = ['invoices/SET_FILTER', 'invoices/SET_SORT'];
	const persistFilesActions = ['files/SET_VIEW', 'files/SET_FILTER', 'files/SET_SORT'];
	const persistCustomersActions = ['customers/SET_SORT'];
	const persistNotificationsActions = ['notifications/SET_NOTIFICATION'];
	// const persistAuthActions = ['auth/LOGIN', 'auth/VALIDATE_TOKEN_SUCCESS', 'auth/VALIDATE_TOKEN_ERROR'];
	
	
	if(persistInvoicesActions.indexOf(action.type) !== -1) {
		const { filterBy, sortBy } = store.getState().invoices;
		saveToLocalStorage('invoices', { filterBy, sortBy });
	}
	
	if(persistFilesActions.indexOf(action.type) !== -1) {
		const { viewBy, filterBy, sortBy } = store.getState().files;
		saveToLocalStorage('files', { viewBy, filterBy, sortBy });
	}
	
	if(persistCustomersActions.indexOf(action.type) !== -1) {
		const { sortBy } = store.getState().customers;
		saveToLocalStorage('customers', { sortBy });
	}
	
	if(persistNotificationsActions.indexOf(action.type) !== -1) {
		saveToLocalStorage('notifications', store.getState().notifications);
	}
	
	// if(persistAuthActions.indexOf(action.type) !== -1) {
	// 	const { user, loggedIn } = store.getState().auth;
		
	// 	if(loggedIn && user.token) {
	// 		saveToLocalStorage('token', user.token);
	// 	} else {
	// 		saveToLocalStorage('token', null);
	// 	}
	// }
	
	return result
}

const store = createStore(
	reducers, 
	composeEnhancers(
		applyMiddleware(thunk, localStorageMiddleware)
	)
);



//const unsubscribe = 
store.subscribe(() => {
	updateLocalStore(store.getState().auth);
})

export default store;
