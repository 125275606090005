import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { FormField, RouterPrompt } from '../../../components'
import Button, { COLORS } from '../../../components/button'
import { tags } from '../../../helpers'

class FilenameForm extends Component {	
	state = {
		initalized: false,
		data: {}
	}
	
	customer = {
		customerid: 100,
		title: 'Beispielfirma',
		company: 'Beispielfirma',
		firstname: 'Vorname',
		lastname: 'Nachname'
	}
	invoice = {
		invoicenumber: 5,
		invoicedate: '20.04.2020',
		daeadline: ''
	}
	
	constructor(props) {
		super(props);
		
		this.update = this.update.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
	}

	componentDidUpdate() {
		this.initForm(this.props);
	}
		
	initForm(props) {
		if(!this.state.initalized && !props.loading && props.data) {
			this.setState({
				initalized: true,
				data: props.data
			})
		}
	}
	
	update(values) {
		return new Promise((resolve, reject) => {
			this.props.update(values)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	render() {
		const { user } = this.props;
		
		return (
			<Form 
				onSubmit={this.update}
				initialValues={this.state.data}
				render={({ handleSubmit, values, valid, form, pristine, submitting }) => (
					<form onSubmit={e => {
							valid ?
								handleSubmit(e).then(() => form.reset(this.state.data)) : 
								handleSubmit(e); 
						}}>

						<FormField name="filenameformat" label="Format für Dateinamen" secondLabel="(ohne .pdf)"/>
						
						<div className="p-3 rounded bg-gray-200 mt-6">
							<h4 className="mt-1">So sieht's später aus (mit Beispieldaten):</h4>
							<div className="whitespace-pre overflow-auto bg-gray-300 rounded p-2">{tags(values.filenameformat, { user, invoice: this.invoice, customer: this.customer }).string}.pdf</div>
						</div>
						
						<Button
							text="Speichern"
							action="submit"
							disabled={submitting || pristine}
							working={submitting}
							color={COLORS.PRIMARY}
							className="mt-6" />
						
						<RouterPrompt 
							when={true}
							navigate={location => this.props.history.push(location)}
							shouldBlockNavigation={location => {
								if(!pristine) return true;
								return false;
							}} 
							action={() => handleSubmit()} />
					</form>	
				)}
			/>
		)
	}
}

export default FilenameForm;
