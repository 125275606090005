import React from 'react'
import { Icon } from '../../components'

import styles from './styles.module.scss'

const StarButton = ({ state, onClick, className }) => (<div 
	onClick={() => {onClick()}}
	className={["inline-flex rounded-full -mt-1 -mb-1 justify-center items-center hover:bg-yellow-100 cursor-pointer transition-colors duration-100", className, styles.Button, state ? "text-yellow-500 hover:text-yellow-500" : "text-gray-500 hover:text-yellow-500"].join(" ")}>
	<span style={{transform: "translate(0, -5%)"}}>
		{state ? 
			<Icon icon="starFull" stroke={false} fill={true} svgClassName="h-6 w-6" /> :
			<Icon icon="star" svgClassName="h-6 w-6" />
		}
	</span>
</div>)

export default StarButton;
