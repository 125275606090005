import React, { Component } from 'react'
import { Status, ContextMenu, Icon, SortHeader, SelectionCheckbox } from '../../components'
import { Link } from 'react-router-dom'
import tableStyles from '../../styles/tables.module.scss'
import { path, numberToEuro, getLastFileDownloadLink, CropTable } from '../../helpers'



class InvoiceList extends Component {
	
	render() {
		const { list, count = list.length, files, user, limit, sortBy, actions, onUpdateInvoice } = this.props;
		
		const { showSortHeader, showSelection, showFooter, showCreateNewLink } = this.props;
		
		let totalAmount = 0;

		return (
			<div className="relative">
				<table>
					<thead>
						<tr>
							{showSelection ?
								<th className={[tableStyles.Select, tableStyles.SelectHeader].join(' ')}>
									<SelectionCheckbox isHeader={true} {...this.props} />
								</th> : null
							}
							<th className={tableStyles.InvoiceNumber}>
								{showSortHeader ? <SortHeader title="Nr." field="invoicenumber" sortBy={sortBy} setSort={actions.setSort}/> : <span>Nr.</span>}
							</th>
							<th>
								{showSortHeader ? <SortHeader title="Titel" field="title" sortBy={sortBy} setSort={actions.setSort}/> : <span>Titel</span>}
							</th>
							<th className={tableStyles.Status}>
								{showSortHeader ? <SortHeader title="Stadium" field="status" sortBy={sortBy} setSort={actions.setSort}/> : <span>Stadium</span>}
							</th>
							<th className={tableStyles.Amount}>
								{showSortHeader ? <SortHeader title="Betrag" field="total" sortBy={sortBy} setSort={actions.setSort}/> : <span>Betrag</span>}
							</th>
							<th className={tableStyles.ContextMenu}></th>
						</tr>
					</thead>
					<tbody className="text-gray-800">
						{list.slice(0, limit)
						.map(({ id, title, updated, invoicedate, invoicenumber, status, deadline, exported, total, positions, tags }) => {
							const exclude = tags && tags.indexOf("exclude") !== -1;
							
							if(!exclude) totalAmount += total;

							return <tr key={id} id={id}>
								{showSelection ?
									<td className={tableStyles.Select}>
										<SelectionCheckbox id={id} {...this.props} />
									</td> : null
								}
								<td className={[tableStyles.InvoiceNumber, exported ? 'text-black' : 'text-gray-400'].join(' ')}>
									<Link to={path('invoices', 'edit', id)}>{invoicenumber}</Link>
								</td>
								<td className={tableStyles.Title}>
									<CropTable>
										<Link to={path('invoices', 'edit', id)} title={title}>{title ? title : <span className="text-gray-600">Kein Titel</span>}</Link>
									</CropTable>
								</td>
								<td className={tableStyles.Status}>
									<Status status={status} invoicedate={invoicedate} deadline={deadline} />
								</td>
								<td className={[tableStyles.Amount, "text-gray-600"].join(' ')}>
									{exclude && "("}
									<span>{numberToEuro(total)}</span>
									{exclude && ")"}
								</td>
								<td className={tableStyles.ContextMenu}>
									<div>
										<ContextMenu items={[
											{
												label: 'Bearbeiten',
												icon: 'edit',
												link: path('invoices', 'edit', id)
											},
											{
												element: <Status status={3} />,
												hide: [0, 1, 3].indexOf(status) !== -1,
												onClick: () => onUpdateInvoice(id, { status: 3 })
											},
											{
												element: <Status status={2} invoicedate={invoicedate} deadline={deadline} />,
												hide: [0, 1, 2].indexOf(status) !== -1,
												onClick: () => onUpdateInvoice(id, { status: 2 })
											},
											{
												label: 'Kopieren',
												icon: 'copy',
												link: path('invoices', 'create', `?action=duplicate&id=${id}`)
											},
											{
												label: "PDF herunterladen",
												hide: status === 0 || status === 1,
												icon: 'download', 
												onClick: () => {
													const downloadLink = getLastFileDownloadLink(files, user, id);
													if(downloadLink) window.location.href = downloadLink;
												}
											},
											{
												label: 'In den Papierkorb',
												icon: 'delete',
												color: 'red',
												onClick: () => onUpdateInvoice(id, { intrash: true })
											}
											/*,
											{
												label: 'Stornieren',
												icon: 'x-circle',
												onClick: () => {}
											}*/
										]} 
										position={["bottom right", "top right"]} />
									</div>
								</td>
							</tr>	
						})}
					</tbody>
					{showFooter ? <tfoot>
						<tr className="text-gray-500">
							<td colSpan={showSelection ? 3 : 2} className="">
								<span><span className="text-gray-700">{count}</span> Rechnungen gefunden</span>
								{count > limit && <span className="ml-1">({limit} angezeigt)</span>}
							</td>
							<td colSpan="2" className={tableStyles.Amount}>
								{<span>Gesamt: <span className="text-gray-700">{numberToEuro(totalAmount)}</span></span>}
							</td>
							<td></td>
						</tr>
						{showCreateNewLink ?
							<tr className={tableStyles.ActionRow}>
								<td className="p-0" colSpan="10">
									<Link to={path('invoices', 'create')} className="inline-flex items-center p-1 rounded hover:text-black hover:no-underline hover:bg-gray-300 text-gray-500 hover:text-green-500 my-2">
										<Icon icon="add" className="mr-1"/>
										<span className="leading-relaxed text-gray-700">Neue Rechnung erstellen</span>
									</Link>
								</td>
							</tr> : null
						}
					</tfoot> : null}
				</table>
			</div>
		);
	}
}

export default InvoiceList;
