import { api } from '../../helpers';

const LOAD_TEMPLATES_BEGIN 					= 'templates/LOAD_TEMPLATES_BEGIN';
const LOAD_TEMPLATES_SUCCESS				= 'templates/LOAD_TEMPLATES_SUCCESS';
const LOAD_TEMPLATES_ERROR 					= 'templates/LOAD_TEMPLATES_ERROR';

const ADD_TEMPLATE_BEGIN 						= 'templates/ADD_TEMPLATE_BEGIN';
const ADD_TEMPLATE_SUCCESS 					= 'templates/ADD_TEMPLATE_SUCCESS';
const ADD_TEMPLATE_ERROR 						= 'templates/ADD_TEMPLATE_ERROR';

const UPDATE_TEMPLATE_BEGIN 				= 'templates/UPDATE_TEMPLATE_BEGIN';
// const UPDATE_TEMPLATE_SUCCESS 			= 'templates/UPDATE_TEMPLATE_SUCCESS';
// const UPDATE_TEMPLATE_ERROR 				= 'templates/UPDATE_TEMPLATE_ERROR';

// const DELETE_TEMPLATE_BEGIN 				= 'templates/DELETE_TEMPLATE_BEGIN';
// const DELETE_TEMPLATE_SUCCESS 			= 'templates/DELETE_TEMPLATE_SUCCESS';
// const DELETE_TEMPLATE_ERROR 				= 'templates/DELETE_TEMPLATE_ERROR';


const loadTemplatesBegin = () => ({
	type: LOAD_TEMPLATES_BEGIN
})

const loadTemplatesError = error => ({
	type: LOAD_TEMPLATES_ERROR,
	payload: error
})

const loadTemplatesSuccess = templates => ({
	type: LOAD_TEMPLATES_SUCCESS,
	payload: templates
})


const addTemplateBegin = () => ({
	type: ADD_TEMPLATE_BEGIN
})

const addTemplateSuccess = template => ({
	type: ADD_TEMPLATE_SUCCESS,
	payload: template
})

const addTemplateError = error => ({
	type: ADD_TEMPLATE_ERROR,
	payload: error
})


const updateTemplateSuccess = template => ({
	type: UPDATE_TEMPLATE_BEGIN,
	payload: template
})

// const deleteTemplateBegin = template => ({
// 	type: DELETE_TEMPLATE_SUCCESS,
// 	payload: template
// })



function apiLoadTemplates() {
	return dispatch => {
		dispatch(loadTemplatesBegin());
		
		return api('/templates')
      .then(res => res.json())
      .then(json => {
      	dispatch(loadTemplatesSuccess(json));
      })
      .catch(err => dispatch(loadTemplatesError(err)))
	}
}

function apiAddTemplate(props = {}) {
	let status;
	
	return dispatch => {
		dispatch(addTemplateBegin())
		return api('/templates', 'post', props)
			.then(res => {
				status = res.status;
				return res.json();
			})
			.then(json => {
				if(json.error) {
					dispatch(addTemplateError(json.error));
				} else if(status === 201) {
	      	dispatch(addTemplateSuccess(json));
	      	return json.item;
      	}
      })
      .catch(console.log)
	}
}

function apiUpdateTemplate(id, props) {
	let status;
	
	return dispatch => {
		return api(`/templates/${id}`, 'put', props)
			.then(res => {
				status = res.status;
				return res.json();
			})
      .then(json => {
      	if(status === 200) {
    			dispatch(updateTemplateSuccess(json));
    			return json.item;
      	} else {
      		return json;
      	}
      })
      .catch(console.log)
	}
}

// function apiDeleteTemplate(id) {
// 	return dispatch => {
// 		return api(`/templates/${id}`, 'delete')
//       .then(res => res.json())
//       .then(json => {
//       	dispatch(deleteTemplateBegin({id, ...json}));
//       })
//       .catch(console.log)
// 	}
// }


export const templateActions = {
	loadTemplates: apiLoadTemplates,
	addTemplate: apiAddTemplate,
	updateTemplate: apiUpdateTemplate,
	// deleteTemplate: apiDeleteTemplate
}



export default function reducer(state = defaultState, { type, payload }) {
	let list = {};
	
	switch(type) {
		case LOAD_TEMPLATES_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
			
		case LOAD_TEMPLATES_ERROR:
			return {
				...state,
				loading: false,
				initiated: true,
				error: payload.error,
			};
			
		case LOAD_TEMPLATES_SUCCESS:
			list = payload.list.reduce((acc, x) => {
				acc[x.id] = x;
				return acc;
			}, {});
			
			return {
				...state,
				loading: false,
				initiated: true,
				error: null,
				list
			};
			
		case ADD_TEMPLATE_BEGIN:
			return {
				...state,
				loading: true,
				error: null
			};
			
		case ADD_TEMPLATE_SUCCESS: 
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state,
				loading: false,
				error: null,
				list
			};
			
		case ADD_TEMPLATE_ERROR: 
			return {
				...state,
				loading: false,
				error: payload
			}
			
		case UPDATE_TEMPLATE_BEGIN:
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state,
				list
			}
			
		// case DELETE_TEMPLATE_SUCCESS:
		// 	list = { ...state.list };
		// 	delete list[payload.id];
			
		// 	return {
		// 		...state,
		// 		list
		// 	};

		default:
			return state;
	}
}

const defaultState = {
	loading: false,
	initiated: false,
	error: null,
	list: {},
}


// selectors

function getTemplatesList(state, useFilter = {}) {
	const templates = state.templates;
	let list = Object.values(templates.list) || [];

	return list; 
}

function getTemplateById(state, id) {
	return state.templates.list[id] || {};
}

function getTemplateByName(state) {
	return null;
}

function getSelectedTemplate({ templates, auth }) {
	if(templates.loading || !auth.user) return null;
	return templates.list[auth.user.templateid] || null;
}


export const templateSelectors = {
	getTemplatesList,
	getTemplateById,
	getTemplateByName,
	getSelectedTemplate
}
