import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'
import { FormField, Checkbox, RouterPrompt } from '../../../components'
import Button, { COLORS } from '../../../components/button'
import iban from 'iban';
import styles from './styles.module.scss'

class TaxesForm extends Component {

	state = {
		initalized: false,
		data: {}
	}
	
	constructor(props) {
		super(props);
		
		this.update = this.update.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
	}

	componentDidUpdate() {
		this.initForm(this.props);
	}
		
	initForm(props) {
		if(!this.state.initalized && !props.loading && props.data) {
			this.setState({
				initalized: true,
				data: props.data
			})
		}
	}
	
	update(values) {
		return new Promise((resolve, reject) => {
			this.props.update(values)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	validate(values) {
  	const errors = {};
  	if(values.iban && !iban.isValid(values.iban)) errors.iban = "Die IBAN ist ungültig"
  	return errors;
  }
	
	render() {
		return (
			<Form 
				onSubmit={this.update}
				initialValues={this.state.data}
				validate={this.validate}
				render={({ handleSubmit, values, valid, form,  pristine, submitting }) => (
					<form onSubmit={e => {
							valid ?
								handleSubmit(e).then(() => form.reset(this.state.data)) : 
								handleSubmit(e); 
						}}>
						
						<FormField name="taxnumber" label="Steuernummer" placeholder="00/111/22222" className="tnum" tag="user.taxnumber" />
						<FormField name="vatid" label="Umstatzsteuer-ID" placeholder="DE000000000" className="tnum" tag="user.vatid" />
						{/*<FormField name="novat" label="Kleinunternehmer*innen-Regelung" checkboxLabel="Berechnung der Umsatzsteuer deaktivieren" type="checkbox"/>*/}
						
						<p className="mt-6 mb-1">Kleinunternehmenden-Regelung</p>
						
						<Field name="novat" type="radio" value={false} parse={value => value === "true"}>
							{({ input, meta }) => <label htmlFor="novat1" className={["p-4 pr-9 mt-0 bg-white rounded-sm border focus-within:border-t-2heme relative", styles.TaxesFormRadio].join(' ')}>
								<Checkbox {...input} name="novat1" id="novat1" value="false" checked={input.checked} type="radio" className="absolute top-0 right-0 mt-2 mr-2"/>
								<strong className="leading-snug">Umsatzsteuer berechnen</strong>
								<p className="mt-1 text-gray-600 leading-snug">Wenn du in deinen Rechnungen die Umsatzsteuer ausweist und an das Finanzamt abführst.</p>
							</label>}
						</Field>
						
						<Field name="novat" type="radio" value={true} parse={value => value === "true"}>
							{({ input, meta }) => <label htmlFor="novat2" className={["p-4 pr-9 mt-3 mb-4 bg-white rounded-sm border focus-within:border-t-2heme relative", styles.TaxesFormRadio].join(' ')}>
								<Checkbox {...input} name="novat2" id="novat2" value="true" checked={input.checked} type="radio" className="absolute top-0 right-0 mt-2 mr-2"/>
								<strong className="leading-snug">Kleinunternehmenden-Regelung nutzen</strong>
								<p className=" mt-1 text-gray-600 leading-snug">Wenn dein Umsatz des letzen Jahres unter 17.500 € (22.000 € ab 2020) liegt und du laut <span className="whitespace-no-wrap">§ 19 UStG</span> als Kleinunternehmer*in giltst.</p>
							</label>}
						</Field>	
						
						{/*<Alert><p>Achtung: Diese Option darfst du nur nutzen, wenn du laut <span className="whitespace-no-wrap">§ 19 UStG</span> als Kleinunternehmer giltst. Wenn du keine Umsatzsteuer in deiner Rechnung ausweist musst du deine Kund/innen darauf hinweisen.</p></Alert>*/}
						{/*<Alert>Steuersatz</Alert>*/}
						
						<Button
							text="Speichern"
							action="submit"
							disabled={submitting || pristine}
							working={submitting}
							color={COLORS.PRIMARY}
							className="mt-6" />
							
						<RouterPrompt 
							when={true}
							navigate={location => this.props.history.push(location)}
							shouldBlockNavigation={location => {
								if(!pristine) return true;
								return false;
							}} 
							action={() => handleSubmit()} />
					</form>	
				)}
			/>
		)
	}
}

export default TaxesForm;
