import React, { Component } from 'react'
import { ConfirmModal, Icon, Alert } from '../../../components'
import Button, { SIZES, COLORS, TYPES } from '../../../components/button'
import { api, downloadLink, hasPermissions } from '../../../helpers'
import toast from 'react-hot-toast'
import { HashLink } from 'react-router-hash-link'

function buttonString(length) {
	return `${length} Datei${length > 1 ? "en" : ""}`;
}

class FilesHeader extends Component {
	state = {
		showConfirmTrashModal: false,
		showConfirmDeleteModal: false,
		showConfirmRestoreModal: false,
		deletePhrase: "",
		deleteKeyword: "LÖSCHEN"
	}
	
	onShowConfirmTrashModal(val) {
		this.setState({ showConfirmTrashModal: val })
	}
	
	onShowConfirmDeleteModal(val) {
		this.setState({ showConfirmDeleteModal: val })
	}
	
	onShowConfirmRestoreModal(val) {
		this.setState({ showConfirmRestoreModal: val })
	}
	
	onTypeDeleteFiles(e) {
		this.setState({
			deletePhrase: e.target.value
		})
	}
	
	render() {
		const { trash = false, setView, setFilter, viewBy, selection, onUpdateSelection, onUpdateFile, onDeleteFile } = this.props;
		const { showConfirmTrashModal, showConfirmDeleteModal, showConfirmRestoreModal } = this.state;
		
		const canDownloadMultipleFiles = hasPermissions("files:download_multiple");
		
		return (
			<div>
				<header className="mb-5 flex-col flex">
					<div className="w-full">
						<h1 className="m-0">{!trash ? "Dateien" : "Papierkorb"}</h1>
						<div className="my-4">
							{selection.length <= 0 ? <div className="flex justify-between items-center">
								<div className="flex gap-3">
										{!trash ? <Button
											text="Papierkorb"
											icon="delete"
											action={() => setFilter({ trash: true })}
											type={TYPES.GHOST} 
											size={SIZES.SMALL}
											className="mt-0"
											/> : 
										<Button
											text="Zurück zur Liste"
											icon="arrowLeft"
											action={() => setFilter({ trash: false })}
											type={TYPES.GHOST} 
											size={SIZES.SMALL}
											className="mt-0"
											/>
									}
								</div>
								<button onClick={() => setView(viewBy !== "grid" ? "grid" : "list")} className={["inline-flex items-center p-1 rounded hover:text-black hover:no-underline hover:bg-gray-300 text-gray-600 hover:text-gray-900"].join(" ")}>
									<Icon icon={viewBy === "grid" ? "list" : "grid"} />
								</button> 
							</div> : <React.Fragment>
								{!trash ? <div>
									<div className="flex gap-3">
										<Button
											text={`${buttonString(selection.length)} herunterladen`}
											icon="download"
											disabled={!canDownloadMultipleFiles}
											action={() => {
												const fileDownlaod = api('/download/zip', 'post', { files: selection })
													.then(res => {
														return res.blob().then((data) => {
															return {
																data: data,
																filename: res.headers.get('Content-Disposition'),
															};
														});
											    })
											    .then(({ data, filename }) => {
											        const blob = new Blob([data], { type: 'application/zip' });
											        const downloadUrl = URL.createObjectURL(blob);
											        
											        downloadLink(downloadUrl, filename.split("=")[1]);
											        console.log("done");
											    })
													.catch(err => console.error)
												toast.promise(fileDownlaod, {
													loading: "Dateien werden zum Download vorbereitet",
													success: "Dateien heruntergeladen",
													error: "Fehler beim herunterladen"
												})
											}}
											color={COLORS.PRIMARY}
											size={SIZES.SMALL}
											className="mt-0"
											/>
										<Button
											text={`${buttonString(selection.length)} in den Papierkorb verschieben`}
											icon="delete"
											action={() => {
												this.onShowConfirmTrashModal(true);
											}}
											color={COLORS.DANGER}
											size={SIZES.SMALL}
											className={["mt-0"]}/>
											
										{showConfirmTrashModal && <ConfirmModal>
											<h3 className="mt-0 mb-4">Willst du wirklich {buttonString(selection.length)} in den Papierkorb verschieben?</h3>
											<div className="flex justify-between mt-5">
												<Button
													text="Abbrechen"
													action={() => this.onShowConfirmTrashModal(false)}
													color={COLORS.SECONDARY} 
													className="mt-0"/>
												<span className="flex-grow"></span>
												<Button
													text={`${buttonString(selection.length)} in den Papierkorb verschieben`}
													icon="delete"
													action={() => {
														this.onShowConfirmTrashModal(false)
														selection.forEach(x => onUpdateFile(x, { intrash: true }));
														toast.success(`${buttonString(selection.length)} in den Papierkorb verschoben`)
														onUpdateSelection([], true);
													}}
													color={COLORS.DANGER}
													className={["mt-0"]}/>
											</div>
										</ConfirmModal>}
									</div>
									{!canDownloadMultipleFiles ? <Alert className="">
										<div className="flex items-center justify-start">
											<Icon icon="lock" className="mr-2 text-red-600" />
											<span>Um mehrere Dateien auf einmal herunterzuladen benötigst du mindestens das <strong>Solo-Paket</strong>. <HashLink smooth to="/settings/account#subscription" className="text-green-600 underline font-medium">Jetzt upgraden</HashLink>
											</span>
										</div>
									</Alert> : null}
								</div> : <div className="flex gap-3">
									<Button
										text={`${buttonString(selection.length)} wiederherstellen`}
										icon="restore"
										action={() => {
											this.onShowConfirmRestoreModal(true);
										}}
										size={SIZES.SMALL}
										className={["mt-0"]}/>
									{showConfirmRestoreModal && <ConfirmModal>
										<h3 className="mt-0 mb-4">Willst du wirklich {buttonString(selection.length)} wiederherstellen?</h3>
										<div className="flex justify-between mt-5">
											<Button
												text="Abbrechen"
												action={() => this.onShowConfirmRestoreModal(false)}
												color={COLORS.SECONDARY} 
												className="mt-0"/>
											<span className="flex-grow"></span>
											<Button
												text={`${buttonString(selection.length)} wiederherstellen`}
												icon="restore"
												action={() => {
													this.onShowConfirmRestoreModal(false)
													selection.forEach(x => onUpdateFile(x, { intrash: false }));
													toast.success(`${buttonString(selection.length)} wiederhergestellt`)
													onUpdateSelection([], true);
												}}
												color={COLORS.PRIMARY}
												className={["mt-0"]}/>
										</div>
									</ConfirmModal>}
									
									<Button
										text={`${buttonString(selection.length)} endgültig löschen`}
										icon="delete"
										action={() => {
											this.onShowConfirmDeleteModal(true);
										}}
										color={COLORS.DANGER}
										size={SIZES.SMALL}
										className={["mt-0"]}/>
										
									{showConfirmDeleteModal && <ConfirmModal>
										<h3 className="mt-0 mb-4">Willst du wirklich {buttonString(selection.length)} endgültig löschen?</h3>
										
										<div className="FormField">
											<p>Schreibe <span className="text-red-600 bg-gray-200 rounded-sm p-1 leading-snug tracking-wider text-sm font-medium">{this.state.deleteKeyword}</span> in das Textfeld, um das Löschen zu bestätigen.</p>
											<Alert>Das löschen von Dateien kann nicht rückgängig gemacht werden.</Alert>
											<input type="text" placeholder={this.state.deleteKeyword} onChange={e => this.onTypeDeleteFiles(e)} />
										</div>
										<div className="flex justify-between mt-5">
											<Button
												text="Abbrechen"
												action={() => this.onShowConfirmDeleteModal(false)}
												color={COLORS.SECONDARY} 
												className="mt-0"/>
											<span className="flex-grow"></span>
											<Button
												disabled={this.state.deletePhrase !== this.state.deleteKeyword}
												text={`${buttonString(selection.length)} endgültig Löschen`}
												icon="delete"
												action={() => {
													this.onShowConfirmDeleteModal(false)
													selection.forEach(x => onDeleteFile(x));
													onUpdateSelection([], true);
												}}
												color={COLORS.DANGER}
												className={["mt-0"]}/>
										</div>
									</ConfirmModal>}
								</div>}
							</React.Fragment>}
						</div>
					</div>
				</header>
			</div>
		);
	}
}

export default FilesHeader;
