import React, { Component } from 'react'
import { authActions, authSelectors } from '../../redux'
import { connect } from 'react-redux'
import { Page, FormField, LoadingIndicator, Alert, Icon, ScrollToTop } from '../../components'
import { Link } from 'react-router-dom'
import Button, { COLORS, SIZES } from '../../components/button'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { FORM_ERROR } from 'final-form'
import { Helmet } from 'react-helmet'
// import queryString from 'query-string'

const focusOnError = createDecorator()


class Login extends Component {
	state = {
		showPassword: false
	}
	
	constructor(props) {
		super(props);

		this.onLogin = this.onLogin.bind(this);
		this.onGetPasswordResetLink = this.onGetPasswordResetLink.bind(this);
		this.toggleShowPassword = this.toggleShowPassword.bind(this);
	}
	
	componentDidUpdate() {		
		const { loggedIn, user, location } = this.props;
		
		if(loggedIn) {
			if(!user.onboardingconfirmed && !location.pathname.match(/(setup)/)) {
				// redirect to setup
				this.props.history.push({pathname: '/setup/'});
			} else {
				// redirect to dashboard
				this.props.history.push({pathname: '/'});
			}
		}
	}
	
	toggleShowPassword(e) {
		this.setState({
			showPassword: e.target.checked
		})
	}
	
	
	onLogin({ username, password }) {
		return this.props.onLogin(username, password)
			.then(res => (
				res.error ? (
					res.error.statusCode === 401 ? { [FORM_ERROR]: "Dein Nutzername oder dein Passwort stimmt nicht." } : { [FORM_ERROR]: res.error.message }
				) : null
			))
	}
	
	
	onGetPasswordResetLink(login) {
		return this.props.onGetPasswordResetLink(login)
			.then(console.log)
	}
	
	
	validate(values) {
  	const errors = {};
  	if(!values.username) errors.username = "Gib deinen Nutzernamen ein"
  	if(!values.password) errors.password = "Gib dein Passwort ein"
  	return errors;
  }
	
	render() {
		const { location, loading, error, match } = this.props;
		
		const status = match && match.params && match.params.status;
		
		// const defaultUsername = queryString.parse(location.search || "");
		
		
		return (
			<Page>
				<ScrollToTop />
				<Helmet>
					<title>Anmelden | Billu</title>
				</Helmet>
				<div className="max-w-md mx-auto">
					<Icon icon="logo-icon" className="mx-auto table mb-6" svgClassName="w-16 h-16" stroke={false}/>
					<div className="bg-white p-10 rounded-lg">
						<h2 className="mt-0">Melde dich an</h2>
						{!status && 
							<p className="mt-4 mb-8">oder <Link to="/signup" className="text-theme-500 hover:no-underline hover:text-black">erstelle ein neues Konto</Link></p>}
						
						{(location && location.message ) && 
							<Alert className="mt-5 bg-green-200 text-gray-900">{location.message}</Alert>}
						
						{status === 'confirmed' &&
							<Alert className="mt-5 bg-green-200 text-gray-900">Du hast deine E-Mail-Adresse bestätigt und dein Konto wurde aktiviert. Du kannst dich jetzt anmelden.</Alert>}
							
						{status === 'error' && 
							<Alert className="mt-5 bg-red-200 text-gray-900"><span>Deine E-Mail-Adresse konnte nicht bestätigt werden. Bitte probiere es später noch einmal oder nimm <a href="https://billu.app/support/" target="_blank" rel="noopener noreferrer" className="underline hover:text-gray-700">Kontakt</a> zu uns auf.</span></Alert>}
							
						{loading ? (
							<div className="relative mt-12 mb-16"><LoadingIndicator /></div>
						) : (
							<Form
					      onSubmit={this.onLogin}
					      decorators={[focusOnError]}
					      validate={this.validate}
					      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
									<form onSubmit={handleSubmit}>
											
										{(!submitting && (submitError || error)) && <Alert className="mt-5 bg-red-200 text-gray-900">{submitError || error}</Alert>}
										
										<FormField name="username" label="Nutzername/E-Mail" autoFocus={true}/>
										<FormField name="password" label="Passwort" type="password" className="tnum" />
										{/*<FormField name="session" label="Eingeloggt bleiben" type="checkbox"/>*/}
										
										<Button 
											text="Anmelden" 
											disabled={submitting} 
											action='submit' 
											working={submitting}
											color={COLORS.PRIMARY} 
											size={SIZES.LARGE} 
											className="block mt-6" />
									</form>
								)} 
							/>
						)}
					</div>
				</div>
				<Link to="/reset-password" className="hover:no-underline text-gray-600 hover:text-theme-500 table mx-auto mt-6">Passwort vergessen?</Link>
			</Page>
		)
	}
}


const mapStateToProps = state => {
	const { auth } = state;
	
	return {
		loading: auth.loading,
		loggedIn: auth.user.token ? true : false,
		error: auth.error,
		// user: auth.user,
		user: authSelectors.getUser(state),
	}
}

const mapActionsToProps = {
	onLogin: authActions.login,
	onGetPasswordResetLink: authActions.getPasswordResetLink
}

export default connect(mapStateToProps, mapActionsToProps)(Login);
