// class ImportError extends Error {}

const loadTemplate = async (templateName, defaults = {}) => {
	if(!templateName) return false; //templateName = "default";
	try {
		const { default: template } = await import(`./${templateName}`);
		return {
			...template, 
			defaults: {
				...template.defaults,
				...defaults
			}
		}
	} catch (error) {
		console.info(`Template ./${templateName} not found`)
		// throw new ImportError(`Unable to import module ./${templateName}`) 
	}
};

export { loadTemplate }
