import React, { Component } from 'react'
import { Field } from 'react-final-form'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { Icon } from '../'


const MultiValueRemove = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref} >
      <div>
      	<Icon icon="cancel" />
      </div>
    </div>
  );
};

const formatOptionLabel = ({ value, label, subline, customerid, favorite }) => (
	<div>
		<div className="flex items-center justify-between">
			<div>
				{label}
				{/*<span className="text-gray-700 ml-2">#{customerid}</span>*/}
			</div>
			{favorite ? <span className="text-yellow-500"><Icon icon="starFull" stroke={false} fill={true} svgClassName="h-5 w-5" /></span> : null}
		</div>
		{subline && 
			<div className="display-block text-xs text-gray-600 whitespace-no-wrap truncate">
				{subline}
			</div>
		}
	</div>
);


const SingleValue = ({ selectProps, data, ...props }) => {
	return <div>
		<span>{selectProps.getOptionLabel(data)}</span>
	</div>
}

const ClearIndicator = props => {
  const { innerProps: { ref, ...restInnerProps } } = props;
  
  return <div
    {...restInnerProps}
    ref={ref} >
    <div className="p-1">
    	<Icon icon="cancel" />
    </div>
  </div>
};

const DropdownIndicator = props => {
  const { innerProps: { ref, ...restInnerProps } } = props;
  
  return <div
    {...restInnerProps}
    ref={ref} >
    <div className="p-1 px-2 mr-1 text-xs">
    	<Icon icon="chevronDown" />
    </div>
  </div>
};




class FormFieldSelect extends Component {
	
	render() {
		
		const customStyles = {
		  control: (provided, state) => ({
				...provided,
				borderColor: 'inherit',
				minHeight: 42.5,
				borderRadius: state.isFocused ? '0.125rem 0.125rem 0 0' : '0.125rem',
		  	color: state.isFocused ? 'var(--gray-600)' : 'inherit',
		  	boxShadow: 'none'
		  }),
		  singleValue: (provided, state) => ({
		  	color: 'var(--gray-900)',
		  	whiteSpace: 'nowrap',
		  	width: '96%',
		  	overflow: 'hidden', 
		  	textOverflow: 'ellipsis'
		  }),
		  menu: (provided) => ({
		  	...provided,
		  	height: 300,
		  	boxShadow: 'none',
		  	borderRadius: '0 0 0.125rem 0.125rem',
				borderColor: 'inherit',
				borderWidth: '0 1px 1px 1px',
				margin: 0,
				overflowX: 'hidden',
				overflowY: 'auto'
		  }),
		  menuList: (provided) => ({
		  	padding: 0
		  }),
		  indicatorSeparator: () => ({
		  	display: 'none'
		  }),
		  indicatorsContainer: (provided) => ({
		  	...provided,
		  	color: 'var(--gray-700)'
		  }),
		  option: (provided, state) => ({
				...provided,
				color: 'inherit'
			}),
			placeholder: (provided) => ({
				...provided, 
				color: 'var(--gray-500)'
			}),
			multiValue: (provided, state) => ({
				backgroundColor: state.data.color || 'var(--gray-400)',
				color: 'var(--gray-800)',
				borderRadius: 2,
				display: 'inline-flex',
				margin: '.2em',
				alignItems: 'center',
				paddingLeft: '.3em'
			}),
			multiValueLabel: (provided) => ({
				lineHeight: 1,
				// marginTop: '.1em'
			}),
			multiValueRemove: (provided, state) => ({
				...provided,
				padding: '.15em',
				// marginLeft: '.3em',
				cursor: 'pointer',
				'&:hover': {
		      backgroundColor: 'inherit',
		      color: 'var(--red-500)',
		    }
			}),
		}
		
		
		const customTheme = defaultTheme => ({
			...defaultTheme,
			colors: {
	      ...defaultTheme.colors,
	      primary: 'var(--gray-300)',
	      primary75: 'var(--gray-400)',
	      primary50: 'var(--gray-300)',
	      primary25: 'var(--gray-200)',
	    }
		})
		
		
		// const customTheme = defaultTheme => ({
		// 	...defaultTheme,
		// 	colors: {
  //       ...defaultTheme.colors,
  //       primary: 'var(--gray-500)',
  //       primary75: 'var(--gray-400)',
  //       primary50: 'var(--gray-300)',
  //       primary25: 'var(--gray-200)',
  //     }
		// })

		const { name, label, placeholder = "Auswählen …", options, customerSelect, creatable = false, isMulti = false, isClearable = true } = this.props;
		
		return (
			<Field name={name} id={name} 
				parse={val => {
					return isMulti ?
					 (val ? val.map(x => x.value) : []) :
					 (val ? val.value : "")
				}} 
				format={val => {
					return isMulti ?
					 (val ? val.map(x => ({ value: x, label: x })) : []) :
					 (val && val.value)
				}}>
				{({ input, meta, value }) => {
					let defaultval = options.filter(x => {
						return isMulti ? 
							meta.initial?.indexOf(x.value) !== -1 :
							x.value === meta.initial
					});
					
					const resultLimit = 30
					let i = 0
					
					return defaultval ?
		        <div className={[(meta.touched && (meta.error || meta.submitError)) ? "has-error" : null, 'select'].join(' ')} >
		        	<div className="mt-6 mb-2">
		        		<div className="flex justify-between items-end mt-2 mb-1">
			        		<label htmlFor={name} className="leading-tight my-0">{label}&nbsp;</label>
			        		{(meta.error || meta.submitError) && meta.touched && 
			        			<span className="text-red-500 text-sm leading-tight">{meta.error || meta.submitError}</span>	
			        		}
		        		</div>
			        	
			        	<div className="relative">
			        		{creatable ? 
			        			<Creatable {...input} 
			        				options={options} 
				        			placeholder={placeholder} 
				        			defaultValue={defaultval} 
				        			isFocused={true}
				        			components={{ ClearIndicator, DropdownIndicator, SingleValue, MultiValueRemove }}
				        			isMulti={isMulti}
				        			// menuIsOpen={true}
				        			captureMenuScroll={false}
				        			formatOptionLabel={customerSelect ? formatOptionLabel : undefined}
				        			formatCreateLabel={string => `"${string}" hinzufügen`}
				        			noOptionsMessage={() => "Keine Optionen gefunden"}
				        			searchable 
				        			styles={customStyles}
				        			theme={customTheme}
				        			isClearable={isClearable} /> :
				        		<Select {...input} 
				        			options={options} 
				        			placeholder={placeholder} 
				        			defaultValue={defaultval} 
				        			isFocused={true}
				        			components={{ ClearIndicator, DropdownIndicator, SingleValue, MultiValueRemove }}
				        			isMulti={isMulti}
				        			// menuIsOpen={true}
				        			filterOption={(item, query) => {
				        				// console.log(item.label.toLowerCase(), query, item.label.indexOf(query));
				        				return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0 && i++ < resultLimit
				        			}} 
				        			onInputChange={() => { i = 0 }}
				        			captureMenuScroll={false}
				        			formatOptionLabel={customerSelect ? formatOptionLabel : undefined}
				        			noOptionsMessage={() => "Keine Optionen gefunden"}
				        			searchable 
				        			styles={customStyles}
				        			theme={customTheme}
				        			isClearable={isClearable} />
			        		}
			        	</div>
			        </div>
		        </div>
	      	: null;
	     	}
	   	}
			</Field>
		)
	}
}

export default FormFieldSelect;
