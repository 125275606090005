import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'

import { PageEditor, LoadingIndicator, FormField, RouterPrompt } from '../../components'
import { prepareTemplate, testCommandKey, getCommandKeySymbol } from '../../helpers'
import { loadTemplate } from '../../templates'
import { templateSelectors, templateActions } from '../../redux'
import styles from '../invoiceEditor/styles.module.scss'
import Button, { COLORS, SIZES } from '../../components/button'

class TemplateEditor extends Component {
	state = {
		mounted: false,
		initalized: false,
		data: {},
		template: {
			loading: true
		},
		pagePreviewMode: false,
		previewValues: {}
	}
	submitFormFunction = {};
	form = null;
	
	constructor(props) {
		super(props);
		
		this.onUpdate = this.onUpdate.bind(this);
		this.onSaveForm = this.onSaveForm.bind(this);
		this.loadTemplateFile = this.loadTemplateFile.bind(this);
		this.togglePagePreviewMode = this.togglePagePreviewMode.bind(this);
		this.setPreviewValues = this.setPreviewValues.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
		this.loadTemplateFile();
		
		document.addEventListener("keydown", this.keyboardSubmitForm, false);
	}
	
	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyboardSubmitForm, false);
	}

	componentDidUpdate() {
		this.initForm(this.props);
		this.loadTemplateFile();
	}
	
	loadTemplateFile() {
		const { template, templates } = this.props;
		
		if(!templates.initiated || templates.loading) return false;
		
		if(!this.state.mounted) {
			if(template && template.file) {
				this.setState({ mounted: true });
				loadTemplate(template.file)
					.then(template => {
						this.setState({ 
							template: {
								loading: false,
								...template
								}
						})
					})	
					.catch(console.error)
			} else {
				this.setState({ 
					mounted: true,
					template: {
						loading: false,
					}
				})
			}
		}	
	}
	
	togglePagePreviewMode(val) {
		if(val ? val : !this.state.pagePreviewMode && this.form.getState().dirty) {
			this.submitFormFunction()
				.then(() => this.form.reset(this.state.data))
				.then(() => {
					this.setState({
						pagePreviewMode: true,
					})
				})
		} else {
			this.setState({
				pagePreviewMode: false
			})
		}
	}
		
	initForm({ template }) {
		if(!template.title) return false;
		
		if(!this.state.initalized) {
			this.setState({
				initalized: true,
				data: template
			})
		}
	}
	
	keyboardSubmitForm(e) {
		if(testCommandKey(e) && e.keyCode === 83) {
			e.preventDefault();
			// this.submitFormFunction();
			document.getElementById('templateEditorForm')
				.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}
	
	setPreviewValues(values) {
		this.setState({
			previewValues: values
		})
	}
	
	onSaveForm(values, form) {
		if(form.getState().dirty) {
			const dirtyFields = form.getState().dirtyFields;
			let changedFields = {};
			
			Object.keys(dirtyFields).forEach(x => {
				changedFields[x] = values[x];
			})
			return this.onUpdate(changedFields);
		} else {
			return Promise.resolve(true);
		}
	}
	
	onUpdate(values) {
		let preparedValues = prepareTemplate({...values});
		const { id } = this.props.template;
		
		return new Promise((resolve, reject) => {
			this.props.onUpdateTemplate(id, preparedValues)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	render() {
		const { initalized, template, pagePreviewMode } = this.state;
		const { templates, user, history } = this.props;
		
		template.placeholders = {
			headline: "headline",
			receiver: "receiver",
			sidebar: "sidebar",
			header: "header",
			body: "body",
			conclusion: "conclusion",
			positions: "positions",
			footer1: "footer1",
			footer2: "footer2",
			footer3: "footer3",
		}
		
		return <div className={styles.Editor}>
			{(templates.loading || !initalized) && <LoadingIndicator />}
			
			{(!templates.loading && !template.loading && initalized) && (
				<Form
					onSubmit={this.onSaveForm}
					mutators={{
						setTotal: ([value], state, { changeValue }) => {
						  changeValue(state, "total", () => value)
						}
					}}
					initialValues={this.state.data}
					validate={this.validate}
					render={({submitError, handleSubmit, submitting, pristine, values, form }) => {
						this.submitFormFunction = handleSubmit;
						this.form = form;
						
						return <form
							id="templateEditorForm"
							className={styles.Form} 
							onSubmit={e => {
								handleSubmit(e)
									.then(() => form.reset(this.state.data))
							}}>
							<div className={styles.Sidebar}>
								<div className="p-5">
									<h3 className="mt-0 flex items-center gap-1"><span>Vorlage bearbeiten</span> <span className="text-sm p-1 bg-yellow-400 text-yellow-900 rounded leading-snug align-baseline ml-1">BETA</span></h3>
									
									{/*<div>
										3 tabs: Allgemein, CSS, Platzhalter
									</div>*/}
									
									<div>
										<FormField label="Titel" name="title" type="text" />
									</div>
									
									<div>
										<FormField label="Währung" name="currency" type="text" />
									</div>
									
									<div>
										<FormField label="Fällig nach" secondLabel="(in Tagen)" name="deadline" type="number" />
									</div>
									
									{/*<div>
										<FormField label="Schriftarten" name="fonts" type="code" />
									</div>*/}
									
									<div>
										<FormField label="Eigenes CSS" name="styles" type="code" rows={15} />
									</div>
									
									{/*<div>
										<h3>Platzhalter</h3>
										<div  className="flex flex-wrap gap-1">
											{Object.keys(tags).map(x => Object.keys(tags[x]).map((y,i) => {
												return <Tag key={i} tag={y} brackets={false}/>
											}))}
										</div>
									</div>*/}
									
									<Button 
										text={pagePreviewMode ? "Vorschau verlassen" : "Vorschau mit Beispieldaten"}
										color={COLORS.PRIMARY} 
										size={SIZES.LARGE}
										icon={pagePreviewMode ? "arrowLeft" : "arrowRight"}
										iconPosition={pagePreviewMode ? "left" : "right"}
										action={e => this.togglePagePreviewMode(!pagePreviewMode)}
										className="mt-4 w-full"/>
									
									<Button 
										text={pristine ? "gespeichert" : `Änderungen speichern (${getCommandKeySymbol()}+S)`}
										icon={pristine ? "check" : ""}
										action="submit"
										disabled={submitting || pristine}
										working={submitting}
										color={COLORS.SECONDARY}
										size={SIZES.MEDIUM}
										className="mt-4"/>
								</div>
							</div>
							<div className={[styles.Page, pagePreviewMode ? "bg-gray-400" : "bg-gray-500", ].join(' ')}>
								<span className="absolute top-0 left-0 mt-4 ml-4 p-2 z-10 bg-green-400 text-green-900 rounded">{pagePreviewMode ? "Vorschau" : "Vorlage"}</span>
								<div className={styles.PageScroller}>
									<PageEditor
										key="2"
										currentInvoiceNumber={10}
										user={user} 
										customer={{
											title: "ACME Co.",
											company: "ACME Co.",
											firstname: "Beate",
											lastname: "Beispiel",
											addresslineone: "Beispielstr. 100",
											addresslinetwo: "",
											postcode: "12345",
											city: "Stadt",
											country: "Deutschland",
										}}
										values={values}
										defaultTaxRate={19}
										previewMode={pagePreviewMode}
										setPreviewValues={this.setPreviewValues}
										template={{
											...template,
											currency: values.currency
										}}
										styles={template.styles}
										userStyles={values.styles}
										form={form} />
								</div>
							</div>
							<RouterPrompt 
								when={true}
								navigate={location => history.push(location)}
								shouldBlockNavigation={location => {
									if(!pristine) return true;
									return false;
								}} 
								action={() => this.submitFormFunction()} />
						</form>
					}}
				/>
			)}
		</div>
	}
}
	
	
const mapStateToProps = (state, props) => {
	const { match } = props;
	const tempalteid = parseInt(match.params.id) || 0;
	
	// console.log(templateSelectors.getTemplateById(state, tempalteid))
	
	return {
		user: state.auth.user,
		templates: {
			...state.templates,
			list: templateSelectors.getTemplatesList(state),
		},
		template: templateSelectors.getTemplateById(state, tempalteid)
	}
}
	
const mapDispatchToProps = {
	onUpdateTemplate: templateActions.updateTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
