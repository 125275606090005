import React, { Component } from 'react'
import { Page, FormField, Alert, Icon } from '../../components'
import { api } from '../../helpers'
import Button, { COLORS, SIZES } from '../../components/button'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import createDecorator from 'final-form-focus'
import { FORM_ERROR } from 'final-form'
import * as EmailValidator from 'email-validator'
import { Helmet } from 'react-helmet'

const focusOnError = createDecorator()

class GetResetPasswordLink extends Component {
	state = {
		sentMailTo: null
	}
	
	constructor(props) {
		super(props);
		
		this.sendPasswordResetMail = this.sendPasswordResetMail.bind(this);
	}
	
	validate({ email }) {
  	const errors = {};
  	if(!EmailValidator.validate(email)) errors.email = "Die E-Mail ist ungültig"
  	if(!email) errors.email = "Gib deine E-Mail ein"
  	return errors;
  }
  
  sendPasswordResetMail({ email }) {
  	return api('/auth/password/reset', 'post', { email })
			.then(res => res.json())
			.then(json => {
				if(json.error && json.error.statusCode === 404) {
					return { [FORM_ERROR]: "Dieser Nutzer existiert nicht. Bist du sicher, dass du schon ein Konto hast?" }
				} else if(json.error) {
					return { [FORM_ERROR]: `Etwas ist schief gelaufen (ERROR ${json.error.statusCode})` }
				} else {
					this.setState({ sentMailTo: json.email })
					return null;
				}
			})
			.catch(console.log)
  }
	
	render() {
		const { sentMailTo } = this.state;
		
		return (
			<Page>
				<Helmet>
					<title>Passwort zurücksetzen | Billu</title>
				</Helmet>
				<div className="max-w-md mx-auto">
					<Icon icon="logo-icon" className="mx-auto table mb-6" svgClassName="w-16 h-16" stroke={false}/>
					<div className="bg-white p-10 rounded">
						<h2 className="mt-0">Passwort zurücksetzen</h2>
						<Form
				      onSubmit={this.sendPasswordResetMail}
				      decorators={[focusOnError]}
				      validate={this.validate}
				      render={({ submitError, submitSucceeded, handleSubmit, form, submitting, pristine, values }) => (
								<form onSubmit={handleSubmit}>
										
									{!submitSucceeded && 
										<p className="mt-4 mb-8 text-gray-700">Trage deine E-Mail-Adresse ein, damit wir dir einen Link zum zurücksetzen deines Passworts zuschicken können.</p>}
									
									{submitSucceeded && 
										<Alert className="mt-5 bg-green-200 text-gray-900">Eine E-Mail mit dem Link zum zurücksetzen deines Passworts wurde an {sentMailTo} versandt.</Alert>}
										
									{(!submitting && submitError) && 
										<Alert className="mt-5 bg-red-200 text-gray-900">{submitError}</Alert>}
									
									{!submitSucceeded && <div>
										<FormField 
											name="email" 
											label="Deine E-Mail-Adresse" 
											placeholder="deine@email.de"/>
										
										<Button 
											text={"Link anfordern"} 
											disabled={submitting || submitSucceeded} 
											action='submit' 
											color={COLORS.PRIMARY} 
											working={submitting}
											size={SIZES.LARGE} 
											className={["block", "mt-6"]} />
											
									</div>}
								</form>
							)} 
						/>
					</div>
					<p className="mt-6 text-gray-600 mx-auto table">Du hast noch kein Konto? <Link to="/signup" className="text-theme-500 hover:no-underline hover:text-black">Konto erstellen</Link></p>
				</div>
			</Page>
		)
	}
}


export default GetResetPasswordLink;
