import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import styles from './styles.module.scss'

class Modal extends Component {
	
	Title() {
		return (
			<div>titel</div>	
		)
	}
	
	render() {
		const { children, closeOnDocumentClick = false, closeOnEscape = false, className, width = 'sm', onClose = () => {} } = this.props;
		
		const popupStyles = {
			padding: 0,
			border: 'none',
			boxShadow: '0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%)',
			backgroundColor: 'transparent',
			width: 'auto'
		}
		
		const overlayStyle = {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
			backdropFilter: 'blur(4px)',
		}
		
		return (
			<Popup 
				open={true}
				closeOnDocumentClick={closeOnDocumentClick}
				closeOnEscape={closeOnEscape}
				contentStyle={popupStyles}
				overlayStyle={overlayStyle}
				onClose={onClose}
				modal>
				<div className={["bg-white p-5 rounded-lg shadow", className, styles[width]].join(' ')}>
					{children}
				</div>
			</Popup>
		)
	}
}

export default Modal;
