import React from 'react'
import Select, { components } from 'react-select'
import { Icon } from '../'
import styles from './styles.module.scss'


// const ClearIndicator = props => {
//   const {
//     innerProps: { ref, ...restInnerProps },
//   } = props;
//   return null;
//   // return (
//   //   <div
//   //     {...restInnerProps}
//   //     ref={ref} >
//   //     <div className="p-1">
//   //     	<Icon icon="cancel" />
//   //     </div>
//   //   </div>
//   // );
// };
const IndicatorSeparator = () => null;
const ClearIndicator = () => null;

const MultiValueRemove = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref} >
      <div>
      	<Icon icon="cancel" />
      </div>
    </div>
  );
};

const DropdownIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref} >
      <div className="p-1 px-2 mr-1 text-xs">
      	<Icon icon="chevronDown" />
      </div>
    </div>
  );
};

const Input = props => <components.Input {...props} className={styles.Input}/>;
const NoOptionsMessage = props => <span {...props} style={{ padding: "8px 12px" }} className="block text-gray-600">Keine weiteren Optionen</span>


const SmallSelect = props => {
	const customStyles = {
		valueContainer: (provided, state) => ({
			...provided,
			padding: '.3em',
			alignItems: 'flex-start'
		}),
		control: (provided, state) => ({
			...provided,
			border: 'none',
			minHeight: 0,
			borderRadius: state.menuIsOpen ? '0.25rem 0.25rem 0 0' : '0.25rem',
			backgroundColor: state.isFocused || state.menuIsOpen ? 'var(--gray-300)' : 'var(--gray-200)',
			outline: 'none',
			boxShadow: 'none',
			zIndex: 2
		}),
		multiValue: (provided, state) => ({
			backgroundColor: state.data.color || 'var(--gray-400)',
			color: 'var(--gray-800)',
			borderRadius: 2,
			display: 'inline-flex',
			margin: '.2em',
			alignItems: 'center',
			paddingLeft: '.3em'
		}),
		multiValueLabel: (provided) => ({
			lineHeight: 1,
			// marginTop: '.1em'
		}),
		multiValueRemove: (provided, state) => ({
			...provided,
			padding: '.15em',
			// marginLeft: '.3em',
			cursor: 'pointer',
			'&:hover': {
	      backgroundColor: 'inherit',
	      color: 'var(--red-500)',
	    }
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: state.isFocused ? 'var(--gray-600)' : 'inherit',
			marginLeft: '2px'
		}),
		input: (provided) => ({
			...provided,
			display: 'table',
			// padding: '.2em',
			// margin: '.2em',
			boxShadow: 'none !important'
		}),
		clearIndicator: provided => ({
			display: 'none'
		}),
		menu: (provided) => ({
	  	...provided,
	  	borderRadius: '0 0 .25rem .25rem',
	  	zIndex: 1,
	  	boxShadow: '0 5px 10px 3px rgba(0, 0, 0, 0.05), 0 0px 15px 0px rgba(0, 0, 0, 0.1)',
			border: 'none',
			margin: 0
	  }),
		menuList: provided => ({
			...provided,
			padding: 0, 
			border: 'none'
		}),
		option: (provided, state) => ({
			...provided,
			color: state.isDisabled ? 'var(--gray-600)' : 'inherit',
			cursor: state.isDisabled ? 'not-allowed' : 'default',
		})
	};
	
	const customTheme = defaultTheme => ({
		...defaultTheme,
		colors: {
      ...defaultTheme.colors,
      primary: 'var(--gray-300)',
      primary75: 'var(--gray-400)',
      primary50: 'var(--gray-300)',
      primary25: 'var(--gray-200)',
    }
	})
	
	
	
	return <div className={props.className}>
		<Select
	    isMulti={props.isMulti}
	    name={props.year}
	    onChange={props.onChange}
	    placeholder={props.placeholder}
	    options={props.options}
	    defaultValue={props.defaultValue}
	    value={props.value}
	    autoFocus={false}
	    blurInputOnSelect={true}
	    styles={customStyles}
	    // menuIsOpen={true}
	    theme={customTheme}
	    components={{ ClearIndicator, DropdownIndicator, IndicatorSeparator, MultiValueRemove, Input, NoOptionsMessage }}
	    className={["basic-multi-select", styles.SmallSelect].join(' ')}
	    classNamePrefix="select"
	  />
	</div>
}

export default SmallSelect;
