import { store } from '../redux'

const permissions = {
	invoices: {},
	templates: {
		edit_template: "templates:edit_template",
		add_max_1: "templates:add_max_1",
		add_max_3: "templates:add_max_3",
		add_max_10: "templates:add_max_10",
		add_unlimited: "templates:add_unlimited"
	},
	files: {
		set_sharing_duration: "files:set_sharing_duration",
		download_multiple: "files:download_multiple"
	},
	customers: {
		add_max_10: "customers:add_max_10",
		add_max_20: "customers:add_max_20",
		add_unlimited: "customers:add_unlimited",
	},
	settings: {
		edit_invoicenumber: "settings:edit_invoicenumber",
	},
	users: {}
}

export const tiers = {
	free: [
		permissions.customers.add_max_10,
		permissions.templates.add_max_1,
	],
	solo: [
		permissions.customers.add_unlimited,
		permissions.settings.edit_invoicenumber,
		permissions.files.set_sharing_duration,
		permissions.files.download_multiple,
		permissions.templates.edit_template,
		permissions.templates.add_max_1,
	],
	team: [
		permissions.customers.add_unlimited,
		permissions.settings.edit_invoicenumber,
		permissions.files.set_sharing_duration,
		permissions.files.download_multiple,
	],
	beta: [
		permissions.customers.add_max_20,
		permissions.settings.edit_invoicenumber,
		permissions.files.set_sharing_duration,
		permissions.templates.add_max_1,
		permissions.files.download_multiple,
	]
}

// später: check für mehrere permissions auf einmal 
export const hasPermissions = _permissions => {
	const neededPermissions = typeof _permissions === 'object' ? _permissions : [_permissions];
	
	const state = store.getState();
	
	const userTier = state.auth.user.tier || "beta";
	const userPermissions = tiers[userTier];
	
	return neededPermissions.every(x => userPermissions.includes(x));
}
