import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import { Icon } from '../../components'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

const colors = {
	green: "hover:text-theme-500 hover:bg-gray-100",
	red: "hover:text-red-600 hover:bg-red-100 "
}

const overrideStyles = {
	padding: 0,
	border: 'none',
	backgroundColor: 'transparent',
	width: 'auto',
	boxShadow: 'none',
	color: 'var(--gray-800)'
}


const ContextMenuButton = React.forwardRef(({open, ...props}, ref) => (
	<span {...props} ref={ref}
		className="flex items-center justify-around text-lg cursor-pointer rounded-sm w-8 h-8 text-gray-600 hover:bg-gray-300 hover:text-gray-800">
		<Icon icon="meatballs"/>
	</span>
));


const menuItemStyles = "flex items-center px-3 py-2 h-10 box-border leading-relaxed rounded-sm hover:no-underline cursor-pointer";

const ContextMenuItem = props => {
	const { label, icon, onClick = () => {}, link, close, color = 'green', target, items, element, hide = false } = props;
	
	return (
		hide ? null :
			items ? 
				<ContextMenuSubmenu {...props} /> :
				element ? 
					<div onClick={() => {onClick(); close()}} className={[menuItemStyles, colors[color]].join(' ')}>
						{element}
					</div> :
					link ?
						target ?
							<a href={link} rel="noopener noreferrer" target={target} onClick={close} className={[menuItemStyles, colors[color]].join(' ')}>
								{icon && <Icon icon={icon} className="mr-2"/>}
								<span className="leading-tight self-center">{label}</span>
							</a> :
							<Link to={link} onClick={close} className={[menuItemStyles, colors[color]].join(' ')}>
								{icon && <Icon icon={icon} className="mr-2"/>}
								<span className="leading-tight self-center">{label}</span>
							</Link>
						:
						<div onClick={() => {onClick(); close()}} className={[menuItemStyles, colors[color]].join(' ')}>
							{icon && <Icon icon={icon} className="mr-2"/>}
							<span className="leading-tight self-center">{label}</span>
						</div>
	)	
}

const ContextMenuSubmenu = props => {
	const { color = 'green', label, icon = 'chevronLeftSmall', arrow = false, position = 'left bottom', items, children, on = 'hover', close:parentClose } = props;
	
	return (
		<Popup
			trigger={<div className={[menuItemStyles, colors[color]].join(' ')}>
				{icon && <Icon icon={icon} className="mr-2"/>}
				<span className="leading-tight self-center">{label}</span>
			</div>}
			arrow={arrow} 
			contentStyle={overrideStyles}
			position={position}
			on={on}>
			{close => (
				<div className={["bg-white rounded select-none", styles.ContextMenu].join(' ')}>
					{children}
					{items.map((item, i) => <ContextMenuItem key={i} {...item} close={parentClose} />)}
				</div>
			)}
		</Popup>
	)
}


class ContextMenu extends Component {
	render() {
		const { items, trigger = <ContextMenuButton />, arrow = false, position = 'bottom right', children } = this.props;

		return (
			<Popup 
				trigger={trigger}
				arrow={arrow} 
				contentStyle={overrideStyles}
				position={position}
				nested={true} >
				{close => (
					<div className={["bg-white rounded select-none", styles.ContextMenu].join(' ')}>
						{children}
						{items.map((item, i) => <ContextMenuItem key={i} {...item} close={close} />)}
					</div>
				)}
			</Popup>
		)
	}
}

export default ContextMenu;
