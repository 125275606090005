import React, { Component } from 'react'
import styles from './styles.module.scss'

import { isJSON, numberToEuro, euroToNumber, percentToNumber, numberToPercent } from '../../helpers'
import { Icon } from '../../components'
import { PositionField } from './PositionField'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'


const DragHandle = SortableHandle(() => (
	<span className={[styles.DragButton, "button text-gray-900 hover:bg-gray-300 rounded h-6 w-6 flex items-center justify-center transition-colors duration-200"].join(' ')}>
		<Icon icon="drag-handle" />
	</span>
))

	
	
class PositionsList extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			initalized: false,
			positions: [],
			calc: {
				netto: null,
				brutto: null,
				taxes: []
			}
		}
		
		this.onUpdate = this.onUpdate.bind(this);
		this.addPosition = this.addPosition.bind(this);
		this.removePosition = this.removePosition.bind(this);
		this.changePositions = this.changePositions.bind(this);
		this.removeAllPositions = this.removeAllPositions.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
		
		this.state.templateStyles = this.props.styles;
		this.state.defaultTaxRate = this.props.novat ? 0 : this.props.defaultTaxRate;
		this.state.strings = this.props.strings;
		this.state.novat = this.props.novat;
		this.state.templateFields = this.props.fields;
		
		const defaultFields = [
			{
				priority: 10,
				name: 'content',
				type: 'textarea',
				className: [styles.content, this.state.templateStyles.PositionsText, "PositionsText"],
				placeholder: 'Beschreibung der Leistung',
				rows: 1,
				label: this.state.strings.positionsText,
				defaultValue: ""
			},
			{
				priority: 20,
				name: 'taxrate',
				type: 'number',
				className: [styles.taxrate, "PositionsTaxrate"],
				placeholder: `${this.state.defaultTaxRate} %`,
				format: x => numberToPercent(x || this.state.defaultTaxRate),
				show: !this.state.novat,
				label: this.state.strings.positionsTax,
				defaultValue: this.state.defaultTaxRate
			},
			{
				priority: 30,
				name: 'price',
				type: 'number',
				className: [styles.price, this.state.templateStyles.PositionsPrice, "PositionsPrice"],
				format: x => {
					let val = x ? x.toString() : "";
					try {
						val = val.replace(/,/g, '.').replace(/[^-()\d/*+.]/g, '');
						
						val = window.eval(val); // eslint-disable-line no-eval
					} catch(err) {
						console.error(err);
					}
					
					return numberToEuro(euroToNumber(val || ""), this.props.currency);
				},
				label: this.state.strings.positionsPrice,
				defaultValue: 0
			}
		];
		
	
		let fields = new Map();

	  [ ...defaultFields, ...this.state.templateFields ].forEach((item) => {
	    const priority = item.priority;
	    fields.has(priority) ?
	    	fields.set(priority, { ...fields.get(priority), ...item }) :
	    	fields.set(priority, item);
	  });
	  
	  
  	this.state.fields = Array.from(fields.values())
  		.sort((x, y) => x.priority - y.priority);
	}
	
	calcTotal(positions) {
		let taxes = positions.reduce((acc, position) => {
			let tr = position.taxrate;
			acc[tr] = acc[tr] || [];
			acc[tr].push(position);
			return acc;
		}, [])
		.map((x, y) => ({
			taxrate: y,
			price: x.reduce((i, n) => i + ((euroToNumber(n.price) * euroToNumber(y) / 100 )|| 0), 0)
		}))
		.sort((x, y) => x.taxrate - y.taxrate)
		
		let netto = positions.reduce((x, y) => x + (euroToNumber(y.price) || 0), 0),
			brutto = taxes.reduce((x, y) => x + y.price || 0, 0) + netto;
		
		return {
			netto,
			taxes,
			brutto
		}
	}
	
	onUpdate(positions) {
		const { novat, currency } = this.props;
		const calc = this.calcTotal(positions);
		
		this.setState({
			positions,
			currency,
			calc
		});
		
		this.props.onUpdate({ positions, total: novat ? calc.netto : calc.brutto });
	}
	
	componentDidUpdate() {
		if(!this.state.initialized) {
			const positions = this.props.positions && isJSON(this.props.positions) ?
				isJSON(this.props.positions) :
				[{
					id: Math.random().toString(36).substr(2, 9),
					content: "",
					price: 0,
					taxrate: this.state.defaultTaxRate
				}];
			
			this.setState({
				initialized: true,
				positions,
				calc: this.calcTotal(positions)
			})
		}
	}
	
	addPosition() {
		const defaultValues = this.state.fields.reduce((arr, x) => {
			if(x.defaultValue) {
				arr[x.name] = x.defaultValue;
			}
			return arr;
		}, {})
		
		let updatePositions = [
			...this.state.positions,
			{
				id: Math.random().toString(36).substr(2, 9),
				// content: "",
				// price: 0,
				// taxrate: this.state.defaultTaxRate,
				...defaultValues
			}
		]
		this.onUpdate(updatePositions);
	}
	
	removePosition(id) {
		let updatePositions = [
			...this.state.positions.filter(x => x.id !== id)
		];
		
		this.onUpdate(updatePositions);
	}
	
	changePositions(e, row) {
		let val = e.target.value.normalize("NFKC");
		
		if(e.target.name === 'content') e.target.innerHTML = val;		
		// if(e.target.name === 'price' || e.target.name === 'taxrate') val = percentToNumber(val)
		
		if(e.target.getAttribute('data-type') === 'number') val = percentToNumber(val)
	
		let updatedRow = { ...row, [e.target.name]: val};
		
		this.state.fields.forEach(x => {
			if(x.calc) {
				updatedRow[x.name] = x.calc(updatedRow)
			}
		})
			
		let updatePositions = [
			...this.state.positions.map(x => {
				if(x.id !== e.target.getAttribute('data-id')) {
					return x;
				} else {
					return updatedRow;
				}
			})
		];
		
		this.onUpdate(updatePositions);
	}
	
	removeAllPositions() {
		let updatePositions = [];
		this.onUpdate(updatePositions);
	}


	SortableItem = SortableElement(({ value }) => {
		const { defaultTaxRate, templateStyles, fields } = this.state;
		const { currency } = this.props;
		
		return (
			<li key={value.id} className={[styles.Position, templateStyles.PositionsPosition, "position PositionsPosition"].join(' ')} id={value.id}>
				<div>
					{fields.map((field, i) => {
						return <PositionField 
							{...field} 
							row={value} 
							changePositions={this.changePositions}
							taxrate={defaultTaxRate}
							currency={currency}
							key={i} />
					})}
					
					<DragHandle />
					<span onClick={() => this.removePosition(value.id)} className={[styles.RemoveButton, "button text-gray-900 hover:bg-red-300 rounded h-6 w-6 flex items-center justify-center cursor-pointer transition-colors duration-200"].join(' ')}>
						<Icon icon="cancel" />
					</span>
				</div>
			</li>
		)
	})

	SortableList = SortableContainer(({ children }) => {
		return <ul>
			{children}
		</ul>
	})
	
	onSortEnd({ oldIndex, newIndex }) {
		const updatePositions = arrayMove(this.state.positions, oldIndex, newIndex)
		this.onUpdate(updatePositions)
		document.body.style.cursor = "";
	}
	
	onSortStart() {
		document.body.style.cursor = "grabbing"
		// debugger;
	}
	
	render() {
		const { templateStyles, strings, novat = false, fields, positions } = this.state;
	
		const helperContainer = document.querySelector('.PositionsList');
		// const PositionField = this.PositionField;
  	
		return (
			<div className={[styles.List, templateStyles.PositionsList, novat ? styles.novat : '', 'PositionsList'].join(' ')}>
				<div className={[styles.label, templateStyles.PositionsLabel, 'PositionsLabel'].join(' ')}>
					{fields.map((x, i) => {
						return x.show === false ? null : <span key={i}>{x.label}</span>;
					})}
				</div>
				
				<this.SortableList 
					onSortEnd={this.onSortEnd} 
					onSortStart={this.onSortStart}
					useDragHandle={true} 
					lockAxis="y"
					transitionDuration={200}
					// helperClass={styles.SortableHelper}
					helperContainer={helperContainer}
					hideSortableGhost={true}>
					{positions && (
						positions.map((x, i) => (
							<this.SortableItem key={x.id} index={i} value={x} />
						))
					)}
				</this.SortableList>
				
				<div className={styles.Actions}>
					<span onClick={this.addPosition} className="button bg-green-500 text-white hover:bg-green-400 rounded-full h-6 w-6 flex items-center justify-center cursor-pointer transition-colors duration-200">
						<Icon icon="add" />
					</span>
					{/*<Button text="Alle Zeilen löschen" action={this.removeAllPositions} type={TYPES.GHOST} size={SIZES.MEDIUM} className={["block"]}/>*/}
				</div>
				
				<div className={[styles.total, templateStyles.PositionsTotal, "total PositionsTotal"].join(' ')}>
					{!novat && <React.Fragment>
							<span>{strings.positionsSubtotal}</span>
							<span>{numberToEuro(this.state.calc.netto, this.props.currency)}</span>
						</React.Fragment>
					}
					{!novat && this.state.calc.taxes.map(x => {						
						if(!x.price) return null;
						return [
							<span key={`taxrate${x.taxrate}`}>zzgl. {x.taxrate} % {strings.positionsTax}</span>,
							<span key={`price${x.price}`}>{numberToEuro(x.price, this.props.currency)}</span>
						]
					})}
					
					<span className={[styles.brutto, templateStyles.PositionsBrutto, "PositionsBrutto"].join(' ')}>
						{strings.positionsTotal}
					</span>
					<span className={[styles.brutto, templateStyles.PositionsBrutto, "PositionsBrutto"].join(' ')}>
						{numberToEuro(!novat ? this.state.calc.brutto : this.state.calc.netto, this.props.currency)}
					</span>
				</div>
			</div>	
		)
	}
}

export default PositionsList;
