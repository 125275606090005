import React from 'react';
import { useLocation } from 'react-router-dom';
import Button, { SIZES, TYPES } from '../../components/button'

const Tabs = ({ tabs }) => {	
	const location = useLocation();
	
	return (
		<nav className="flex gap-3 my-4">
			{tabs.map((x, i) => (
				<Button
					key={i}
					text={x.name}
					icon={x.icon}
					link={x.link}
					type={TYPES.GHOST} 
					size={SIZES.SMALL}
					className={["mt-0", location.pathname === x.link ? "bg-gray-300 active" : ""].join(' ')}
					/>
			))}
		</nav>
	);
}

export default Tabs;
