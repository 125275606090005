import React, { Component } from 'react'
import { connect } from 'react-redux'
import HttpsRedirect from 'react-https-redirect'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { createBrowserHistory } from 'history'
import { Toaster } from 'react-hot-toast'
import { Icon } from './components'


//import { TransitionGroup, CSSTransition } from "react-transition-group"

import { authActions, invoiceActions, fileActions, customerActions, notificationActions, templateActions, invoiceSelectors, fileSelectors, customerSelectors } from './redux'

//import './styles/styles.module.scss';
import styles from './styles/styles.module.scss'

import { Navigation, ProtectedRoute, CookieNotice, HelmetHeader, CsvImport } from './components'
import { InvoiceCreator, CustomerCreator, TemplateCreator } from './containers'
import { Customers, CustomerEditor, TemplateEditor, Dashboard, Login, Signup, ResetPassword, GetResetPasswordLink, Invoices, InvoiceEditor, Files, NotFound, Onboarding, Support, Tags, Colors, PrivacyPolicy, Settings, Statements } from './pages'
import ReactGA from 'react-ga'

import { ReactComponent as IconSprite } from './components/icon/sprite.svg'


const history = createBrowserHistory();
if(process.env.NODE_ENV === 'production') {
	history.listen(location => {
		// ReactGA.set({ page: location.pathname }); // Update the user's current page
		ReactGA.pageview(location.pathname + location.search); // Record a pageview for the given page
	});
}

		
class App extends Component {
	state = {
		loggedId: false
	}
	
	componentDidUpdate() {  
		const { auth  } = this.props;
		
		if(!this.state.loggedIn) {
			if(auth.loggedIn) {
				this.props.onLoadInvoices();
				this.props.onLoadFiles();
				this.props.onLoadCustomers();
				this.props.onLoadTemplates();
				
				if(process.env.NODE_ENV === 'production') {	
					ReactGA.set({ userId: auth.user.id });
				}
				
				this.setState({
					loggedIn: true
				})
			}
		}
		if(!auth.loggedIn && this.state.loggedIn) {
			this.setState({
				loggedIn: false
			})
		}
	}
	
	componentDidMount() {		
		this.props.onValidateLogin();
		
		if(process.env.NODE_ENV === 'production') {	
			ReactGA.initialize(process.env.GA_TRACKING_ID || 'UA-167616574-1');
			ReactGA.pageview(history.location.pathname + history.location.search)
		}
	}

	
	render() {
		const { auth, invoices, files, customers, notifications, onLogout, onSetNotification } = this.props;
		
		return (
			<HttpsRedirect>
				<Router history={history}>
					<Route render={({ location }) => (
						<React.Fragment>
							<HelmetHeader location={location} />
							
							{auth.loggedIn && !auth.user.onboardingconfirmed && !location.pathname.match(/(setup)/) && <Redirect to="/setup" />}
							
							<div className={styles.App}>
								<span className={[styles.Sprite, "absolute z-0 hidden"].join(' ')}>
									<IconSprite />
								</span>
								
								{(auth.loggedIn && location.pathname.indexOf('setup') === -1) && <Navigation logout={onLogout} auth={auth} invoices={invoices} files={files} customers={customers} />}
								
								{document.body.classList.toggle("bg-gray-300", location.pathname.match(/(signup|login|password|setup)/))}
								
								<Switch location={location}>
									<ProtectedRoute exact path="/" component={Dashboard} auth={auth} />
									
									<ProtectedRoute exact path="/invoices/edit/:id" component={InvoiceEditor} auth={auth} />
									<ProtectedRoute exact path="/invoices/create" component={InvoiceCreator} auth={auth} />
									<ProtectedRoute exact path="/invoices/trash" component={Invoices} auth={auth} />
									<ProtectedRoute path="/invoices" component={Invoices} auth={auth} />
									
									<ProtectedRoute path="/files" component={Files} auth={auth} />
									
									<ProtectedRoute path="/settings" component={Settings} auth={auth} />
									
									<ProtectedRoute exact path="/customers" component={Customers} auth={auth} />
									<ProtectedRoute exact path="/customers/create" component={CustomerCreator} auth={auth} />
									<ProtectedRoute exact path="/customers/:id" component={CustomerEditor} auth={auth} />
									
									{/*<ProtectedRoute exact path="/templates" component={Templates} auth={auth} />*/}
									<ProtectedRoute exact path="/templates/create" component={TemplateCreator} auth={auth} />
									<ProtectedRoute exact path="/templates/:id" component={TemplateEditor} auth={auth} />
									
									<ProtectedRoute exact path="/csv" component={CsvImport} auth={auth} />
									<ProtectedRoute exact path="/statements" component={Statements} auth={auth} />
									
									<Route exact path="/login/:status?" component={Login} />
									<Route exact path="/signup" component={Signup} />
									<Route exact path="/reset-password" component={GetResetPasswordLink} />
									<Route exact path="/reset-password/:id" component={ResetPassword} />
									
									<ProtectedRoute path="/setup/:page?" component={Onboarding} auth={auth} />
									
									<Route path="/support" component={Support} />
									<Route path="/styles" component={Colors} />
									<Route path="/tags" component={Tags} />
									<Route path="/privacy-policy" component={PrivacyPolicy} />
									
									<Route component={NotFound} />
								</Switch>
							</div>
						</React.Fragment>
					)} />
				</Router>
				<Toaster
					position="bottom-left"
					toastOptions={{
						duration: 5000,
						className: "rounded p-3 text-gray-900",
						success: {
							icon: <div className="p-1 flex bg-green-200 text-green-700 rounded-full"><Icon icon="check" svgClassName="w-4 h-4"/></div>
						},
						error: {
							icon: <div className="p-1 flex bg-red-200 text-red-700 rounded-full"><Icon icon="cancel" svgClassName="w-4 h-4"/></div>
						},
					}}
				/>
				{!notifications.cookiesAccepted ? <CookieNotice id={1} accept={onSetNotification} /> : null}
			</HttpsRedirect>
		);
	}
}

const mapStateToProps = state => {
	return {
		auth: state.auth,
		notifications: state.notifications,
		invoices: invoiceSelectors.getInvoicesList(state, { year: [], month: [], status: [], trash: [false] }, {}),
		files: fileSelectors.getFilesList(state, { year: [], month: [], status: [], trash: [false] }, {}),
		customers: customerSelectors.getCustomersList(state)
	}
}
	
	
const mapDispatchToProps = {
	onValidateLogin: authActions.validateToken,
	onLogout: authActions.logout,
	onLoadInvoices: invoiceActions.loadInvoices,
	onLoadFiles: fileActions.loadFiles,
	onLoadTemplates: templateActions.loadTemplates,
	onLoadCustomers: customerActions.loadCustomers,
	onSetNotification: notificationActions.setNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
