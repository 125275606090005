import React, { Component } from 'react';
import { Field } from 'react-final-form';
import Select, { components } from 'react-select'
import { Status, Icon } from '../'
import { germanDateToDate } from '../../helpers'

function Option(props) {
	const { innerProps, innerRef, isSelected } = props;
	
	return (
		<div className={["p-3", isSelected ? 'bg-gray-200' : '', 'hover:bg-gray-100'].join(' ')} ref={innerRef} {...innerProps}>
			<Status status={props.data.value} invoicedate={germanDateToDate(props.data.invoicedate)} deadline={props.data.deadline} />
			{/*<Status status={props.data.value} />*/}
		</div>
	)
}

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;

  const value = hasValue ? getValue()[0] : null;
  
  return (
    <components.ValueContainer {...props}>
    	{children}
      {hasValue && <Status status={value.value} invoicedate={germanDateToDate(value.invoicedate)} deadline={value.deadline} />}
    </components.ValueContainer>
  );
};


const DropdownIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref} >
      <div className="p-1 px-2 mr-1 text-xs">
      	<Icon icon="chevronDown" />
      </div>
    </div>
  );
};



class FormFieldStatus extends Component {	
	render() {
		
		const customStyles = {
		  control: (provided, state) => ({
				...provided,
				borderColor: 'var(--gray-300)',
				borderWidth: 1,
				backgroundColor: 'white',
				minHeight: 42.5,
				borderRadius: state.menuIsOpen ? '0.125rem 0.125rem 0 0' : '0.125rem',
		  	boxShadow: 'none',
		  	':hover': {
		  		borderColor: 'var(--gray-300)'
		  	}
		  }),
		  menu: (provided) => ({
		  	...provided,
		  	borderRadius: '0 0 0.125rem 0.125rem',
				borderColor: 'var(--gray-300)',
				borderWidth: '0 1px 1px 1px',
				backgroundColor: 'white',
		  	boxShadow: 'none',
				margin: 0
		  }),
		  menuList: (provided) => ({
		  	padding: 0
		  }),
		  indicatorsContainer: (provided) => ({
		  	color: 'var(--gray-700)',
		  }),
		  indicatorSeparator: () => ({
		  	display: 'none'
		  })
		}
		
		const customTheme = defaultTheme => ({
			...defaultTheme,
			colors: {
        ...defaultTheme.colors,
        primary: 'var(--gray-500)',
        primary75: 'var(--gray-400)',
        primary50: 'var(--gray-300)',
        primary25: 'var(--gray-200)',
      }
		})

		const { name, label, invoicedate, deadline } = this.props;
		
		const options = [
			// {
			// 	value: 1
			// },
			{
				value: 2,
				invoicedate,
				deadline
			},
			{
				value: 3
			},
			{
				value: 4
			}
		];
				
		return (
			<Field name={name} id={name} parse={val => val && val.value} format={val => val && val.value}>
				{({ input, meta, value }) => {
					return (
		        <div className={[(meta.touched && (meta.error || meta.submitError)) ? "has-error" : null, 'select'].join(' ')} >
		        	<div className="mt-6 mb-2">
		        		<div className="flex justify-between items-end mt-2 mb-1">
			        		<label htmlFor={name} className="leading-tight my-0">{label}&nbsp;</label>
			        		{(meta.error || meta.submitError) && meta.touched && 
			        			<span className="text-red-500 text-sm leading-tight">{meta.error || meta.submitError}</span>	
			        		}
		        		</div>
		        		
			        	<div className="relative">
			        		<Select {...input}
			        			options={options}
			        			components={{ Option, ValueContainer, DropdownIndicator }}
			        			defaultValue={{
			        				value: meta.initial,
			        				invoicedate,
			        				deadline
										}}
			        			isSearchable={false}
			        			// menuIsOpen={true}
			        			styles={customStyles}
			        			//hideSelectedOptions={true}
			        			theme={customTheme} />
			        	</div>
			        </div>
		        </div>
	      	)
	     	}
	   	}
			</Field>
		)
	}
}

export default FormFieldStatus;
