import React, { Component } from 'react'
import { dueDate, formatAddressShort, tags, germanDate, path, getCommandKeySymbol } from '../../helpers/'
import { FormField, FormFieldSelect, FormFieldStatus, ContextMenu, Icon, Alert, Tag, FileList } from '../../components'
import Button, { COLORS, SIZES } from '../../components/button'


class InvoiceEditorSidebar extends Component {
	render() {
		const { invoice, invoices, customers, files, user, values, currentInvoiceNumber, styles, pagePreviewMode, submitting, pristine, form, onCreatePdf, togglePagePreviewMode, exportData, onUpdateInvoice, changeFilename, previewValues } = this.props;
		
		const currentInvoiceDate = values.invoicedate ? values.invoicedate : germanDate(new Date());
		
		let due = dueDate(currentInvoiceDate, values.deadline, true)
		
		const { exported } = invoice;
		
		const manualInvoiceNumberMode = (!!values.invoicedate || !!values.invoicenumber);
		
		const missingTags = Object.values(previewValues).reduce((x, y) => {
			x.push(...y.missing);
			return x;
		}, []);
		
		return (
			<div className={[styles.Sidebar, "bg-gray-100 border-r p-5"].join(' ')}>
				<div className="flex justify-between items-start">
					{(pagePreviewMode && !exported) ?
						<h3 className="mt-0">Rechnung abschließen</h3> :
						<h3 className="mt-0">Einstellungen</h3>
					}
						
					<ContextMenu items={[
						{
							label: 'In den Papierkorb',
							icon: 'delete',
							color: 'red',
							onClick: () => onUpdateInvoice(invoice.id, { intrash: true })
						},
						{
							label: 'Kopieren',
							icon: 'copy',
							onClick: () => {
								form.submit(values).then(() => {
									this.props.history.push(path('invoices', 'create', `?action=duplicate&id=${invoice.id}`))
								});
							}
						}/*,
						{
							label: 'Stornieren',
							icon: 'x-circle',
							onClick: () => {}
						}*/
					]} />
				</div>
				
				{exported && 
					<Alert>
						<Icon icon="lock" className="self-center mr-2 ml-1"/>
						<span>Diese Rechnung wurde am {germanDate(new Date(exported))} abgeschlossen</span>
					</Alert>
				}
						
				
				{!pagePreviewMode && <React.Fragment>
					{/*<label htmlFor="filename" className="flex items-center">
						<span>Title</span>
						<Icon icon="auto" className="text-xs ml-2 text-theme-500"/>
					</label>*/}
					<FormField name="title" label="Titel" placeholder="Kein Titel" removeChars={/[/\\]/g} />
				</React.Fragment>}
		
				{(!customers.loading && !pagePreviewMode) && 
					<FormFieldSelect
						name="customerid"
						label="Kund*in"
						placeholder="Kund*innen durchsuchen ..."
						customerSelect={true}
						options={customers.list
							.sort((x, y) => {
								if(x.favorite > y.favorite) return -1;
								if(x.favorite < y.favorite) return 1;
								
								return x.title.localeCompare(y.title);
							})
							.reduce((x, y) => {
								x.push({
									value: y.id, 
									label: y.title, 
									subline: formatAddressShort(y),
									favorite: y.favorite,
									customerid: y.customerid
								})
							return x
						}, [])} />
				}
				
				{(exported && pagePreviewMode) && 
					<div className="FormField mt-6">
						<label htmlFor="pdfname" className="mt-2 mb-1">PDF-Dateiname</label>
						<input type="text" id="pdfname" value={exportData.filename} onChange={changeFilename}/>
					</div>
				}
				
				{exported && 
					<div className="p-2 bg-gray-300 rounded my-3">
						<table className="table-fixed no-tnum">
							<tbody>
								<tr>
									<th className="border-none text-black ">Rechnungsnummer</th>
									<td className="border-none tnum">{tags(user.invoicenumberformat, { invoice: {
										invoicenumber: values.invoicenumber,
										invoicedate: values.invoicedate
									}}).string}</td>
								</tr>
								<tr>
									<th className="border-none text-black ">Datum</th>
									<td className="border-none ">{values.invoicedate}</td>
								</tr>
								<tr>
									<th className="border-none text-black ">Zahlungsfrist</th>
									<td className="border-none ">{values.deadline} Tage</td>
								</tr>
								{/*<tr>
									<th className="text-black">Kunde</th>
									<td>{console.log(values)}</td>
								</tr>*/}
							</tbody>
						</table>
					</div>
				}
				
				{(exported && !pagePreviewMode) &&
					<FormFieldStatus name="status" label="Stadium" invoicedate={currentInvoiceDate} deadline={values.deadline} />}

					
				{(!exported && !pagePreviewMode) &&
					<div className="my-6 pt-6 border-t">
						<p className="mt-0">Rechnungsnummer</p>
						
						{/*<label htmlFor="" className={["leading-tight mt-6 mb-0", invoice.exported ? "text-gray-500" : ""].join(' ')}>
							{invoice.exported ? 
								<span>Rechnungsnummer</span> : 
								<span>Rechnungsnummer</span>}
						</label>*/}
						
						<div className="flex justify-between items-center">
							<FormField 
								name="invoicenumber"
								type="number" 
								placeholder={currentInvoiceNumber + 1}
								className="w-2/5 tnum"/>
							<Icon icon="arrowRight" className="mx-2 text-gray-500"/>
							<div className="w-full">
								<span className="tnum text-gray-900 bg-gray-300 p-2 rounded-sm mt-1 border block">
									{tags(user.invoicenumberformat, { invoice: {
										invoicenumber: values.invoicenumber || currentInvoiceNumber + 1,
										invoicedate: values.invoicedate || germanDate(new Date())
									}}).string}
								</span>
							</div>
						</div>
						
						<div className="inline-flex items-center pt-2">
								{!manualInvoiceNumberMode ?
									<Icon icon="auto" className="mr-2 text-green-500 text-sm"/> :
									<Icon icon="arrowRight" className="mr-2 text-blue-500 text-sm"/>
								}
								{!manualInvoiceNumberMode ? 
									<span className="flex-grow text-green-500">wird automatisch gesetzt</span> :
									<span className="flex-grow text-blue-500">manuell gesetzt</span>
								}
							</div>
							
						<div className="flex -mx-3">
							<FormField 
								className="w-3/5 px-3" 
								name="invoicedate" 
								label="Rechnungsdatum" 
								placeholder={currentInvoiceDate} />
								
							<FormField className="w-2/5 px-3" name="deadline" label="Zahlungsfrist"/>
						</div>

						<div className="text-sm text-gray-700">Fällig am {due.date} ({due.fromNow})</div>
					
					</div>
				}
				
				{!pagePreviewMode && 
					<FormFieldSelect
						name="tags"
						label="Schlagworte"
						isMulti={true}
						isClearable={false}
						creatable={true}
						options={invoices.tags ? invoices.tags.reduce((x, y) => {
							x.push({ value: y, label: y })
							return x
						}, []) : []}
					/>
				}
				
					{/*<div>
					<div className="mt-5 mb-4">
							
						<div className="flex mt-4 -mx-2">
							<Button
								text="Kopieren"
								link={{
									pathname: `/${this.props.path}/create/`,
									state: {
										action: 'duplicate',
										id: invoice.id
									}
								}} 
								color={COLORS.SECONDARY}
								className={["w-1/2 mx-2 mt-0"]} />
								
							<Button
								text="Stornieren"
								link={{
									pathname: `/${this.props.path}/create/`,
									state: {
										action: 'cancel',
										id: invoice.id
									}
								}} 
								color={COLORS.SECONDARY}
								className={["w-1/2 mx-2 mt-0"]} />
						</div>

						<Button
							text="In den Papierkorb legen"
							action={() => this.props.onSetInvoiceTrash(this.props.invoice.id, true)}
							color={COLORS.DANGER}
							type={TYPES.GHOST}
							className={["block mt-4"]} />
						
					</div>
				</div>
				
				<h3 className="mt-8 mb-4">Rechnung versenden</h3>*/}
				
				{!pagePreviewMode && <div>
					<Button 
						text={"Vorschau anzeigen"}
						disabled={pagePreviewMode}
						color={COLORS.PRIMARY} 
						size={SIZES.LARGE}
						icon="arrowRight"
						iconPosition="right"
						action={e => togglePagePreviewMode(true)}
						className="block mt-2 w-full" />
				
						
					<Button 
						text={pristine ? "gespeichert" : `Änderungen speichern (${getCommandKeySymbol()}+S)`}
						icon={pristine ? "check" : ""}
						action="submit"
						disabled={submitting || pristine}
						working={submitting}
						color={COLORS.SECONDARY}
						size={SIZES.MEDIUM}
						className="mt-2" />
						
					{/*<pre>{JSON.stringify(Object.values(getTags()).map(x => Object.keys(x)), 0, 2)}</pre>*/}
					
				</div>}
				
				
				{(!exported && pagePreviewMode) && <React.Fragment>
					<p className="text-gray-800">Wenn du diese Rechnung abschließt kannst du die <strong>Rechnungsnummer</strong>, <strong>das Datum</strong> und die <strong>Zahlungsfrist</strong> danach nicht mehr ändern. </p>

					<Alert className="bg-red-200 text-red-900">Prüfe vor dem Exportieren nochmal genau, ob alle Angaben richtig sind.</Alert>
					
					{/*<Alert>Diese Platzhalter wurden nicht gefunden:</Alert>*/}
					
					<div className="FormField">
						<label htmlFor="pdfname"><span>PDF-Dateiname</span></label>
						<input type="text" id="pdfname" value={exportData.filename} onChange={changeFilename}/>
						<span className="text-gray-600 block mt-1 mb-4">.pdf wird automatisch angehangen</span>
					</div>
					
					<div className="p-2 bg-gray-300 rounded my-3">
						<table className="table-fixed no-tnum">
							<tbody>
								<tr>
									<th className="border-none text-black">Rechnungsnummer</th>
									<td className="border-none tnum">{exportData.formattedInvoiceNumer}</td>
								</tr>
								<tr>
									<th className="border-none text-black ">Datum</th>
									<td className="border-none ">{exportData.invoicedate}</td>
								</tr>
								<tr>
									<th className="border-none text-black ">Zahlungsfrist</th>
									<td className="border-none ">{exportData.deadline} Tage</td>
								</tr>
							</tbody>
						</table>
					</div>	
				</React.Fragment>}
				
				{(pagePreviewMode && missingTags.length > 0) && 
					<Alert className="bg-red-200 text-gray-900">
						<div>
							<p className="text-red-800">Zu {missingTags.length} Platzhaltern wurden keine Werte gefunden:</p>
							<div className="flex flex-wrap gap-3 mt-3">
								{missingTags.map((x, i) => <Tag key={i} tag={x} brackets={true} className="bg-red-100 text-red-700" />)}
							</div>
						</div>
					</Alert>
					// <Alert className="p-3 bg-green-200 text-gray-900 justify-start">
					// 	<Icon icon="check" className="mr-1"/>
					// 	<span>Alle Platzhalter wurden ersetzt</span>
					// </Alert>	
				}
				
				
				{pagePreviewMode && <div>
					<Button
						text="Weiterarbeiten"
						color={COLORS.SECONDARY} 
						size={SIZES.LARGE}
						icon="arrowLeft"
						className="block"
						action={(e => togglePagePreviewMode(false))}/> 	
					<Button
						text={!exported ? "Rechnung abschließen" : "Neues PDF exportieren"}
						icon={!exported ? "lock" : "arrowRight"}
						iconPosition={!exported ? "left" : "right"}
						disabled={files.aws.loading}
						working={files.aws.loading}
						action={() => form.submit(values).then(() => onCreatePdf())}
						size={SIZES.LARGE}
						color={COLORS.PRIMARY}
						className="block mt-6"/>
				</div>}
						
				{(files.list.length > 0 || files.aws.loading || files.aws.error) && <div>
						<h3>Dateien</h3>
						<FileList 
							{...files}
							invoices={invoices}
							customers={customers}
							filterBy={{trash: [false]}}
							limit={10}
							user={user}
							onShareFile={this.props.onShareFile}
							onUpdateFile={this.props.onUpdateFile}
							//invoiceid={invoice.id} 
							/>
					</div>
				}
					
			</div>
		)
	}
}

export { InvoiceEditorSidebar };
