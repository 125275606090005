import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { LoadingIndicator } from '../../components'


const ProtectedRoute = ({ component: Component, auth, ...rest }) => (
  <Route {...rest} render={(props) => (
  	!auth.loading ? (
	    auth.loggedIn === true
	      ? <Component {...props} />
	      : <Redirect to={{
					  pathname: '/login',
					  state: { from: props.location }
					}} /> 
		) : (
			<LoadingIndicator />
		)
  )} />
)

export default ProtectedRoute;
