import React, { Component } from 'react'
import { connect } from 'react-redux'

import styles from './styles.module.scss'
import { Page, FormField, FormFieldSelect, InvoiceList, Alert, StarButton, ScrollToTop, RouterPrompt } from '../../components'
import Button, { COLORS, TYPES, SIZES } from '../../components/button'
import { customerActions, customerSelectors, invoiceSelectors, invoiceActions } from '../../redux'
import { Form } from 'react-final-form'
import { Helmet } from 'react-helmet'
import { prepareCustomer, path, testCommandKey, getCommandKeySymbol } from '../../helpers/';

class CustomerEditor extends Component {
	state = {
		initalized: false,
		data: {},
		deletePhrase: ""
	}
	
	deleteKeyword = "LÖSCHEN";
	
	constructor(props) {
		super(props);
		
		this.onUpdateCustomer = this.onUpdateCustomer.bind(this);	
		this.onTypeDeleteCustomer = this.onTypeDeleteCustomer.bind(this);
		this.onDeleteCustomer = this.onDeleteCustomer.bind(this);
		this.onSaveForm = this.onSaveForm.bind(this);
	}
	
	componentDidMount() {
		this.initAddressForm(this.props);
		
		if(this.props.loading !== null && this.props.loading !== true && !this.props.customer) this.props.history.push('/customers/');
		
		document.addEventListener("keydown", this.keyboardSubmitForm, false);
	}

	componentDidUpdate() {
		this.initAddressForm(this.props);
		
		if(this.props.loading !== null && this.props.loading !== true && !this.props.customer) this.props.history.push('/customers/');
	}
	
	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyboardSubmitForm, false);
	}
	
	onTypeDeleteCustomer(e) {
		this.setState({
			deletePhrase: e.target.value
		})
	}
	
	initAddressForm(props) {
		if(!this.state.initalized && !props.loading && props.customer) {
			const { id, intrash, ...fields } = props.customer;
			this.setState({
				initalized: true,
				data: fields
			})
		}
	}
	
	keyboardSubmitForm(e) {
		if(testCommandKey(e) && e.keyCode === 83) {
			e.preventDefault();
			// this.submitFormFunction();
			document.getElementById('customerEditorForm')
				.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}
	
	onSaveForm(values, form) {
		if(form.getState().dirty) {
			const dirtyFields = form.getState().dirtyFields;
			let changedFields = {};
			
			Object.keys(dirtyFields).forEach(x => {
				changedFields[x] = values[x];
			})
			return this.onUpdateCustomer(changedFields);
		} else {
			return Promise.resolve(true);
		}
	}
		
	onUpdateCustomer(values) {
		let preparedValues = prepareCustomer(values);
		
		return new Promise((resolve, reject) => {
			if(!Object.keys(preparedValues).length) return resolve();
				
			this.props.onUpdateCustomer(this.props.customer.id, prepareCustomer(values))
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	onDeleteCustomer() {
		this.props.onDeleteCustomer(this.props.customer.id)
	}

	render() {
		const { customer, user, files, loading, invoicesList, invoicesLoading } = this.props;
		
		return (
			<Page>
				<ScrollToTop />
				<Helmet>
					<title>{this.props.customer ? this.props.customer.title : 'Laden …'}</title>
				</Helmet>

				<div className={styles.Editor}>	
					<Button
						text="Zurück zur Liste"
						icon="arrowLeft"
						link={path('customers')}
						type={TYPES.GHOST} 
						size={SIZES.SMALL}
						className="mt-0 mb-4"
						/>

					<div className="flex items-center justify-between">
						<h1 className="m-0">{customer.title}</h1>	
						<StarButton
							className="ml-3"
							state={customer.favorite} 
							onClick={() => this.onUpdateCustomer({favorite: !customer.favorite})} />
					</div>
					
					{!loading && <Form
						onSubmit={this.onSaveForm}
						initialValues={this.state.data}
						render={({ submitError, handleSubmit, submitting, pristine, values, form }) => (
							<form 
								id="customerEditorForm"
								onSubmit={e => {
									handleSubmit(e)
										.then(() => form.reset(this.state.data))
								}}>
									<section className="border-b py-6">
										<div className="flex justify-between mt-6 mb-8 -mx-4 ">
											<div className="w-1/3 px-4">
												<h3 className="mt-0 text-gray-800">Intern</h3>
												<p className="text-gray-600">Diese Felder werden nur hinter den Kulissen verwendet und tauchen später nicht in deinen Rechnungen auf.</p>
											</div>
											<div className="w-1/2 px-4">
												<FormField name="title" label="Bezeichnung" tag="customer.title" />
												<FormField type="number" name="customerid" label="Kundennummer" tag="customer.id" />
												<FormField type="textarea" name="notes" label="Notizen"/>
																								
												<FormFieldSelect
													name="tags"
													label="Schlagworte"
													isMulti={true}
													isClearable={false}
													creatable={true}
													options={this.props.tags ? this.props.tags.reduce((x, y) => {
														x.push({ value: y, label: y })
														return x
													}, []) : []}
												/>
												
												{/*<br />
												tags: {JSON.stringify(values.tags, null, 2)}
												<br />
												all tags: {JSON.stringify(this.props.tags, null, 2)*/}
												
												<Button 
													text={pristine ? "gespeichert" : `Änderungen speichern (${getCommandKeySymbol()}+S)`}
													icon={pristine ? "check" : ""}
													action="submit"
													disabled={submitting || pristine}
													working={submitting}
													color={COLORS.PRIMARY}
													size={SIZES.MEDIUM}
													className="mt-6" />
											</div>
										</div>
									</section>
									
									<section className="border-b py-6">
										<div className="flex justify-between mt-6 mb-8 -mx-4">
											<div className="w-1/3 px-4">
												<h3 className="mt-0 text-gray-800">Rechnungsdaten</h3>
												<p className="text-gray-600"></p>
											</div>
											<div className="w-1/2 px-4">
												<FormField name="company" label="Firma" tag="customer.company" />
												
												<div className="flex -mx-3">
													<div className="w-1/2 px-3">
														<FormField name="firstname" label="Vorname" tag="customer.firstname" />
													</div>
													<div className="w-1/2 px-3">
														<FormField name="lastname" label="Nachname" tag="customer.lastname" />
													</div>
												</div>
												
												<FormField name="addresslinetwo" label="Adresszusatz" secondLabel="(optional)" placeholder="c/o, z. Hd., …" tag="customer.addresslinetwo" />
												<FormField name="addresslineone" label="Straße, Hausnummer" tag="customer.addresslineone" />
												
												<div className="flex -mx-3">
													<div className="w-1/3 px-3">
														<FormField name="postcode" label="Postleitzahl" className="tnum"  tag="customer.zipcode" />
													</div>
													<div className="w-2/3 px-3">
														<FormField name="city" label="Stadt" tag="customer.city" />
													</div>
												</div>
												
												<FormField name="country" label="Land" placeholder="FRANKREICH" tag="customer.country" />
												
												<Button 
													text={pristine ? "gespeichert" : `Änderungen speichern (${getCommandKeySymbol()}+S)`}
													icon={pristine ? "check" : ""}
													action="submit"
													disabled={submitting || pristine}
													working={submitting}
													color={COLORS.PRIMARY}
													size={SIZES.MEDIUM}
													className="mt-6" />
													
											</div>
										</div>
									</section>
									
									<section className="border-b py-6">
										<div className="flex justify-between mt-6 mb-8 -mx-4 ">
											<div className="w-1/3 px-4">
												<h3 className="mt-0 text-gray-800">Kontakt</h3>
												{/*<p className="text-gray-600">Diese Felder werden nur hinter den Kulissen verwendet und tauchen später nicht in deinen Rechnungen auf.</p>*/}
											</div>
											<div className="w-1/2 px-4">
												<FormField name="email" label="E-Mail-Adresse" tag="customer.email" />
												<FormField name="tel" label="Telefonnummer" tag="customer.phone" />
												
												<Button 
													text={pristine ? "gespeichert" : `Änderungen speichern (${getCommandKeySymbol()}+S)`}
													icon={pristine ? "check" : ""}
													action="submit"
													disabled={submitting || pristine}
													working={submitting}
													color={COLORS.PRIMARY}
													size={SIZES.MEDIUM}
													className="mt-6" />
													
											</div>
										</div>
									</section>
									
									<RouterPrompt 
										when={true}
										navigate={location => this.props.history.push(location)}
										shouldBlockNavigation={location => {
											if(!pristine) return true;
											return false;
										}} 
										action={() => handleSubmit()} />
							</form>
							)
						}/>
					}
					
					<section className="py-6">
						<h3 className="mt-6 text-gray-800">Rechnungen</h3>
						<InvoiceList 
							list={invoicesList}
							user={user}
							files={files}
							loading={invoicesLoading}
							showFooter={true}
							onUpdateInvoice={this.props.onUpdateInvoice} /> 
					</section>
						
					<section className="py-6 border-b">
						<Button
							text={`Rechnung für ${customer.title} erstellen`}
							icon="add"
							link={path('invoices', 'create', `?customerid=${customer.id}`)}
							color={COLORS.PRIMARY}
							size={SIZES.LARGE}
							className={["mt-0"]}/>
					</section>
					
					
					<section className="py-6">
						<div className="flex justify-between mt-6 mb-8 -mx-4 ">
							<div className="w-1/3 px-4">
								<h3 className="mt-0 text-gray-800">Kund/in löschen</h3>
								<p className="text-gray-600">Wenn du ein/e Kund/in einmal gelöscht hast gibt es kein zurück mehr.</p>
							</div>
							<div className="w-1/2 px-4">
								<div className="FormField">
									<p>Schreibe <span className="text-red-600 bg-gray-200 rounded-sm p-1 leading-snug tracking-wider text-sm font-medium">{this.deleteKeyword}</span> in das Textfeld, um diese/n Kund/in endgültig zu löschen.</p>
									<Alert>Dadurch werden keine Rechnungen oder Dateien gelöscht.</Alert>
									<input type="text" placeholder={this.deleteKeyword} onChange={e => this.onTypeDeleteCustomer(e)} />
								</div>
								<Button
									text="Diese/n Kund/in endgültig löschen"
									icon="delete"
									disabled={this.state.deletePhrase !== this.deleteKeyword}
									action={this.onDeleteCustomer} 
									color={COLORS.DANGER} 
									size={SIZES.MEDIUM}
									className={["inline-block"]} />
							</div>
						</div>
					</section>
				</div>
			</Page>
		)
	}
}


const mapStateToProps = (state, props) => {
	const { customers } = state;
	const { match } = props;
	
	return {
		customer: customerSelectors.getCustomerById(state, match.params.id), //customers.list.filter(x => parseInt(x.id) === parseInt(match.params.id))[0] || false,
		loading: customers.loading,
		tags: customers.tags,
		invoicesList: invoiceSelectors.getInvoicesByCustomerId(state, parseInt(match.params.id)),
		invoicesLoading: state.invoices.loading,
		files: state.files,
		user: state.auth.user
	}
}

const mapActionsToProps = {
	onUpdateCustomer: customerActions.updateCustomer,
	onDeleteCustomer: customerActions.deleteCustomer,
	onUpdateInvoice: invoiceActions.updateInvoice
}

export default connect(mapStateToProps, mapActionsToProps)(CustomerEditor);
