import React, { Component } from 'react'
import { connect } from 'react-redux'

import { customerActions, customerSelectors } from '../../redux'
import { prepareCustomer, path } from '../../helpers'
import { LoadingIndicator } from '../../components'

class CustomerCreator extends Component {
	constructor(props) {
		super(props);
		
		this.createCustomer = this.createCustomer.bind(this);
	}
	
	componentDidMount() {
		this.createCustomer();
	}
	
	createCustomer() {
		const { currentCustomerNumber, onAddCustomer } = this.props;
		
		let customer = prepareCustomer({
			title: 'Neue/r Kund/in',
			customerid: currentCustomerNumber + 1
		});
		
		// später: check permission und customers limit
	
		onAddCustomer(customer).then(customer => {
			this.props.history.push(path('customers', customer.id));
		})
	}
	
	render() {
		return <LoadingIndicator />
	}	
}

const mapStateToProps = state => ({
	currentCustomerNumber: customerSelectors.getCurrentCustomerNumber(state)
})

const mapActionsToProps = {
	onAddCustomer: customerActions.addCustomer
}

export default connect(mapStateToProps, mapActionsToProps)(CustomerCreator);
