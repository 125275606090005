export const isJSON = str => {
	// let val;
  
  try {
		let val = JSON.parse(str);
    return val;
  } catch (e) {
    console.error(e);
		return false;
  }
  
  // if(val != "" && val != null ) {
  // 	return val;
  // } else {
  // 	return false;
  // }
}
