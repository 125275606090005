import React, { Component } from 'react'
import { connect } from 'react-redux'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import { Page, Skeleton, Alert, ContextMenu, Icon, StarButton, ScrollToTop, SortHeader } from '../../components'
import { hasPermissions, path, prepareCustomer } from '../../helpers'
import Button, { COLORS, SIZES } from '../../components/button'
import { customerActions, customerSelectors } from '../../redux'
import { Helmet } from 'react-helmet'
// import { invoiceSelectors } from '../../redux/ducks/invoices'

import tableStyles from '../../styles/tables.module.scss'

class Customers extends Component {
	
	
	render() {
		const { loading, customers, customersPath, sortBy } = this.props;
		const limit = 100;
		
		const { setSort } = this.props;
		
		const maxCustomers = hasPermissions("customers:add_max_10") ? 10 : hasPermissions("customers:add_max_20") ? 20 : null;

		
		return (
			<Page>
				<ScrollToTop />
				<Helmet>
					<title>Kund/innen | Billu</title>
				</Helmet>
				<header className="flex items-start justify-between">
					<div>
						<h1 className="mb-5">Kund/innen</h1>
						{maxCustomers !== null && customers.length < maxCustomers ? 
							<p className="my-3 px-2 py-1 rounded bg-green-200 text-green-900 table tracking-wider">{customers.length}/{maxCustomers}</p> : null
						}
					</div>
					{maxCustomers === null || customers.length < maxCustomers ? 
						<Button
							text="Kund/in erstellen"
							icon="add"
							link={path(customersPath, "create")}
							color={COLORS.PRIMARY}
							size={SIZES.LARGE}
							className={["mt-0"]}/> : null}
				</header>
				
				{loading ? (
					<Skeleton />
				) : (
					<div>
						{maxCustomers === null || customers.length < maxCustomers ? null : 
							<Alert className="">
								<div className="flex items-center justify-start">
									<Icon icon="lock" className="mr-2 text-red-600" />
									<span>Du hast die maximale Anzahl an Kund*innen in deinem Paket erreicht. Um mehr zu erstellen benötigst du mindestens das <strong>Solo-Paket</strong>. <HashLink smooth to="/settings/account#subscription" className="text-green-600 underline font-medium">Jetzt upgraden</HashLink></span>
								</div>
							</Alert>}
						<table>
							<thead>
								<tr>
									<th>
										<SortHeader title="Nr." field="customerid" sortBy={sortBy} setSort={setSort}/>
									</th>
									<th>
										<SortHeader title="Titel" field="title" sortBy={sortBy} setSort={setSort}/>
									</th>
									{/*<th><span>Rechnungen</span></th>*/}
									<th>
										<SortHeader title="" field="favorite" sortBy={sortBy} setSort={setSort}/>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{customers.slice(0, limit)
									.map((x, i) => (
									<tr key={x.id}>
										<td className={tableStyles.InvoiceNumber}>
											<Link to={path(customersPath, x.id)}>{x.customerid}</Link>
										</td>
										<td className={tableStyles.Title}>
											<Link to={path(customersPath, x.id)} title={x.title}>{x.title}</Link>
										</td>
										{/*<td>
											<span>
												{invoices.filter(y => y.customerid === x.id).length}
											</span>
										</td>*/}
										<td className={tableStyles.ContextMenu}>
											<div className="flex items-center h-full">
												<StarButton 
													state={x.favorite}
													onClick={() => this.props.onUpdateCustomer(x.id, prepareCustomer({ favorite: !x.favorite }))} />
											</div>
										</td>
										<td className={tableStyles.ContextMenu}>
											<ContextMenu items={[
												{
													label: 'Bearbeiten',
													icon: 'edit',
													link: path(customersPath, x.id)
												},
												{
													label: 'Rechnung erstellen',
													icon: 'add',
													link: path('invoices', 'create', `?customerid=${x.id}`)
												}												
											]} 
											position={["bottom right", "top right"]} />
										</td>
									</tr>	
								))}
							</tbody>
							<tfoot>
								<tr className="text-gray-500">
									<td colSpan="4" className="">
										<span><span className="text-gray-700">{customers.length}</span> Kund/innen gefunden</span>
										{customers.length > limit && <span className="ml-1">({limit} angezeigt)</span>}
									</td>
								</tr>
								{maxCustomers === null || customers.length < maxCustomers ? 
									<tr className={tableStyles.ActionRow}>
										<td className="p-0" colSpan="4">
											<Link to={path('customers', 'create')} className="inline-flex items-center p-1 rounded hover:text-black hover:no-underline hover:bg-gray-300 text-gray-500 hover:text-green-500 my-2">
												<Icon icon="add" className="mr-1"/>
												<span className="leading-relaxed text-gray-700">Neue Kund/in erstellen</span>
											</Link>
										</td>
									</tr> : null
								}
							</tfoot>
						</table>
					</div>
				)}
			</Page>	
		)
	}
}

const mapStateToProps = state => {
	const { customers } = state;
		
	return {
		customers: customerSelectors.getCustomersList(state),
		loading: customers.loading,
		error: customers.error,
		customersPath: customers.path,
		// invoices: invoiceSelectors.getInvoicesList(state, { year: [], month: [], status: [], trash: [false] }, {}),
		sortBy: customers.sortBy
	}
}

const mapActionsToProps = {
	onUpdateCustomer: customerActions.updateCustomer,
	setSort: customerActions.setSort
}

export default connect(mapStateToProps, mapActionsToProps)(Customers);
