import React, { Component } from 'react'
import { Page, Skeleton, InvoiceList, Icon, ScrollToTop, FileList } from '../../components'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { invoiceSelectors, authSelectors, invoiceActions, fileActions, fileSelectors } from '../../redux'
import { path, numberToEuro } from '../../helpers'
// import Button, { COLORS, SIZES } from '../../components/button'
import { Helmet } from 'react-helmet'


class Dashboard extends Component {
	render() {
		const { invoicesList, invoicesLoading, setFilter, history, files, user } = this.props;
		
		// let draftInvoices, openInvoices, paidInvoices;
		
		// if(invoices) {
		// 	draftInvoices = invoices.filter(x => x.status === 1).length;
		// 	openInvoices = invoices.filter(x => x.status === 2).length;
		// 	paidInvoices = invoices.filter(x => x.status === 3).length;
		// }
		
		const draftInvoices = invoicesList.filter(x => x.status === 1),
			openInvoices = invoicesList.filter(x => x.status === 2),
			paidInvoices = invoicesList.filter(x => x.status === 3);
			
		const openTotal = openInvoices.reduce((x, y) => x + y.total, 0),
			paidTotal = paidInvoices.reduce((x, y) => x + y.total, 0);
		
		return (
			<Page>
				<ScrollToTop />
				<Helmet>
					<title>Übersicht | Billu</title>
				</Helmet>
				<div>
					{/*<h1 className="mb-4">Übersicht</h1>*/}
					
					<h2 className="mb-6">
						<Link to="/invoices" className="hover:text-gray-600">
							<span className="inline-block">Rechnungen</span>
						</Link>
					</h2>
					
					<div className="grid grid-cols-2 gap-5 flex-wrap mb-10">
						<div>
							<div className="flex flex-col justify-end h-full bg-gray-200 p-4 rounded-lg hover:bg-gray-300 cursor-pointer transition-colors duration-150 relative"
								onClick={() => {
									setFilter({ status: [1] });
									history.push(path('invoices'))
								}}>
								<span className="inline-block w-8 h-8 rounded-full">
									<Icon icon="status1" svgClassName="w-full h-full" />
								</span>
								<h3 className="text-3xl mb-2">{draftInvoices.length}</h3>
								<span className="text-gray-600">Entwürfe</span>
								{/*<span className="absolute top-0 right-0 w-5 h-5 mt-5 mr-3"><SVG1 /></span>*/}
							</div>
						</div>
						<Link to={path('invoices', 'create')} className="hover:no-underline">
							<div className="flex flex-col justify-end h-full bg-green-500 p-4 rounded-lg hover:bg-green-500-hover cursor-pointer transition-colors duration-150 relative"
								onClick={() => {
									setFilter({ status: [3] });
									history.push(path('invoices'))
								}}>
								<span className="inline-block w-8 h-8 rounded-full text-green-200">
									<Icon icon="add" svgClassName="w-full h-full" />
								</span>
								<h3 className="text-3xl text-white mb-2">Rechnung</h3>
								<span className="text-green-200">Neue Rechnung erstellen</span>
								{/*<span className="absolute top-0 right-0 w-5 h-5 mt-5 mr-3"><SVG3 /></span>*/}
							</div>
						</Link>
						
						
						<div>
							<div className="flex flex-col justify-end h-full bg-gray-200 p-4 rounded-lg hover:bg-gray-300 cursor-pointer transition-colors duration-150 relative"
								onClick={() => {
									setFilter({ status: [2] });
									history.push(path('invoices'))
								}}>
								<span className="inline-block w-8 h-8 rounded-full">
									<Icon icon="status5" svgClassName="w-full h-full" />
								</span>
								<h3 className="text-3xl text-red-400 mb-2">{openInvoices.length}</h3>
								<span className="text-gray-600">{numberToEuro(openTotal)} Offen</span>
								{/*<span className="absolute top-0 right-0 w-5 h-5 mt-5 mr-3"><SVG5 /></span>*/}
							</div>
						</div>
						<div>
							<div className="flex flex-col justify-end h-full bg-gray-200 p-4 rounded-lg hover:bg-gray-300 cursor-pointer transition-colors duration-150 relative"
								onClick={() => {
									setFilter({ status: [3] });
									history.push(path('invoices'))
								}}>
								<span className="inline-block w-8 h-8 rounded-full">
									<Icon icon="status3" svgClassName="w-full h-full" />
								</span>
								<h3 className="text-3xl text-green-500 mb-2">{paidInvoices.length}</h3>
								<span className="text-gray-600">{numberToEuro(paidTotal)} Bezahlt</span>
								{/*<span className="absolute top-0 right-0 w-5 h-5 mt-5 mr-3"><SVG3 /></span>*/}
							</div>
						</div>
						
					</div>
					{/*}
					<div className="mb-8">
						
						<Link to={path('customers', 'create')} className="inline-flex items-center p-1 rounded hover:no-underline hover:bg-gray-300 ml-4 cursor-pointer transition-colors duration-150 text-gray-500 hover:text-green-500">
							<span className="mr-1 icon"><Icon icon="add" /></span>
							<span className="no-underline text-black leading-relaxed">Kund/in erstellen</span>
						</Link>
					</div>*/}
					
					
					{/*<h1>Hey, {user.username}</h1>*/}
					
					{/*}
					<div className="-mx-8 bg-gray-200 rounded px-8 py-8 my-10 border-theme border-2">
						<h2 className="mt-0 text-theme-500">Schließe die Einrichtung ab.</h2>
						<p className="text-lg">Fülle deine Rechnungsdaten aus, damit du in Zukunft mit möglichst wenigen Schritten neue Rechnungen erstellen kannst.</p>
						<Button
							text="Rechnungsdaten ausfüllen"
							link="/invoices/settings/your-data" 
							color={COLORS.PRIMARY}
							size={SIZES.LARGE}
							className={["mt-8"]}/>
					</div>
					*/}
					
					{/*<div className="flex flex-wrap -mx-8 bg-gray-200 rounded px-4 py-8 my-10">
						<span className="w-full px-4 mt-0 mb-6 text-gray-500">So funktionierts: </span>
						<div className="w-1/3 px-4">}
							<h2 className="mt-3 "><strong className="text-theme-500">1.</strong> Rechnung schreiben</h2>
							<p className="text-gray-600">Erstelle mit wenigen Klicks eine neue Rechnungen und lege eine Zahlungs&shy;frist fest.</p>
						</div>	
						<div className="w-1/3 px-4">}
							<h2 className="mt-3 "><strong className="text-theme-500">2.</strong> PDF versenden</h2>
							<p className="text-gray-600">Verschicke deine Rechnungen über einen Link oder als PDF an deine Kunden.</p>
						</div>	
						<div className="w-1/3 px-4">}
							<h2 className="mt-3 "><strong className="text-theme-500">3.</strong> Status im Blick behalten</h2>
							<p className="text-gray-600">Behalte den Überblick über offene Rechnungen und lass dich an Zahlungsdaten erinnern.</p>
						</div>	
						<Button
							text="Jetzt eine Rechnung erstellen"
							link="/invoices/create" 
							color={COLORS.PRIMARY}
							size={SIZES.LARGE}
							className={["mt-8 table mx-auto"]}/>
					</div>*/}
					
					{/*<div className="flex -mx-4">
						<div className="w-1/2 px-4">
							<Link to="/invoices/create" className="block rounded bg-green-500 text-white px-8 py-6 my-6 hover:text-white hover:no-underline shadow-md">
								<span className="text-sm opacity-50 block tracking-wider">RECHNUNGEN</span>
								<span className="text-2xl block my-3">Erstelle eine neue Rechnung</span>
								<span><span className="text-lg">+</span> Neue Rechnung erstellen</span>
							</Link>
						</div>
						<div className="w-1/2 px-4">
							<Link to="/customers/create" className="block rounded bg-red-400 text-white px-8 py-6 my-6 hover:text-white hover:no-underline shadow-md">
								<span className="text-sm opacity-50 block tracking-wider">KUNDEN</span>
								<span className="text-2xl block my-3">Erstelle einen neuen Kunden</span>
								<span>+ Neuen Kunden erstellen</span>
							</Link>
						</div>
					</div>*/}
					
					{/*<h3 className="mt-12 mb-6">
						<Link to="/invoices">
							<span className="inline-block align-middle">Letzte Rechnungen</span> 
							<Icon icon="chevronRightSmall" className="inline-block align-middle text-gray-500"/>
						</Link>
					</h3>*/}
					{invoicesLoading ? (
						<Skeleton rows={5}/>
					) : (
						<InvoiceList 
							list={invoicesList} 
							user={user}
							files={files}
							loading={invoicesLoading} 
							onUpdateInvoice={this.props.onUpdateInvoice} 
							limit={5} /> 
					)}
					
					{files.list.length ? <div>
						<h2 className="mt-12 mb-6">
							<Link to="/files" className="hover:text-gray-600">
								<span className="inline-block align-middle">Dateien</span> 
								{/*<Icon icon="chevronRightSmall" className="inline-block align-middle text-gray-500"/>*/}
							</Link>
						</h2>
						<FileList 
							user={user} 
							{...files}
							invoices={this.props.invoices}
							customers={this.props.customers}
							onUpdateFile={this.props.onUpdateFile}
							onShareFile={this.props.onShareFile}
							limit={10} />
					</div> : null}
					
					
				</div>
				<div></div>
			</Page>
		)
	}
}


const mapStateToProps = state => {
	return {
		invoicesList: invoiceSelectors.getInvoicesList(state, { year: [], month: [], status: [], trash: [false] }, {}),
		invoicesLoading: state.invoices.loading,
		invoices: state.invoices,
		customers: state.customers,
		user: authSelectors.getUser(state),
		files: {
			...state.files,
			list: fileSelectors.getFilesList(state, { year: [], month: [], status: [], trash: [false] }, {})
		}
	}
}


const mapDispatchToProps = {
	onUpdateInvoice: invoiceActions.updateInvoice,
	onUpdateFile: fileActions.updateFile,
	setFilter: invoiceActions.setFilter,
	onShareFile: fileActions.shareFile,
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
