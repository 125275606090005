import React, { Component } from 'react'
import { authActions } from '../../redux'
import { connect } from 'react-redux'
import { Page, FormField, FormFieldPassword, LoadingIndicator, Icon, Alert, ScrollToTop } from '../../components'
import { Link } from 'react-router-dom'
import Button, { COLORS, SIZES } from '../../components/button'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { FORM_ERROR } from 'final-form'
import * as EmailValidator from 'email-validator'
import { Helmet } from 'react-helmet'

const focusOnError = createDecorator();

class Signup extends Component {
	state = {
		showPassword: false,
		passwordStrength: 0
	}
	
	constructor(props) {
		super(props);

		this.onSignup = this.onSignup.bind(this);
		this.validate = this.validate.bind(this);
	}
	
	componentDidUpdate(props) {
		const { user } = props;
		
		if(user.token ? true : false) {
			this.props.history.push({pathname: '/'});
		}
		
		
	}
	
	onSignup({ username, password, email }) {
		return this.props.onSignup(username, email, password)	
			.then(res => {
				const { statusCode: status } = res.error;
				
				if(res.error) {
					if(status === 200) {
						return null;
					} else if(status === 409) {
						let error = {};
						
						if(res.error.fields.indexOf('email') !== -1) {
							error.email = "Diese E-Mail ist schon vergeben"
						}
						if(res.error.fields.indexOf('username') !== -1) {
							error.username = "Dieser Nutzername ist schon vergeben"
						}
						return error
					} else {
						return { [FORM_ERROR]: `Server Error (${status})` }
					}
				}
			})
	}
	
	checkPassword(password) {
		if(!password) return false;
		
		let strength = 0;
		if(password.length > 6) strength++
		if(password.length > 10) strength++
		//if(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) strength++
		if(/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) strength++ // sonderzeichen
		if(/(?=.*\d)(?=.*[a-zA-Z])/.test(password)) strength++ // digit + character
		if(/(?=.*[a-z])(?=.*[A-Z])/.test(password)) strength++ // uppercase + lowercase
		
		return strength;
	}
	
	validate(values) {
  	const errors = {};
  	const { email, username, password, privacy } = values;
  
  	if(!EmailValidator.validate(email)) errors.email = "Die E-Mail ist ungültig"
  	if(!email) errors.email = "Gib deine E-Mail-Adresse ein"
  	
  	if(username && username.length < 4) errors.username = "Mindestens 4 Zeichen"
  	if(!username) errors.username = "Such dir einen Nutzernamen aus"
  
  	if(!privacy) errors.privacy = "Bitte akzeptiere"
  	
  	
		if(!password) {
			errors.password = "Gib ein sicheres Password ein"
		} else {
			if(this.checkPassword(password) < 2) {
				errors.password = "Nicht sicher genug";
			}
  	}
		// this.setState({ passwordStrength: strength })
		// errors.passwordStrength = strength;
  	
  	return errors;
  }
	

	render() {
		const { loading, signup, user } = this.props;
		
		return (
			<Page>
				<ScrollToTop />
				<Helmet>
					<title>Registrieren | Billu</title>
				</Helmet>
				<div className="max-w-md mx-auto">
					<Icon icon="logo-icon" className="mx-auto table mb-6" svgClassName="w-16 h-16" stroke={false}/>
					<div className="bg-white p-10 rounded">
						{loading ?
							<div className="relative mt-12 mb-16"><LoadingIndicator /></div> : (
								(!signup.status || signup.status === 409) ? <div>
									<h2 className="mt-0">Erstelle ein Konto</h2>
									<Form
										onSubmit={this.onSignup}
										decorators={[focusOnError]}
										validate={this.validate}
										render={({ meta, submitError, handleSubmit, submitting, values }) => (
											<form onSubmit={handleSubmit}>
											
												{(!submitting && submitError) && 
													<Alert className="mt-5 bg-red-200 text-gray-900">{submitError}</Alert>}
											
												<FormField name="email" label="E-Mail-Adresse" autoFocus={true}/>
												<FormField name="username" label="Nutzername"/>
												<FormFieldPassword name="password" label="Passwort" strength={this.checkPassword(values.password)} className="tnum" />
												
												<Alert className="block leading-snug my-5">Tipp: Verwende für ein sicheres Passwort Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen.</Alert>
												<span className="block border-b-2"></span>

												<FormField name="privacy" type="checkbox" label="Datenschutz" checkboxLabel={<span>Ich erkläre mich mit den <a href="https://billu.app/datenschutzerklaerung/" rel="noopener noreferrer" target="_blank" className="text-theme-500 underline">Datenschutz&shy;bestimmungen</a> einverstanden</span>} className="my-6 leading-tight"/>
												
												<Button 
													text={"Kostenloses Konto erstellen"} 
													disabled={submitting} 
													working={signup.loading}
													action='submit' 
													color={COLORS.PRIMARY} 
													size={SIZES.LARGE} 
													className="block mt-6" />
											</form>
										)}
									/>
								</div> : 
								<div>
									{!signup.error ? <div className="text-center">
										<Icon icon="mailSent" className="text-2xl p-2 bg-gray-200 rounded-full text-theme-500 table mx-auto"/>
										<h3 className="mt-4">Bestätige deine E-Mail-Adresse</h3>
										<p>Wir haben dir eine E-Mail an {user.email} mit einem Bestätigungslink zugeschickt.</p>
										{/*<a href="#asdf" className="block mt-4 text-theme-500">Ich habe keine E-Mail bekommen</a>*/}
									</div> : <div className="text-center">
										<Icon icon="error" className="text-2xl p-2 bg-gray-200 rounded-full text-red-500 table mx-auto"/>
										<h3 className="mt-4">Fehler beim Registrieren</h3>
										<p>Auf unseren Servern ist beim anlegen deines Kontos ein Fehler aufgetreten. Bitte wende dich an unseren <a href="https://billu.app/support/" target="_blank" rel="noopener noreferrer" className="text-green-500 underline">Support</a>.</p>
									</div>}
								</div>
							)
						}
					</div>
					{(!signup.status || signup.status === 409) ? <p className="mt-6 text-gray-600 mx-auto table">Hast du schon ein Konto? <Link to="/login" className="text-theme-500 hover:no-underline hover:text-black">Melde dich an</Link></p> : null}
				</div>
			</Page>
		)
	}
}

const mapActionsToProps = {
	onSignup: authActions.signup
}

export default connect((state) => state.auth, mapActionsToProps)(Signup);
