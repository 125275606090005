import React, { Component } from 'react'
import { Page } from '../../components'

class Support extends Component {
	
	componentDidMount() {
		const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.small.chat/TRL586P2NGRYEV5LN8.js";
    document.head.appendChild(script);
	}
	
	
	render() {
		return (
			<Page>
				<h1>Support</h1>
			</Page>
		)
	}
}

export default Support;
