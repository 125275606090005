import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Page, Skeleton, InvoiceList, SmallSelect, ScrollToTop, InvoiceTrash, Icon } from '../../components'
import Header from './header'
import { invoiceActions, invoiceSelectors } from '../../redux'

import { Helmet } from 'react-helmet'

// const FilterButton = props => {
// 	const { title, filter, filterBy, setFilter } = props;
	
// 	let isActive = false;
	
// 	Object.keys(filter).forEach(x => {
// 		if(filter[x].length === 0 && filterBy[x].length === 0) {
// 			isActive = true;
// 		} else {
// 			filter[x].forEach(y => {
// 				if(filterBy[x].indexOf(y) !== -1) isActive = true;
// 			})
// 		}
// 	})
	
// 	return (
// 		<button 
// 			className={["block px-1 rounded hover:text-black hover:no-underline hover:bg-gray-300", isActive ? "bg-gray-300" : ""].join(' ')} 
// 			onClick={() => setFilter(filter)}>
// 				<span>{title}</span>
// 		</button>
// 	)
// }

class Invoices extends Component {
	state = {
		selection: []
	}
	
	onUpdateSelection = selection => {
		this.setState({
			selection: selection
		})
	}
	
	render() {
		const { list, user, files, listTrash, loading, filterBy, sortBy } = this.props;
		
		const { onUpdateInvoice, onDeleteInvoice, setFilter, setSort } = this.props;
		const { selection } = this.state;
		
		const limit = 250;
		const isTrash = filterBy.trash.indexOf(true) !== -1;
		
		const years = [{value: 'auto', label: "Aktuelles Jahr", color: 'var(--green-300)'}, {value: 2023, label: "2023"}, {value: 2022, label: "2022"}, {value: 2021, label: "2021"}, {value: 2020, label: "2020"}, {value: 2019, label: "2019"}],
			months = [{value: 'auto', label: "Aktueller Monat", color: 'var(--green-300)'}, {value: 0, label: "Januar"}, {value: 1, label: "Februar"}, {value: 2, label: "März"}, {value: 3, label: "April"}, {value: 4, label: "Mai"}, {value: 5, label: "Juni"}, {value: 6, label: "Juli"}, {value: 7, label: "August"}, {value: 8, label: "September"}, {value: 9, label: "Oktober"}, {value: 10, label: "November"}, {value: 11, label: "Dezember"}],
			stadiums = [
				{value: 1, label: <div className="flex items-center gap-2"><div className="w-5 h-5"><Icon icon="status1" svgClassName="w-full h-full" /></div><span>Entwurf</span></div>}, 
				{value: 2, label: <div className="flex items-center gap-2"><div className="w-5 h-5"><Icon icon="status5" svgClassName="w-full h-full" /></div><span>Offen</span></div>}, 
				{value: 3, label: <div className="flex items-center gap-2"><div className="w-5 h-5"><Icon icon="status3" svgClassName="w-full h-full" /></div><span>Bezahlt</span></div>}, 
				{value: 4, label: <div className="flex items-center gap-2"><div className="w-5 h-5"><Icon icon="status4" svgClassName="w-full h-full" /></div><span>Storniert</span></div>}
			];
			
		const displayedList = list.slice(0, limit),
			displayedTrashList = listTrash.slice(0, limit);
			
		return (
			<Page>
				<ScrollToTop />
				<Helmet>
					<title>Rechnungen | Billu</title>
				</Helmet>
				<div>
					<Header trash={isTrash} setFilter={this.props.setFilter} selection={selection} onUpdateSelection={this.onUpdateSelection} onDeleteInvoice={onDeleteInvoice} onUpdateInvoice={onUpdateInvoice} user={user} files={files} invoices={list} />
					{!isTrash &&
						<div className="w-full flex  mb-6">
							<SmallSelect
								className="w-1/3" 
								defaultValue={years.filter(x => filterBy.year.indexOf(x.value) !== -1)}
								onChange={val => {
									if(val) {
										setFilter({ year: val.map(x => x.value) });
									} else {
										setFilter({ year: [] });
									}
								}}
								options={years}
								isMulti={true} 
								placeholder="Alle Jahre" />
								
							<SmallSelect
								className="w-1/3 ml-3" 
								defaultValue={months.filter(x => filterBy.month.indexOf(x.value) !== -1)}
								onChange={val => {
									if(val) {
										setFilter({ month: val.map(x => x.value) });
									} else {
										setFilter({ month: [] });
									}
								}}
								options={months}
								isMulti={true} 
								placeholder="Alle Monate" />
									
							<SmallSelect
								className="w-1/3 ml-3" 
								defaultValue={stadiums.filter(x => filterBy.status.indexOf(x.value) !== -1)}
								onChange={val => {
									if(val) {
										setFilter({ status: val.map(x => x.value) });
									} else {
										setFilter({ status: [] });
									}
								}}
								// defaultValue={[stadiums[0], stadiums[2]]}
								options={stadiums}
								isMulti={true} 
								placeholder="Alle Stadien" />
							{/*<button onClick={() => setFilter({ year: [], status: [] })}>Filter zurücksetzen</button>*/}
						</div>
					}
					
					{loading ? (
						<Skeleton rows={10}/>
					) : (
						!isTrash ? 
							<InvoiceList 
								list={displayedList} 
								count={list.length}
								limit={limit}
								user={user}
								files={files}
								loading={loading}
								onUpdateInvoice={onUpdateInvoice}
								actions={{setFilter, setSort}}
								sortBy={sortBy}
								showSortHeader={true}
								showSelection={true}
								showFooter={true}
								showCreateNewLink={true}
								selection={this.state.selection}
								onUpdateSelection={this.onUpdateSelection}
								/> :
							<InvoiceTrash
								list={displayedTrashList} 
								count={listTrash.length}
								limit={limit}
								loading={loading}
								onUdpateInvoice={onUpdateInvoice}
								onDeleteInvoice={onDeleteInvoice}
								selection={this.state.selection}
								onUpdateSelection={this.onUpdateSelection} 
								/> 
					)}
				</div>
				<div></div>
			</Page>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		list: invoiceSelectors.getInvoicesList(state),
		listTrash: invoiceSelectors.getInvoicesList(state, { year: [], month: [], status: [], trash: [true] }, {}),
		loading: state.invoices.loading,
		filterBy: state.invoices.filterBy,
		sortBy: state.invoices.sortBy,
		files: state.files,
		user: state.auth.user
	}
}

const mapDispatchToProps = {
	onUpdateInvoice: invoiceActions.updateInvoice,
	onDeleteInvoice: invoiceActions.deleteInvoice,
	setFilter: invoiceActions.setFilter,
	setSort: invoiceActions.setSort,
}


export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
