import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'

import { invoiceActions, invoiceSelectors, templateSelectors } from '../../redux'
import { prepareInvoice, path } from '../../helpers'
import { LoadingIndicator } from '../../components'
import { loadTemplate } from '../../templates'

class InvoiceCreator extends Component {
	state = {
		creating: false
	}
	
	constructor(props) {
		super(props);
		this.createInvoice = this.createInvoice.bind(this);
	}
	
	componentDidMount() {
		this.createInvoice();
	}
	
	componentDidUpdate() {
		this.createInvoice();
	}
	
	async createInvoice() {
		const { invoices, auth, duplicateInvoiceData, query, action, templates, template } = this.props;
		
		if(templates.initiated && invoices.initiated && !invoices.loading && !this.state.creating && !auth.loading) {
			this.setState({ creating: true })
			let invoice = {};
			
			const { status, invoicedate, exported, ...duplicateInvoice } = duplicateInvoiceData;

			let templateFile = { defaults: {} };
			
			try {
				templateFile = await loadTemplate(template.file, template) 
			} catch(error) {
				console.error(error)
			}
				
			if (action === 'duplicate' && duplicateInvoice) {
				invoice = prepareInvoice({
					...duplicateInvoice,
					...query,
					title: `${duplicateInvoice.title} – Kopie`
				});
			} else if (action === 'cancel' && duplicateInvoice) {
				invoice = prepareInvoice({
					...duplicateInvoice,
					title: duplicateInvoice.title + " - Storno",
					headline: "Stornorechnung",
					body: `${duplicateInvoice.body}
						\n Original Rechnungsnummer: ${duplicateInvoice.invoicenumber}
						\n Rechnungsdatum: ${duplicateInvoice.invoicedate}`
				});
			} else {
				invoice = prepareInvoice({
					...templateFile.defaults,
					...template,
					title: "",
					...query,
				})
			}
			
			
			this.props.onAddInvoice(invoice).then(({ id }) => {
				this.props.history.push(path('invoices', 'edit', id));
				// this.props.history.push(path('edit', id));
			})
		}
	}

	render() {
		return <React.Fragment>
			<Helmet>
				<title>Billu: Rechnung erstellen</title>
			</Helmet>
			<LoadingIndicator />
		</React.Fragment>;
	}
}


const mapStateToProps = (state, props) => {
	// let duplicateId = props.location.state && props.location.state.id;
	
	const { id: duplicateId, action, ...query } = queryString.parse(props.location.search || "");
	
	return {
		invoices: state.invoices, 
		auth: state.auth,
		duplicateInvoiceData: (action === "duplicate" || action === "cancel") ? invoiceSelectors.getInvoiceById(state, duplicateId) : {},
		templates: state.templates,
		template: templateSelectors.getSelectedTemplate(state),
		query,
		action
	}
}

const mapActionsToProps = {
	onAddInvoice: invoiceActions.addInvoice,
	onUpdateInvoice: invoiceActions.updateInvoice
}

export default connect(mapStateToProps, mapActionsToProps)(InvoiceCreator);
