import React from 'react'
import { connect } from 'react-redux'
import { invoiceSelectors } from '../../redux'
import { Page, ScrollToTop } from '../../components'
import Button, { COLORS, SIZES, TYPES } from '../../components/button'
import { numberToEuro, getLastFileDownload, downloadLink, api } from '../../helpers'
import tableStyles from '../../styles/tables.module.scss'
import { Helmet } from 'react-helmet'
import toast from 'react-hot-toast'
import dayjs from "dayjs"

const Statements = props => {
	const { invoices, files } = props;
	const { list } = invoices;
	
	const sortedYears = list
		.filter(x => !!x.exported)
		.reduce((acc, x) => {
			if(x.tags && x.tags.indexOf("exclude") !== -1) return acc;
			
			let year = new Date(x.invoicedate).getFullYear(),
				month = new Date(x.invoicedate).getMonth()
			
			acc[year] = acc[year] || {};
			acc[year][month] = acc[year][month] || {};
			acc[year][month].invoices = [x, ...acc[year][month].invoices || []];
			acc[year][month].total = (acc[year][month].total || 0) + x.total;
			return acc;
		}, {})
		
	
	// console.log(Object.values(sortedYears[2022]).reduce((acc, x) => {
	// 	console.log(acc, x);
	// }, 0))
	
	return <Page>
		<ScrollToTop />
		<Helmet>
			<title>Monatsübersicht | Billu</title>
		</Helmet>
		<div className="pb-20">
			<h1 className="m-0">Monatsübersicht</h1>
			
			<div className="flex my-4 gap-3">
				<Button
					text="Zurück zur Liste"
					icon="arrowLeft"
					link="/invoices"
					type={TYPES.GHOST} 
					size={SIZES.SMALL}
					className="mt-0"
					/>
			</div>
			
			<table className="mt-10">
				<thead>
					<tr>
						<th><span>Jahr</span></th>
						<th className="text-right pr-10"><span>Rechnungen</span></th>
						<th className={tableStyles.Amount}><span>Betrag</span></th>
						<th></th>
					</tr>
				</thead>
				{Object.keys(sortedYears).sort((x,y) => y - x).map((x, i) => <>
					<thead>
						<tr className="font-medium">
							<td><span><h3 className="mt-2 mb-0">{x}</h3></span></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
					</thead>	
					<tbody className="text-gray-800">
						{Object.keys(sortedYears[x]).sort((x, y) => y - x).map((y, i) => <tr className="text-gray-600" key={i}>
							<td className="">{dayjs().month(y).format("MMMM")}</td>
							<td className="text-right pr-10">{sortedYears[x][y].invoices.length}</td>
							<td className={[tableStyles.Amount, "text-gray-600"].join(' ')}>
								<span>{numberToEuro(sortedYears[x][y].total)}</span>
							</td>
							<td className={tableStyles.Actions}>
								<div>
									<Button 
										text="Dateien herunterladen"
										icon="download"
										action={() => {
											const invoicesWithFiles = sortedYears[x][y].invoices.filter(x => [0,1,4].indexOf(x.status) === -1);
											const downloadFiles = invoicesWithFiles.map(x => getLastFileDownload(files, x.id)).filter(x => x)
											console.log("invoices", invoicesWithFiles, downloadFiles.map(x => x.id))
											
											const fileDownlaod = api('/download/zip', 'post', { files: downloadFiles.map(x => x.id) })
												.then(res => {
													return res.blob().then((data) => {
														return {
															data: data,
															filename: res.headers.get('Content-Disposition'),
														};
													});
										    })
										    .then(({ data, filename }) => {
										        const blob = new Blob([data], { type: 'application/zip' });
										        const downloadUrl = URL.createObjectURL(blob);
										        
										        downloadLink(downloadUrl, filename.split("=")[1]);
										        console.log("done");
										    })
												.catch(err => console.error)
											toast.promise(fileDownlaod, {
												loading: "Dateien werden zum Download vorbereitet",
												success: "Dateien heruntergeladen",
												error: "Fehler beim herunterladen"
											})
										}}
										color={COLORS.PRIMARY}
										size={SIZES.SMALL}
										className={["m-0"]}/>
								</div>
							</td>
						</tr>)}
						<tr className="text-gray-500">
							<td className="">Gesamt</td>
							<td className="text-right pr-10"><span>{Object.values(sortedYears[x]).reduce((acc, y) => acc + y.invoices.length, 0)}</span></td>
							<td className={tableStyles.Amount}><span>{numberToEuro(Object.values(sortedYears[x]).reduce((acc, y) => acc + y.total, 0))}</span></td>
							<td></td>
						</tr>
					</tbody>
				</>)}
			</table>
		</div>
	</Page>
}

const mapStateToProps = (state, props) => {
	return {
		files: state.files,
		invoices: {
			...state.invoices,
			list: invoiceSelectors.getInvoicesList(state, { year: [], month: [], status: [2,3,5], trash: [false] }, {}),
		}
	}
}

export default connect(mapStateToProps)(Statements);
