import React from 'react'
import { autoHeightTextarea } from '../../helpers'

const handleBlur = (e, format, changePositions, row) => {
	e.target.value = format(e.target.value || "", row);
	
	if(e.target.tagName === "TEXTAREA") {
		autoHeightTextarea(e.target);
	}
	
	changePositions(e, row);
}

const handleFocus = e => e.target.select();

const handleKeyPress = e => {
	if(e.key === "Enter") {
		if(e.target.tagName !== "TEXTAREA") e.preventDefault()
	}
}

const PositionField = props => {
	const { type = 'input', name, placeholder, className = [], defaultValue, rows, show = true, format = x => x, disabled = false, value } = props;
	const { row, changePositions } = props;
	
	if(!show || !name) return null;
	
	const sharedProps = {
		className: [...className].join(' '),
		name: name,
		'data-id': row.id,
		placeholder: placeholder,
		onBlur: e => handleBlur(e, format, changePositions, row),
		onKeyPress: handleKeyPress,
		onFocus: handleFocus,
		key: name,
		disabled: disabled
	}
	
	value !== undefined ? sharedProps.value = format(row[name] || value, row) : sharedProps.defaultValue = format(row[name] || defaultValue, row);
	
	switch (type) {
		case 'input':
			return <input 
				type="text" 
				data-type={type}
				{...sharedProps} />
				
		case 'number':
			return <input 
				type="text" 
				data-type={type}
				{...sharedProps} />
		
		case 'textarea':
			return <textarea
				rows={rows}
				data-type={type}
				{...sharedProps} />
			
		default:
			return null;	
	}
}

export { PositionField } 
