import React, { Component } from 'react'
import { Modal } from '../../components'

class ConfirmModal extends Component {
	state = {
		show: true
	}
	
	render() {
		const { children } = this.props;
		
		return <Modal
			closeOnDocumentClick={false}
			width="md">
			{children}
		</Modal>
	}
}

export default ConfirmModal;
