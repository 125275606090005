import React from 'react'
import { Icon } from '../'


const SortHeader = props => {
	const { title, field, sortBy, setSort } = props;
	
	return (
		<button 
			className={["flex items-center w-full outline-none hover:text-theme-500 select-none", sortBy.field === field ? "text-gray-700" : ""].join(" ")} 
			onClick={() => setSort({
				field: field, 
				direction: sortBy.field !== field ? 1 : (sortBy.direction || 1) * -1
			})}>
			
			<span className="leading-snug">{title}</span>
			<Icon icon={sortBy.field === field ? sortBy.direction === 1 ? "arrowDown" : "arrowUp" : ""} className={["text-sm", sortBy.field === field ? "" : "text-gray-300"].join(" ")}/>
		</button>
	)
}

export default SortHeader;
