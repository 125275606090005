const API_BASE = process.env.NODE_ENV === 'development' ? 'http://files.localhost:3308' : 'https://files.billu.app';

const getPrivateDownloadLink = (key, token, filename, download = false) => {
	return `${API_BASE}/private/${encodeURIComponent(key)}/${encodeURIComponent(filename)}?token=${token}&download=${download}`;
}

const getPublicDownloadLink = shortlink => {
	return `https://files.billu.app/${shortlink}`;
}

const getLastFileDownloadLink = (files, user, invoiceid) => {
	if(!files || !user || !invoiceid) return null;
	
	const file = getLastFileDownload(files, invoiceid);
	
	if(!file) return null;
	
	return getPrivateDownloadLink(file.s3key, user.token, file.title, true);
}

const getLastFileDownload = (files, invoiceid) => {
	if(!files || !invoiceid) return null;
	
	const list = Object.values(files.list)
		.filter(x => x.invoiceid === invoiceid && x.intrash !== true )
		.sort((x, y) => new Date(y.created) - new Date(x.created));
		
	if(!list.length) return null;
	
	return list[0];
}

const downloadLink = (url, filename) => {
	const a = document.createElement("a");
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
}



export { getPrivateDownloadLink, getPublicDownloadLink, getLastFileDownloadLink, getLastFileDownload, downloadLink }
