import { formatAddress, germanDateToDate, numberToEuro, getPublicDownloadLink } from './';
import * as dayjs from 'dayjs'


const dateTags = date => ({
	'date.YY': {
		value: date.format('YY'),
		description: "Zweistellige Jahreszahl des aktuellen Datums",
		example: "22"
	},
	'date.YYYY': {
		value: date.format('YYYY'),
		description: "Vierstellige Jahreszahl des aktuellen Datums",
		example: "2022"
	},
	'date.M': {
		value: date.format('M'),
		description: "Monat ohne führende Null des aktuellen Datums",
		example: "1"
	},
	'date.MM': {
		value: date.format('MM'),
		description: "Monat mit führender Null des aktuellen Datums",
		example: "01"
	},
	'date.D': {
		value: date.format('D'),
		description: "Tag ohne führende Null des aktuellen Datums",
		example: "5",
	},
	'date.DD': {
		value: date.format('DD'),
		description: "Tag mit führender Null des aktuellen Datums",
		example: "05",
	},
	'date.H': {
		value: date.format('H'),
		description: "Stunde ohne führende Null (1–24) des aktuellen Datums",
		example: "5",
	},
	'date.HH': {
		value: date.format('HH'),
		description: "Stunde mit führender Null (1–24) des aktuellen Datums",
		example: "05",
	},
	'date.h': {
		value: date.format('h'),
		description: "Stunde ohne führende Null (1–12) des aktuellen Datums",
		example: "5",
	},
	'date.hh': {
		value: date.format('hh'),
		description: "Stunde mit führender Null (1–12) des aktuellen Datums",
		example: "05",
	},
	'date.m': {
		value: date.format('m'),
		description: "Minute ohne führende Null des aktuellen Datums",
		example: "8",
	},
	'date.mm': {
		value: date.format('mm'),
		description: "Minute mit führender Null des aktuellen Datums",
		example: "08",
	},
	'date.W': {
		value: date.format('W'),
		description: "Kalenderwoche (1–52) des aktuellen Datums",
		example: "5"
	},
	'date.full': {
		value: '{{date.D}}.{{date.MM}}.{{date.YYYY}}',
		description: "Deutsches Datumsformat (DIN 5008) des aktuellen Datums",
		example: "1.01.2022"
	}
})


const invoiceTags = (invoice, user = {}) => {
	const invoicedate = invoice.invoicedate ? germanDateToDate(invoice.invoicedate) : false;
	// console.log(invoice.invoicedate, invoicedate);
	
	return {
		'#': {
			value: invoice.invoicenumber ? invoice.invoicenumber : false,
			description: "Rechnungsnummer mit einer Stelle",
			example: "1"
		},
		'##': {
			value: invoice.invoicenumber ? invoice.invoicenumber.toString().padStart(2, 0) : false,
			description: "Rechnungsnummer mit 2 Stellen",
			example: "01"
		},
		'###': {
			value: invoice.invoicenumber ? invoice.invoicenumber.toString().padStart(3, 0) : false,
			description: "Rechnungsnummer mit 3 Stellen",
			example: "001"
		},
		'####': {
			value: invoice.invoicenumber ? invoice.invoicenumber.toString().padStart(4, 0) : false,
			description: "Rechnungsnummer mit 4 Stellen",
			example: "0001"
		},
		'#####': {
			value: invoice.invoicenumber ? invoice.invoicenumber.toString().padStart(5, 0) : false,
			description: "Rechnungsnummer mit 5 Stellen",
			example: "00001"
		},
		'######': {
			value: invoice.invoicenumber ? invoice.invoicenumber.toString().padStart(6, 0) : false,
			description: "Rechnungsnummer mit 6 Stellen",
			example: "000001"
		},
		'#######': {
			value: invoice.invoicenumber ? invoice.invoicenumber.toString().padStart(7, 0) : false,
			description: "Rechnungsnummer mit 7 Stellen",
			example: "0000001"
		},
		'invoice.index': {
			value: invoice.invoicenumber ? invoice.invoicenumber : false,
			description: "gleich wie {{#}}",
			example: "1",
		},
		'invoice.number': {
			value: user.invoicenumberformat ? user.invoicenumberformat : '{{##}}',
			description: "Rechnungsnummer (Formatiert nach deiner Formatvorlage)	",
			example: "",
		},
		'invoice.date': {
			value: invoice.invoicedate ? invoice.invoicedate : false,
			description: "Datum der Rechnung",
			example: "03.05.2020",
		},
		'invoice.deadline': {
			value: invoice.deadline ? invoice.deadline : false,
			description: "Zahlungsfrist der Rechnung (in Tagen)",
			example: "14",
		},
		'invoice.YY' : {
			value: invoicedate ? invoicedate.format("YY") : false,
			description: "Zweistellige Jahreszahl der Rechnung",
			example: "20",
		},
		'invoice.YYYY' : {
			value: invoicedate ? invoicedate.format("YYYY") : false,
			description: "Vierstellige Jahreszahl der Rechnung",
			example: "2020",
		},
		'invoice.M' : {
			value: invoicedate ? invoicedate.format("M") : false,
			description: "Monat ohne führende Null der Rechnung",
			example: "4",
		},
		'invoice.MM' : {
			value: invoicedate ? invoicedate.format("MM") : false,
			description: "Monat mit führender Null der Rechnung",
			example: "04",
		},
		'invoice.D' : {
			value: invoicedate ? invoicedate.format("D") : false,
			description: "Tag ohne führende Null der Rechnung",
			example: "1",
		},
		'invoice.DD': {
			value: invoicedate ? invoicedate.format("DD") : false,
			description: "Tag mit führender Null der Rechnung",
			example: "01",
		},
		// 'invoice.H' : {
		// 	value: invoicedate ? invoicedate.format("H") : false,
		// 	description: "",
		// 	example: "",
		// },
		// 'invoice.HH' : {
		// 	value: invoicedate ? invoicedate.format("HH") : false,
		// 	description: "",
		// 	example: "",
		// },
		// 'invoice.h' : {
		// 	value: invoicedate ? invoicedate.format("h") : false,
		// 	description: "",
		// 	example: "",
		// },
		// 'invoice.hh' : {
		// 	value: invoicedate ? invoicedate.format("hh") : false,
		// 	description: "",
		// 	example: "",
		// },
		// 'invoice.m' : {
		// 	value: invoicedate ? invoicedate.format("m") : false,
		// 	description: "",
		// 	example: "",
		// },
		// 'invoice.mm' : {
		// 	value: invoicedate ? invoicedate.format("mm") : false,
		// 	description: "",
		// 	example: "",
		// },
		'invoice.W': {
			value: invoicedate ? invoicedate.format("W") : false,
			description: "Kalenderwoche (1–52) der Rechnung",
			example: "20",
		},
		'invoice.title': {
			value: invoice.title ? invoice.title : false,
			description: "Titel der Rechnung",
			example: "",
		},
		'invoice.total': {
			value: invoice.total ? numberToEuro(invoice.total) : false,
			description: "Gesamtsumme der Rechnung",
			example: "100 €",
		}
		//'invoice.duedate': invoice.duedate,
		//'invoice.subtutal': invoice.subtutal,
		//'invoice.tax': invoice.tax,
		//'invoice.page': invoice.page,
		//'invoice.pagetotal': invoice.pagetotal
	}
}

const customerTags = customer => ({
	'customer.id': {
		value: customer.customerid,
		description: "Kundennummer",
		example: "",
	},
	'customer.title': {
		value: customer.title,
		description: "Titel des Kunden",
		example: "",
	},
	'customer.email': {
		value: customer.email,
		description: "E-Mail des Kunden",
		example: "",
	},
	'customer.phone': {
		value: customer.tel,
		description: "Telefonnummer des Kunden",
		example: "",
	},
	'customer.company': {
		value: customer.company,
		description: "Firmenname des Kunden",
		example: "",
	},
	'customer.firstname': {
		value: customer.firstname,
		description: "Vorname des Kunden",
		example: "",
	},
	'customer.lastname': {
		value: customer.lastname,
		description: "Nachname des Kunden",
		example: "",
	},
	'customer.addresslineone': {
		value: customer.addresslineone,
		description: "Straße und Hausnummer des Kunden",
		example: "",
	},
	'customer.addresslinetwo': {
		value: customer.addresslinetwo,
		description: "Adressezeile 2 des Kunden",
		example: "",
	},
	'customer.zipcode': {
		value: customer.postcode,
		description: "Postleitzahl des Kunden",
		example: "",
	},
	'customer.city': {
		value: customer.city,
		description: "Stadt des Kunden",
		example: "",
	},
	'customer.country': {
		value: customer.country,
		description: "Land des Kunden",
		example: "",
	},
	'customer.address': {
		value: formatAddress(customer),
		description: "Komplette Anschrift des Kunden",
		example: "",
	}
})


const userTags = user => ({
	'user.company': {
		value: user.company,
		description: "Dein Firmenname",
		example: "Firma 123",
	},
	'user.firstname': {
		value: user.firstname,
		description: "Dein Vorname",
		example: "Vorname",
	},
	'user.lastname': {
		value: user.lastname,
		description: "Dein Nachname",
		example: "Nachname",
	},
	'user.addresslineone': {
		value: user.addresslineone,
		description: "Deine Straße und Hausnummer",
		example: "Straße 123",
	},
	'user.addresslinetwo': {
		value: user.addresslinetwo,
		description: "Deine Adressezeile 2",
		example: "c/o Nachname",
	},
	'user.zipcode': {
		value: user.postcode,
		description: "Deine Postleitzahl",
		example: "12345",
	},
	'user.city': {
		value: user.city,
		description: "Deine Stadt",
		example: "Berlin",
	},
	'user.email': {
		value: user.email,
		description: "E-Mail-Adresse deines Kontos",
		example: "email@example.com",
	},
	'user.contactemail': {
		value: user.contactemail,
		description: "Deine Kontakt-E-Mail-Adresse",
		example: "kontakt@example.com",
	},
	'user.phone': {
		value: user.tel,
		description: "Deine Telefonnummer",
		example: "",
	},
	'user.website': {
		value: user.website,
		description: "Deine Webseite",
		example: "www.example.com",
	},
	'user.iban': {
		value: user.iban,
		description: "Deine IBAN",
		example: "",
	},
	'user.bic': {
		value: user.bic,
		description: "Deine BIC",
		example: "",
	},
	'user.taxnumber': {
		value: user.taxnumber,
		description: "Deine Steuernummer",
		example: "12/345/67890",
	},
	'user.vatid': {
		value: user.vatid,
		description: "Deine Umsatzsteuer-ID",
		example: "DE999999999",
	},
	'user.accountholder': {
		value: user.accountholder,
		description: "Kontoinhaber",
		example: "",
	},
	'user.accountbank': {
		value: user.accountbank,
		description: "Name der Bank",
		example: "",
	},
	'user.address': {
		value: formatAddress(user),
		description: "Deine komplette Anschrift",
		example: `Vorname Nachname
Straße 100
Zeile 2
12345 Stadt`,
	}
})

const fileTags = file => ({
	'file.title': {
		value: file.title,
		description: "Titel der Datei",
		example: "Rechnung 123.pdf",
	},
	'file.link': {
		value: getPublicDownloadLink(file.shortlink),
		description: "Download-Link der Datei",
		example: "https://files.billu.app/b51f5313aeaef031",
	},
})


export const getTags = () => {
	const userData = userTags({}),
		dateData = dateTags(dayjs()),
		invoiceData = invoiceTags({}),
		customerData = customerTags({}),
		fileData = fileTags({});
		
	const user = {},
		date = {},
		invoice = {},
		customer = {},
		file = {};
	
	[[user, userData], [date, dateData], [invoice, invoiceData], [customer, customerData], [file, fileData]].forEach(x => {
		Object.keys(x[1]).forEach(y => {
			x[0][y] = {
				description: x[1][y].description,
				example: x[1][y].example
			}
		})
	})
	
	return {
		user,
		date,
		invoice,
		customer,
		file
	}
}

export const tags = (string, values = {}) => {
	let data = {};
	
	Object.keys(values).forEach(x => {
		if(values[x] && x === 'customer') data = { ...data, ...customerTags(values[x])}
		if(values[x] && x === 'invoice') data = { ...data, ...invoiceTags(values[x], values.user)}
		if(values[x] && x === 'user') data = { ...data, ...userTags(values[x])}
		if(values[x] && x === 'file') data = { ...data, ...fileTags(values[x])}
		data = { ...data, ...dateTags(dayjs())}
	})
	
	const replacedTags = replaceTags({ string }, data);
	return replacedTags;
}

const replaceTags = ({ string, missing = []}, data) => {
	if(!string || !data) return { string, missing};
	
	// console.log(string, missing);
	// important: check if tag contains itself
	
	// let matches = string.match(/(?<=\{\{).+?(?=\}\})/g);
	let matches = string.match(/(\{\{).+?(\}\})/g);
	
	if(!matches) return { string, missing };
	
	
	if(matches && matches.length) {
		matches.forEach(x => {
			let matchContent = x.substring(2).slice(0, -2);
			if(data[matchContent] && data[matchContent].value) {
				string = string.replace(x, data[matchContent].value);
			} else {
				// string = string.replace(x, `[${matchContent}]`);
				string = string.replace(x, "");
				missing.push(matchContent);
			}	
		})
		return replaceTags({ string, missing }, data);
	}
}
