import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'


import { PageEditor, /*, FormField, FormFieldSelect, FormFieldStatus, ContextMenu,*/ LoadingIndicator, RouterPrompt, Icon, Modal } from '../../components'
import Button, { COLORS, /*TYPES,*/ SIZES } from '../../components/button'
import { Form } from 'react-final-form'
import { InvoiceEditorSidebar } from './sidebar'
import { loadTemplate } from '../../templates'
import { Helmet } from 'react-helmet'

import styles from './styles.module.scss'

import { invoiceActions, invoiceSelectors, fileActions, fileSelectors, customerSelectors, authSelectors, authActions, templateSelectors } from '../../redux'
import { prepareInvoice, scalePage, tags, germanDate, path, testCommandKey, utcToLocal } from '../../helpers/'

const convertInvoiceDate = date => date ? germanDate(utcToLocal(new Date(date))) : null; //germanDate(new Date())

class InvoiceEditor extends Component {
	state = { 
		mounted: false,
		initalized: false,
		data: {},
		template: {
			loading: true
		},
		pagePreviewMode: false,
		exportData: {},
		previewValues: {}
	}
	submitFormFunction = {};
	form = null;
	
	constructor(props) {
		super(props);
		
		this.onUpdateInvoice = this.onUpdateInvoice.bind(this);
		this.onUpdateUser = this.onUpdateUser.bind(this);
		this.onSaveForm = this.onSaveForm.bind(this);
		this.onCreatePdf = this.onCreatePdf.bind(this);
		this.togglePagePreviewMode = this.togglePagePreviewMode.bind(this);
		this.callPdfExport = this.callPdfExport.bind(this);
		this.prepareExportData = this.prepareExportData.bind(this);
		this.changeFilename = this.changeFilename.bind(this);
		this.setPreviewValues = this.setPreviewValues.bind(this);
		this.loadTemplateFile = this.loadTemplateFile.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
		this.loadTemplateFile();
		
		// const { template } = this.props;
		
		// loadTemplate(template.template).then(template => {
		// 	this.setState({ template: {
		// 		loading: false,
		// 		...template
		// 	}})
		// })
		// .catch(error => {
		// 	this.setState({ template: {
		// 		loading: false,
		// 		error: error
		// 	}})
		// 	console.error(error)
		// })
		
		
		
		document.addEventListener("keydown", this.keyboardSubmitForm, false);
	}
	
	loadTemplateFile() {
		const { template, templates } = this.props;
		if(templates.loading || !templates.initiated) return false;
		
		if(this.state.mounted !== true) {
			this.setState({ mounted: true });
			
			if(template && template.file) {
				loadTemplate(template.file)
					.then(templateFile => {
						this.setState({ 
							template: {
								loading: false,
								currency: template.currency,
								...templateFile
								}
						})
					})	
					.catch(console.error)
			} else {
				this.setState({ 
					mounted: true,
					template: {
						loading: false,
					}
				})
			}
		}	
	}
	
	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyboardSubmitForm, false);
	}
	
	componentDidUpdate() {
		this.initForm(this.props);
		this.loadTemplateFile();
	}
	
	togglePagePreviewMode(val) {
		if(val ? val : !this.state.pagePreviewMode) {
			this.submitFormFunction()
				.then(() => {
					this.prepareExportData();
					
					this.setState({
						pagePreviewMode: true,
						exportData: {
							...this.state.exportData, 
						}
					})
				})
		} else {
			this.setState({
				pagePreviewMode: false
			})
		}
	}
	
	changeFilename(e) {
		this.setState({
			exportData: {
				...this.state.exportData,
				filename: e.target.value.replace(/[/\\]/g, "")
			}
		})
	}
	
		
	// async loadTemplate(templateName) {
	// 	const { default: template } = await import(`../../templates/${templateName}`);
	// 	this.setState({ template: {
	// 		loading: false,
	// 		...template
	// 	}})
	// }
	
	initForm(props) {
		if(!this.state.initalized && !props.loading && props.invoice) {
			const { invoicedate, ...fields } = props.invoice;
			
			this.setState({
				initalized: true,
				data: {
					...fields,
					invoicedate: convertInvoiceDate(invoicedate)
				}
			})
		}
	}
	
	keyboardSubmitForm(e) {
		if(testCommandKey(e) && e.keyCode === 83) {
			e.preventDefault();
			// this.submitFormFunction();
			document.getElementById('invoiceEditorForm')
				.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}
	
	onSaveForm(values, form) {
		if(form.getState().dirty) {
			const dirtyFields = form.getState().dirtyFields;
			let changedFields = {};
			
			Object.keys(dirtyFields).forEach(x => {
				changedFields[x] = values[x];
			})
			return this.onUpdateInvoice(changedFields);
		} else {
			return Promise.resolve(true);
		}
	}
	
	onUpdateUser(values) {
		return new Promise((resolve, reject) => {
			this.props.onUpdateUser(values)
				.then(resolve(values));
		})
	}
	
	onUpdateInvoice(values) {		
		let preparedValues = prepareInvoice({...values});
		
		return new Promise(resolve => {
			if(!Object.keys(preparedValues).length) return resolve();
			
			this.props.onUpdateInvoice(this.props.invoice.id, preparedValues)
				.then(values => {
					if(!values.error) {
						this.setState({
							data: {
								...values,
								invoicedate: convertInvoiceDate(values.invoicedate)
							}
						})
					} 
				})
				.then(() => resolve())
		})
	}
	
	prepareExportData() {
		// if(this.props.invoice.exported) return;

		const { user, customers, invoice } = this.props;
		const { invoicenumber, invoicedate, deadline } = invoice;
		
		let newInvoiceNumber = invoicenumber ? invoicenumber : user.currentinvoicenumber + 1,
			newInvoiceDate = convertInvoiceDate(invoicedate || new Date()),
			formattedInvoiceNumer = tags(user.invoicenumberformat, { invoice: { invoicenumber: newInvoiceNumber, invoicedate: newInvoiceDate }}).string,
			newFileName = this.getExportFilename(
				user, 
				{
					...invoice,
					invoicenumber: newInvoiceNumber, 
					invoicedate: newInvoiceDate
				},
				customers.list.find(x => x.id === this.props.invoice.customerid)
			);

		// console.log('hier', newInvoiceNumber, formattedInvoiceNumer);
		
		this.setState({
			exportData: {
				invoicenumber: newInvoiceNumber,
				invoicedate: newInvoiceDate,
				filename: newFileName,
				deadline: deadline,
				formattedInvoiceNumer: formattedInvoiceNumer
			}
		})
	}
	
	getExportFilename(user = {}, invoice = {}, customer = {}) {
		return tags(user.filenameformat ? user.filenameformat : "Rechnung {{invoice.number}}", { invoice, user, customer }).string
	}
	
	setPreviewValues(values) {
		this.setState({
			previewValues: values
		})
	}
	
	onCreatePdf(e) {
		const { user } = this.props;
		const { id, exported, filename: invoiceFilename } = this.props.invoice;
		const { filename } = this.state.exportData;
		
		const newFilename = invoiceFilename ? {} : { filename };
		
		if(!exported) {
			// check if invoicenumber auto mode is one
			// -> increase user.currentinvoicenumber
			const { exportData } = this.state;
			
			Promise.all([
				this.onUpdateUser({
					currentinvoicenumber: exportData.invoicenumber
				}),
				this.onUpdateInvoice({
					exported: germanDate(new Date()),
					status: 2,
					invoicenumber: exportData.invoicenumber,
					invoicedate: exportData.invoicedate,
					...newFilename
				})
			])
				.then(([ updatedUser, updatedInvoice ]) => {
					this.callPdfExport(user, id, exportData.filename)
				})
		} else {
			this.onUpdateInvoice({
				...newFilename
			})
			.then(() => {
				this.callPdfExport(user, id, filename);
			})
		}
		
	}
	
	callPdfExport(user, id, filename) {
		this.props.onStartPdfExport()
		import("../../helpers/export-pdf").then(exportPDF => {
			let pdfstr = exportPDF.default(this.state.template, filename);
			if(pdfstr) {
				this.props.onCreatePdf( user, id, filename, pdfstr)
			}
			scalePage();
			this.togglePagePreviewMode();
		})
	}
	
	validate(values) {
  	const errors = {};
  	if(values.title && values.title.length > 80) errors.title = "Zu lang"
  
  	return errors;
  }
  
	render() {
		const { initalized, pagePreviewMode } = this.state;
		const { invoice, currentInvoiceNumber, loading, user, customers, history, template } = this.props;
		
		return <div className={styles.Editor}>
			<Helmet>
				<title>{this.props.invoice ? this.props.invoice.title || 'Rechnung: Kein Titel' : 'Laden …'}</title>
			</Helmet>
			{!loading && !invoice && <span>Diese Rechnung gibt es nicht mehr</span>}
			{/*!loading && invoice.intrash === true && <Redirect to='/invoices' />*/}
			{(loading || !initalized) && <LoadingIndicator />}
			
			{(!loading && invoice && invoice.intrash) && (
				<Modal width="md">
					<div className="flex align-center">
						<Icon icon="delete" className="text-red-500 mr-3 text-2xl"/>
						<h3 className="mt-0 mb-4">Rechnung kann nicht bearbeitet werden</h3>
					</div>
					<p>Diese Rechnung liegt im Papierkorb und kann nicht bearbeitet werden. <br/>Willst du sie wiederherstellen?</p>
					
					<div className="flex justify-between items-center mt-5">
						<Button 
							text="Zurück"
							link={path('invoices')}
							icon="arrowLeft"
							color={COLORS.SECONDARY}
							size={SIZES.MEDIUM}
							/>
						<Button
							text="Wiederherstellen"
							action={() => this.props.onUpdateInvoice(invoice.id, { intrash: false })}
							icon="restore"
							color={COLORS.PRIMARY}
							size={SIZES.LARGE}
							className={[""]} />
					</div>
				</Modal>	
			)}
			
			{(!loading && invoice && initalized) && (
				<Form
					onSubmit={this.onSaveForm}
					mutators={{
						setTotal: ([value], state, { changeValue }) => {
						  changeValue(state, "total", () => value)
						}
					}}
					initialValues={this.state.data}
					validate={this.validate}
					render={({submitError, handleSubmit, submitting, pristine, values, form }) => {
						this.submitFormFunction = handleSubmit;
						this.form = form;
						
						
						return <form 
							id="invoiceEditorForm"
							className={styles.Form}
							onSubmit={e => {
								handleSubmit(e)
									.then(() => form.reset(this.state.data))
							}}>
							
							<Helmet>
								<title>{this.props.invoice.title || 'Rechnung: Kein Titel'}{!pristine ? '*' : ''}</title>
							</Helmet>
								
							{!invoice.intrash ?			
								<InvoiceEditorSidebar {...this.props} 
									values={values}
									currentInvoiceNumber={currentInvoiceNumber}
									styles={styles}
									pagePreviewMode={pagePreviewMode}
									submitting={submitting}
									pristine={pristine}
									form={form} 
									onCreatePdf={this.onCreatePdf}
									togglePagePreviewMode={this.togglePagePreviewMode}
									changeFilename={this.changeFilename}
									exportData={this.state.exportData}
									getExportFilename={this.getExportFilename}
									previewValues={this.state.previewValues}/> : <div></div>
							}
							
							<div 
								className={[styles.Page, 
									pagePreviewMode ? "bg-gray-400" : "bg-gray-500", 
									invoice.intrash ? "opacity-50 pointer-events-none select-none" : ""].join(' ')}>	
								<div className={styles.PageScroller}>
									{this.state.template.loading || !this.state.initalized ? <LoadingIndicator /> : <React.Fragment>
										{pagePreviewMode ? <span key="1" className="absolute top-0 left-0 mt-4 ml-4 p-2 z-10 bg-green-400 text-green-900 rounded">Vorschau</span> : null}
										<PageEditor 
											key="2"
											invoice={invoice}
											currentInvoiceNumber={currentInvoiceNumber}
											user={user} 
											customer={customers.list.filter(x => x.id === values.customerid)[0]}
											values={values}
											defaultTaxRate={19}
											previewMode={pagePreviewMode}
											template={this.state.template}
											styles={this.state.template.styles}
											userStyles={template ? template.styles : null}
											setPreviewValues={this.setPreviewValues} 
											form={form} />
									</React.Fragment>}
								</div>
							</div>	
							
							<RouterPrompt 
								when={true}
								navigate={location => history.push(location)}
								shouldBlockNavigation={location => {
									if(!pristine) return true;
									return false;
								}} 
								action={() => this.submitFormFunction()} />
						</form>
					}} 
				/>
			)}
		</div>
	}
}

const mapStateToProps = (state, props) => {
	const { invoices, customers } = state;
	const { match } = props;
	const invoiceId = parseInt(match.params.id) || 0;
	
	const user = authSelectors.getUser(state);
	
	return {
		invoice: invoiceSelectors.getInvoiceById(state, invoiceId),
		currentInvoiceNumber: user.currentinvoicenumber,
		templates: state.templates,
		loading: invoices.loading,
		path: invoices.path,
		user: user,
		invoices: state.invoices,
		customers: {
			loading: customers.loading,
			error: customers.error,
			list: customerSelectors.getCustomersList(state)
		},
		files: {
			...state.files,
			list: fileSelectors.getFilesByInvoiceId(state, invoiceId)
		},
		template: templateSelectors.getSelectedTemplate(state)
	}
}

const mapActionsToProps = {
	onUpdateInvoice: invoiceActions.updateInvoice,
	onUpdateUser: authActions.updateUser,
	onCreatePdf: fileActions.addFile,
	onStartPdfExport: fileActions.startFileExport,
	onShareFile: fileActions.shareFile,
	onUpdateFile: fileActions.updateFile
}

export default connect(mapStateToProps, mapActionsToProps)(InvoiceEditor);
