import React, { Component } from 'react'
import { Form } from 'react-final-form'
import { FormField, RouterPrompt } from '../../../components'
import Button, { COLORS } from '../../../components/button'
// import { Prompt } from 'react-router'
import { tags } from '../../../helpers'

class AddressForm extends Component {	
	state = {
		initalized: false,
		data: {}
	}
	
	constructor(props) {
		super(props);
		
		this.update = this.update.bind(this);
	}
	
	componentDidMount() {
		this.initForm(this.props);
	}

	componentDidUpdate() {
		this.initForm(this.props);
	}
		
	initForm(props) {
		if(!this.state.initalized && !props.loading && props.data) {
			this.setState({
				initalized: true,
				data: props.data
			})
		}
	}
	
	update(values) {
		return new Promise((resolve, reject) => {
			this.props.update(values)
				.then(values => {
					this.setState({
						data: values
					})
				})
				.then(() => resolve())
		})
	}
	
	render() {
		return (
			<Form 
				onSubmit={this.update}
				initialValues={this.state.data}
				render={({ handleSubmit, values, valid, form, pristine, submitting }) => (
					<form onSubmit={e => {
							valid ?
								handleSubmit(e).then(() => form.reset(this.state.data)) : 
								handleSubmit(e); 
						}}>

						<FormField name="company" label="Firma" secondLabel="(optional)" tag="user.company" />
						<div className="flex -mx-3">
							<div className="w-1/2 px-3">
								<FormField name="firstname" label="Vorname" tag="user.firstname" />
							</div>
							<div className="w-1/2 px-3">
								<FormField name="lastname" label="Nachname" tag="user.lastname" />
							</div>
						</div>
						
						<FormField name="addresslinetwo" label="Adresszusatz" secondLabel="(optional)" placeholder="c/o, z. Hd., …"  tag="user.addresslineone" />
						<FormField name="addresslineone" label="Straße, Hausnummer" tag="user.addresslinetow" />
						{/*<Alert>button: Adresszusatz hinzufügen</Alert>*/}
						
						<div className="flex -mx-3">
							<div className="w-1/3 px-3">
								<FormField name="postcode" label="Postleitzahl" className="tnum" tag="user.zipcode" />
							</div>
							<div className="w-2/3 px-3">
								<FormField name="city" label="Stadt" tag="user.city" />
							</div>
						</div>
						
						<h4>So sieht's später aus:</h4>
						<div className="whitespace-pre bg-gray-300 rounded p-2">{tags("{{user.address}}", { user: values }).string}</div>

						<Button
							text="Speichern"
							action="submit"
							disabled={submitting || pristine}
							working={submitting}
							color={COLORS.PRIMARY}
							className="mt-6" />
						
						<RouterPrompt 
							when={true}
							navigate={location => this.props.history.push(location)}
							shouldBlockNavigation={location => {
								console.log(pristine)
								if(!pristine) return true;
								return false;
							}} 
							action={() => handleSubmit()} />
					</form>	
				)}
			/>
		)
	}
}

export default AddressForm;
