import React from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import { Icon } from '../../components'

export const COLORS = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	DANGER: 'danger'
}

export const SIZES = {
	SMALL: 'small', 
	MEDIUM: 'medium',
	LARGE: 'large'
}

export const TYPES = {
	BUTTON: 'button', 
	GHOST: 'ghost'
}

const Button = ({ link, target = null, action, text, icon, iconPosition, disabled, working, component, color, type, size, className}) => {
	
	let iconEl = null;
	if(icon) {
		if(iconPosition === "right") {
			iconEl = <Icon icon={icon} className="button-icon order-1 ml-2 -my-1"/>
		} else if(iconPosition === "only") {
			iconEl = <Icon icon={icon} className="button-icon  -my-1"/>
		} else {
			iconEl = <Icon icon={icon} className="button-icon mr-2 -my-1"/>
		}
	} 
	
	let labelEl = null;
	if(text) labelEl = <span className="button-label">{text}</span>;
	
	return (
		<div className={[
				"button",
				styles.Button,
				styles[type || TYPES.BUTTON],
				styles[color || COLORS.SECONDARY],
				styles[size || SIZES.MEDIUM],
				styles[link ? 'link' : null],
				styles[disabled ? 'disabled' : null],
				styles[working ? 'working' : null],
				className
			].join(' ')}
			onClick={typeof action === 'function' ? action : null} >
			
			{link ? (
				target ? (
					<a href={link} target={target} rel='noopener noreferrer' className="flex items-center justify-center h-full w-full no-underline">
						{iconEl}
						{labelEl}
					</a>
				) : (
					<Link to={link} className="flex items-center justify-center h-full w-full no-underline">
						{iconEl}
						{labelEl}
					</Link>	
				)
			) : (
				action === 'submit' ? (
					!disabled ?
						<input type="submit" value={text} /> :
						<span className="flex items-center justify-center h-full w-full">
							{iconEl}
							{labelEl}
						</span>
				) : (
					<span className="flex items-center justify-center h-full w-full">
						{iconEl}
						{labelEl}
					</span>
				)
			)}
		</div>
	)
}

export default Button;
