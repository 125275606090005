import React from 'react'
import tableStyles from '../styles/tables.module.scss'

const CropTable = props => {
	return <table className={tableStyles.CropTable}>
		<tbody>
			<tr>
				<td>
					{props.children}
				</td>
			</tr>
		</tbody>
	</table>
}

export { CropTable }
