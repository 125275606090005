import React, { Component } from 'react'
import { Checkbox } from '../../components'

class SelectionCheckbox extends Component {
	onSelectAll = (list, deselectAll) => {
		const { onUpdateSelection } = this.props;
	
		onUpdateSelection(
			deselectAll ? [] :
			list.reduce((x, y) => {
				x.push(y.id); return x;
			}, []) 
		)
	}
	
	onToggleSelection = id => {
		let { selection, onUpdateSelection } = this.props;
		
		var index = selection.indexOf(id);

		if (index === -1) {
			selection.push(id);
		} else {
			selection.splice(index, 1);
		}
		
		onUpdateSelection(selection);
	}
	
	render() {
		const { id, isHeader, selection, list } = this.props;
		
		if(isHeader) {
			return <Checkbox 
				checked={selection.length === list.length && list.length}
				deselectAll={selection.length < list.length && selection.length > 0}
				onChange={() => {
					if(!list.length) return false;
		
					if(selection.length === 0) {
						this.onSelectAll(list)
					} else if(selection.length <= list.length) {
						this.onSelectAll(list, true)
					}
				}}
				/>
		} else {
			return <Checkbox 
				checked={selection.indexOf(id) !== -1} 
				onChange={e => this.onToggleSelection(id)}
				/>
		}
	}
}

export default SelectionCheckbox;
