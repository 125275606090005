import React, { Component } from 'react'
import { Page, FormFieldPassword, LoadingIndicator, Alert, Icon } from '../../components'
import { api } from '../../helpers'
import Button, { COLORS, SIZES } from '../../components/button'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import createDecorator from 'final-form-focus'
import { FORM_ERROR } from 'final-form'
import queryString from 'query-string'


const focusOnError = createDecorator()

class ResetPassword extends Component {
	state = {
		loading: true,
		error: false,
		showPassword: false,
		passwordStrength: 0,
		user: {
			id: this.props.match.params.id,
			token: queryString.parse(this.props.location.search).token || false
		},
	}
	
	constructor(props) {
		super(props);
		this.onResetPassword = this.onResetPassword.bind(this);
		this.validate = this.validate.bind(this);
		
		this.verifyPasswordResetLink(this.state.user)
	}
	
  onResetPassword({ password }) {
  	const { user } = this.state;
  	
		return api('/auth/password/reset', 'put', { id: user.id, token: user.token, password })
			.then(res => res.json())
			.then(json => {
				if(json.error) {
					return { [FORM_ERROR]: `Etwas ist schief gelaufen (ERROR ${json.error.statusCode})` }
				} else {
					this.props.history.push({ 
						pathname: '/login', 
						message: 'Dein Passwort wurde geändert. Du kannst dich jetzt damit anmelden.'
					});
				}
			})
			.catch(console.error)
	}
	
	
  verifyPasswordResetLink({ id, token }) {
  	api('/auth/password/verifyResetToken', 'post', { id, token })
  		.then(res => res.json())
			.then(json => {
				let error = null;
				
				if(json.error) {
					if(json.error.name === "TokenExpiredError") {
						error = {
							status: 'expired',
							message: "Der Link ist abgelaufen"
						}
					} else {
						error = {
							status: 'unvalid',
							message: "Der Link ist ungültig"
						}
					}
				}
				
				this.setState({
					loading: false,
					error,
					user: json
				})
				
			})
			.catch(console.error)
  }
  
  
	validate({ password }) {
  	const errors = {};
  	let strength = 0;
  	
		if(!password) {
			errors.password = "Gib ein Password ein"
		} else {
			if(password.length > 6) strength++
			if(password.length > 10) strength++
			//if(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) strength++
			if(/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) strength++ // sonderzeichen
			if(/(?=.*\d)(?=.*[a-zA-Z])/.test(password)) strength++ // digit + character
			if(/(?=.*[a-z])(?=.*[A-Z])/.test(password)) strength++ // uppercase + lowercase
			
			if(strength < 2) {
				errors.password = "Nicht sicher genug";
			}
  	}
		this.setState({passwordStrength: strength})
		
  	return errors;
  }
	
	render() {
		const { loading, error } = this.state;
		
		return (
			<Page>
				<div className="max-w-md mx-auto">
					<Icon icon="logo-icon" className="mx-auto table mb-6" svgClassName="w-16 h-16" stroke={false}/>
					<div className="bg-white p-10 rounded-lg">
						{loading ? <div className="relative mt-10 mb-16"><LoadingIndicator /></div> : (
							error ? (
								<div>
									<h1>Passwort zurücksetzen</h1>
									<span className="mt-5 p-3 block rounded bg-red-200 leading-snug">{error.message}</span>
									<Link to="/reset-password" className="block mt-4 text-theme-500 hover:no-underline hover:text-black">Lass dir einen neuen zuschicken</Link>
								</div>
							) : (
								<div>
									{/*<h1>Neues Passwort für {user.username}</h1>*/}
									<h2 className="mt-0">Gib dein neues Passwort ein</h2>
									<Form
							      onSubmit={this.onResetPassword}
							      decorators={[focusOnError]}
							      validate={this.validate}
							      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
											<form onSubmit={handleSubmit}>
													
												{(!submitting && submitError) && <span className="mt-5 p-3 block rounded bg-red-200 leading-snug">{submitError}</span>}
												
												<FormFieldPassword name="password" label="Neues Passwort" strength={this.state.passwordStrength} />
												
												<Alert className="block leading-snug my-5 text-gray-600 text-sm">Tipp: Verwende Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen.</Alert>
												
												<Button text={!submitting ? "Passwort ändern" : "laden..."} disabled={submitting} action='submit' color={COLORS.PRIMARY} size={SIZES.LARGE} className={["block", "mt-6"]} />
											</form>
										)} 
									/>
								</div>
							)
						)}
					</div>
				</div>
			</Page>
		)
	}
}

export default ResetPassword;
