import { api, loadFromLocalStorage } from '../../helpers'
import { authActionCreators } from '../'

const LOAD_INVOICES_BEGIN 	= 'invoices/LOAD_INVOICES_BEGIN';
const LOAD_INVOICES_SUCCESS	= 'invoices/LOAD_INVOICES_SUCCESS';
const LOAD_INVOICES_ERROR 	= 'invoices/LOAD_INVOICES_ERROR';

const ADD_INVOICE_BEGIN 					= 'invoices/ADD_INVOICE_BEGIN';
const ADD_INVOICE_SUCCESS 				= 'invoices/ADD_INVOICE_SUCCESS';
const ADD_INVOICE_ERROR 					= 'invoices/ADD_INVOICE_ERROR';

const UPDATE_INVOICE_BEGIN 				= 'invoices/UPDATE_INVOICE_BEGIN';
// const UPDATE_INVOICE_SUCCESS 			= 'invoices/UPDATE_INVOICE_SUCCESS';
// const UPDATE_INVOICE_ERROR 				= 'invoices/UPDATE_INVOICE_ERROR';

// const DELETE_INVOICE_BEGIN 				= 'invoices/DELETE_INVOICE_BEGIN';
const DELETE_INVOICE_SUCCESS 			= 'invoices/DELETE_INVOICE_SUCCESS';
// const DELETE_INVOICE_ERROR 				= 'invoices/DELETE_INVOICE_ERROR';

const RESET_INVOICES = 'invoices/RESET_INVOICES';

const SET_FILTER									= 'invoices/SET_FILTER';
const SET_SORT										= 'invoices/SET_SORT';


const loadInvoicesBegin = () => ({
	type: LOAD_INVOICES_BEGIN
})

const loadInvoicesError = error => ({
	type: LOAD_INVOICES_ERROR,
	payload: error
})

const loadInvoicesSuccess = invoices => ({
	type: LOAD_INVOICES_SUCCESS,
	payload: invoices
})


const addInvoiceBegin = () => ({
	type: ADD_INVOICE_BEGIN
})

const addInvoiceSuccess = invoice => ({
	type: ADD_INVOICE_SUCCESS,
	payload: invoice
})

const addInvoiceError = error => ({
	type: ADD_INVOICE_ERROR,
	payload: error
})


const updateInvoiceSuccess = invoice => ({
	type: UPDATE_INVOICE_BEGIN,
	payload: invoice
})

const deleteInvoiceBegin = invoice => ({
	type: DELETE_INVOICE_SUCCESS,
	payload: invoice
})

const resetInvoices = () => ({
	type: RESET_INVOICES
})

const setFilter = (filter, override = false) => ({
	type: SET_FILTER,
	payload: {
		filter, 
		override
	}
})

const setSort = sort => ({
	type: SET_SORT, 
	payload: sort
})


function apiLoadInvoices() {
	// let customers = [400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410];
	// console.log("create random invoice");
	
	// for(let i = 500; i < 1000; i ++) {
	
	
	// 	api('/invoices', 'post', prepareInvoice({
	// 		filename: faker.company.companyName(),
	// 		invoicedate: dayjs(faker.date.recent(200)).format("DD.MM.YYYY"),
	// 		customerid: 10,
	// 		exported: dayjs(faker.date.recent(200)).format("DD.MM.YYYY"),
	// 		customerid: customers[Math.floor(Math.random()*customers.length)],
	// 		total: faker.commerce.price(1, 1000, 2),
	// 		invoicenumber: i,
	// 		deadline: 0
	// 	}))
	// 	.then(res => console.log(res));
	// }
		
	return dispatch => {
		dispatch(loadInvoicesBegin());
		
		return api('/invoices')
      .then(res => res.json())
      .then(json => {
      	dispatch(loadInvoicesSuccess(json));
      })
      .catch(err => dispatch(loadInvoicesError(err)))
	}
}

function apiAddInvoice(props = {}) {
	let status;
	
	return dispatch => {
		dispatch(addInvoiceBegin())
		return api('/invoices', 'post', props)
			.then(res => {
				status = res.status;
				return res.json();
			})
			.then(json => {
				if(json.error) {
					dispatch(addInvoiceError(json.error));
				} else if(status === 201) {
	      	dispatch(addInvoiceSuccess(json));
					dispatch(authActionCreators.updateUser({ user: { invoicecount: json.invoiceCount } }));
	      	return json.item;
      	}
      })
      .catch(console.log)
	}
}

function apiUpdateInvoice(id, props) {
	let status;
	
	return dispatch => {
		return api(`/invoices/${id}`, 'put', props)
			.then(res => {
				status = res.status;
				return res.json();
			})
      .then(json => {
      	if(status === 200) {
      		dispatch(authActionCreators.updateUser({ user: { currentinvoicenumber: json.currentinvoicenumber } }));
    			dispatch(updateInvoiceSuccess(json));
    			return json.item;
      	} else {
      		return json;
      	}
      })
      .catch(console.log)
	}
}


function apiDeleteInvoice(id) {
	return dispatch => {
		return api(`/invoices/${id}`, 'delete')
      .then(res => res.json())
      .then(json => {
      	dispatch(deleteInvoiceBegin({id, ...json}));
      })
      .catch(console.log)
	}
}

function dispatchSetFilter(filter, override) {
	return dispatch => {
		dispatch(setFilter(filter, override));
	}
}

function dispatchSetSort(sort) {
	return dispatch => {
		dispatch(setSort(sort));
	}
}

export const invoiceActions = {
	loadInvoices: apiLoadInvoices,
	addInvoice: apiAddInvoice,
	updateInvoice: apiUpdateInvoice,
	deleteInvoice: apiDeleteInvoice,
	resetInvoices: resetInvoices,
	setFilter: dispatchSetFilter,
	setSort: dispatchSetSort
}



export default function reducer(state = defaultState, { type, payload }) {
	let list = {};
	
	switch(type) {
		case LOAD_INVOICES_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};
			
		case LOAD_INVOICES_ERROR:
			return {
				...state,
				loading: false,
				initiated: true,
				error: payload.error,
			};
			
		case LOAD_INVOICES_SUCCESS:
			list = payload.list.reduce((acc, x) => {
				acc[x.id] = x;
				return acc;
			}, {});
			
			return {
				...state,
				loading: false,
				initiated: true,
				error: null,
				tags: payload.tags,
				list
			};
			
		case ADD_INVOICE_BEGIN:
			return {
				...state,
				loading: true,
				error: null
			};
			
		case ADD_INVOICE_SUCCESS: 
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state,
				loading: false,
				error: null,
				tags: payload.tags,
				list
			};
			
		case ADD_INVOICE_ERROR: 
			return {
				...state,
				loading: false,
				error: payload
			}
			
		case UPDATE_INVOICE_BEGIN:
			list = { ...state.list };
			list[payload.item.id] = payload.item;
			
			return {
				...state,
				loading: false,
				error: null,
				tags: payload.tags,
				list
			}
			
		case DELETE_INVOICE_SUCCESS:
			list = { ...state.list };
			delete list[payload.id];
			
			return {
				...state,
				list
			};
			
		case RESET_INVOICES: 
			return defaultState;
			
		case SET_FILTER:
			let filter = payload.override ? {
				...defaultState.filterBy,
				...payload.filter
			} : {
				...state.filterBy, 
				...payload.filter
			};
			
			if(!payload.filter.trash) filter.trash = [];
			
			Object.keys(filter).forEach(x => filter[x] = typeof filter[x] == "object" ? filter[x] : [filter[x]])
			
			return {
				...state, 
				filterBy: filter
			}
			
		case SET_SORT:
			return {
				...state, 
				sortBy: payload
			}
			
		default:
			return state;
	}
}

const savedSettings = loadFromLocalStorage('invoices') || { filterBy: {}, sortBy: {} };

const defaultState = {
	loading: false,
	initiated: false,
	path: 'invoices',
	error: null,
	tags: [],
	list: {},
	filterBy: {
		year: [],
		month: [],
		status: [],
		trash: [false],
		...savedSettings.filterBy
	},
	sortBy: {
		...savedSettings.sortBy
	},
}


// selectors

function getInvoiceById(state, id = null) {
	return state.invoices.list[id] || false;
}

function getInvoicesList(state, useFilter = {}, useSort = false, limit = null) {
	const invoices = state.invoices;
	let list = Object.values(invoices.list) || [];
	
	if(!list.length) return [];	
	
	// filter
	if(Object.keys(invoices.filterBy)) {
		let filter = { ...invoices.filterBy, ...useFilter };
		
		const filterYears = [...filter.year];
		if(filterYears.length && filter.year.indexOf('auto') !== -1) filterYears.push(new Date().getFullYear());
		
		const filterMonth = [...filter.month];
		if(filterMonth.length && filter.month.indexOf('auto') !== -1) filterMonth.push(new Date().getMonth());
		
		list = list.filter(x => {
			if(filterYears.length && filterYears.indexOf(new Date(x.invoicedate || x.created).getFullYear()) === -1) return false;
			if(filterMonth.length && filterMonth.indexOf(new Date(x.invoicedate || x.created).getMonth()) === -1) return false;
			
			if(filter.status.length && filter.status.indexOf(x.status) === -1) return false;
			if(filter.trash.length && filter.trash.indexOf(x.intrash)) return false; 
			if(!filter.trash.length && x.intrash === true) return false;

			return true;
		})
	}
	
	
	//sort
	let sort = !useSort ? invoices.sortBy : useSort;
	
	if(Object.keys(sort).length) {	
		
		let numberFields = ["status", "invoicenumber", "total"],
			stringFields = ["title"],
			dateFields = ["updated"];
			
		// string fields
		// date fields
		
		let sorter = null;
		
		if(numberFields.indexOf(sort.field) !== -1) {
			// numbers
			list.sort((x, y) => {
				if(y[sort.field] > x[sort.field]) sorter = 1;
				if(y[sort.field] < x[sort.field]) sorter = -1;
				
				// if(new Date(y.updated) > new Date(x.updated)) return 1;
				// if(new Date(y.updated) < new Date(x.updated)) return -1;
				return sorter !== null ? sorter * sort.direction : 0;
			})
		} else if(stringFields.indexOf(sort.field) !== -1) {
			// strings
			list.sort((x, y) => {
				if(!x[sort.field]) return 1 * sort.direction;
				if(!y[sort.field]) return -1 * sort.direction;
				return x[sort.field].localeCompare(y[sort.field]) * sort.direction;
			})
		} else if(dateFields.indexOf(sort.field) !== -1) {
			// dates
			list.sort((x, y) => {
				if(new Date(y[sort.field]) > new Date(x[sort.field])) sorter = -1;
				if(new Date(y[sort.field]) < new Date(x[sort.field])) sorter = 1;
				
				return sorter !== null ? sorter * sort.direction : 0;
			})
		}
	} else {
		list.sort((x, y) => {
			if(new Date(y.updated) > new Date(x.updated)) return 1;
			if(new Date(y.updated) < new Date(x.updated)) return -1;
			return 1;
		})	
	}
	
	// if(limit) list = list.slice(0, limit);
	
	return list; 
	
}

function getInvoicesByIds(state, ids = null, intrash = false) {
	return Object.values(state.invoices.list).filter(x => {
		return x.intrash === intrash && ids.indexOf(x.id) !== -1
	}) || []; 
}

function getInvoicesByCustomerId(state, customerid, intrash = false) {
	return Object.values(state.invoices.list).filter(x => {
		return x.intrash === intrash && x.customerid === customerid
	}) || [];
}

export const invoiceSelectors = {
	getInvoiceById,
	getInvoicesByIds,
	getInvoicesList,
	getInvoicesByCustomerId
}
