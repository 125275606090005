const loadFromLocalStorage = key => {
	try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) return undefined
    	
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

const saveToLocalStorage = (key, fields) => {
  try {
    const serializedState = JSON.stringify(fields);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};


export { loadFromLocalStorage, saveToLocalStorage }
