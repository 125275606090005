import React, { Component } from 'react'
import { Icon } from '../../components'
import { withRouter } from 'react-router-dom'
import { path, testCommandKey, getCommandKeySymbol } from '../../helpers'

import styles from './styles.module.scss'

const ResultTitle = ({ title, id }) => {
	return <div className="text-gray-900 flex-grow">
		<strong>{title}</strong> {id && <span className="text-gray-500">({id})</span>}
	</div>
}

const Result = ({ index, title, action, displayId, icon, onToggleSearchModal, cursor, setCursor }) => {	
	const itemClassName = "leading-tighter flex items-center p-2 rounded-lg block no-underline hover:no-underline cursor-pointer";

	return (
		<div className={[itemClassName, index === cursor ? "bg-gray-300 text-green-500" : "bg-white"].join(' ')} 
			onMouseEnter={() => setCursor(index)}
			onClick={() => {
				onToggleSearchModal(false);
				window.scrollTo(0, 0);
				action()
			}}
			>
			{icon !== "logoIcon" ?
				<div className={["flex flex-shrink-0 justify-center items-center mr-3 w-10 h-10 rounded-full", index === cursor ? "bg-gray-200" : "bg-gray-300"].join(' ')}>
					<Icon icon={icon} />
				</div> : <Icon icon={icon} className={[styles.LogoIcon, "w-10 h-10 flex-shrink-0 mr-3"].join(' ')} svgClassName="w-full h-full" />
			}
			<ResultTitle title={title} id={displayId} />
			<div className="w-4 h-4 mr-2"><Icon icon="chevronRight" svgClassName="w-full h-full" /></div>
		</div>
	)
}


class Search extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			searchterm: "",
			files: [],
			showSearchModal: false,
			results: [],
			cursor: null
		}
		
		// for(let i = 0; i < 100000; i++) {
		// 	this.state.files.push(
		// 		{
		// 			id: i,
		// 			invoiceid: 1217,
		// 			customerid: 1234,
		// 			title: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + ".pdf",
		// 			path: "https://rechnungsgenerator-files.s3.amazonaws.com/10/fd8ed801e12941bdbb25fc4f0c37cd5cff748531/Lalapa-015.pdf",
		// 			created: "2021-04-21T06:09:06.614Z",
		// 			price: null,
		// 			intrash: false,
		// 			s3key: "10/fd8ed801e12941bdbb25fc4f0c37cd5cff748531/Lalapa-015.pdf",
		// 			token: null,
		// 		});
		// }
		
		this.onUpdateSearch = this.onUpdateSearch.bind(this);
		this.onToggleSearchModal = this.onToggleSearchModal.bind(this);
		this.onEmptySearchTerm = this.onEmptySearchTerm.bind(this);
		this.SearchModal = this.SearchModal.bind(this);
		this.keyboardListeners = this.keyboardListeners.bind(this);
		this.setCursor = this.setCursor.bind(this);
	}	
	
	componentDidMount() {
		document.addEventListener("keydown", this.keyboardListeners, false);
		this.onUpdateSearch();
	}
	
	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyboardListeners, false);
	}
	
	keyboardListeners(e) {
		if(
			(testCommandKey(e) && e.keyCode === 75) // cmd/strg + k
			// || (e.shiftKey && e.keyCode === 55) // shift + 7
		) {
			e.preventDefault();
			this.onToggleSearchModal(!this.state.showSearchModal);
		} else if(this.state.showSearchModal) {
			if(e.keyCode === 27) {
				// esc
				this.onToggleSearchModal(false);
			} else if(e.keyCode === 13) {
				// enter
				const { cursor, results } = this.state;
				if(!results.length) return false;
				
				this.onToggleSearchModal(false);
				window.scrollTo(0, 0);
				results[cursor].action();
			} else if(e.keyCode === 38) {
				// up
				this.setCursor(this.state.cursor - 1);
			} else if(e.keyCode === 40) {
				// down
				this.setCursor(this.state.cursor + 1);
			}
		}
	}
	
	setCursor(cursor) {
		const { results } = this.state;
		if(!results.length) {
			this.setState({ cursor: null })
		} else if(cursor >= 0 && cursor < results.length) {
			this.setState({ cursor })
		}
	}
	
	SearchModal = props => {
		const { searchterm, results, cursor } = this.state;
		const limit = 40;
		
		return <>
			<div className={styles.SearchOverlay} onClick={() => this.onToggleSearchModal(false)}></div>
			<div className={[styles.SearchModal, "rounded-lg p-4 shadow-xl overflow-hidden"].join(" ")}>
				<div className="mb-4 w-full flex items-center leading-tighter bg-gray-300 p-2 pr-4 rounded cursor-pointer select-none justify-between">
					<Icon icon="search" className="w-8 h-8" svgClassName="w-full h-full"/>
					<input 
						type="text" 
						value={searchterm} 
						onChange={this.onUpdateSearch} 
						onFocus={e => e.target.select()}
						autoFocus={true}
						className={["p-2 w-full left-0 box-border bg-transparent", styles.SearchField].join(' ')} 
						placeholder="Suche nach Rechnungen, Kund*innen, Nummern, ..."
						/>
					<span className="text-sm text-gray-900 p-2 border bg-white rounded cursor-pointer hover:bg-gray-100" onClick={() => this.onToggleSearchModal(false)}>Esc</span>
				</div>
				
				<div className="overflow-y-auto">
					{results.slice(0, limit).map((x, i) => <Result key={i} index={i} {...x} cursor={cursor} setCursor={this.setCursor} onToggleSearchModal={this.onToggleSearchModal} />)}
					{results.length > limit && <div className="p-2 text-gray-500">Noch {results.length - limit} weitere</div>}
				</div>
			</div>
		</>
	}
	
	onUpdateSearch(e) {
		const searchterm = e ? e.target.value.toLowerCase() : "" ;
		const { invoices, customers, history } = this.props;
		
		const links = [
			{
				title: "Dashboard",
				searchstring: "Dashboard übersicht startseite home",
				link: "/",
				type: "link",
				icon: "logoIcon",
			},
			{
				title: "Rechnungen",
				searchstring: "Rechnungen invoices",
				link: "/invoices",
				type: "link",
				icon: "invoices",
			},
			{
				title: "Rechnung erstellen",
				searchstring: "Rechnung erstellen hinzufügen invoices add create erstellen",
				link: "/invoices/create",
				type: "link",
				icon: "add",
			},
			{
				title: "Dateien",
				searchstring: "Dateien files",
				link: "/files",
				type: "link",
				icon: "files",
			},
			{
				title: "Kund/innen",
				searchstring: "Kund/innen customers kunden kundinnen",
				link: "/customers",
				type: "link",
				icon: "customers",
			},
			{
				title: "Kund/in erstellen",
				searchstring: "Kund/in erstellen customers  kunden kundinnen add create erstellen",
				link: "/customers/create",
				type: "link",
				icon: "add",
			},
			{
				title: "Einstellungen",
				searchstring: "Einstellungen settings",
				link: "/settings",
				type: "link",
				icon: "settings",
			},
			{
				title: <span className="flex items-center gap-1">Einstellungen<Icon icon="arrowRight" svgClassName="w-4 h-4" />Dein Konto</span>,
				searchstring: "Einstellungen account Dein Konto abo konto mail e-mail passwort nutzername benutzer paket beanchrichtigungen notifications updates",
				link: "/settings/account",
				type: "link",
				icon: "settings",
			},
			{
				title: <span className="flex items-center gap-1">Einstellungen<Icon icon="arrowRight" svgClassName="w-4 h-4" />Firmendaten</span>,
				searchstring: "Einstellungen Firmendaten adresse anschrift kontakt daten bankverbindung steuer tax satz",
				link: "/settings/your-data",
				type: "link",
				icon: "settings",
			},
			{
				title: <span className="flex items-center gap-1">Einstellungen<Icon icon="arrowRight" svgClassName="w-4 h-4" />Rechnungen</span>,
				searchstring: "Einstellungen Rechnungen format rechnungsnummer vorlagen templates zähler",
				link: "/settings/invoices",
				type: "link",
				icon: "settings",
			},
			{
				title: <span className="flex items-center gap-1">Einstellungen<Icon icon="arrowRight" svgClassName="w-4 h-4" />Dateien</span>,
				searchstring: "Einstellungen Dateien files format dateiname",
				link: "/settings/files",
				type: "link",
				icon: "settings",
			},
			{
				title: "Platzhalter",
				searchstring: "platzhalter tags",
				link: "/tags",
				type: "link",
				icon: "info",
			},
			{
				title: "Monatsübersicht",
				searchstring: "monatsübersicht month statements",
				link: "/statements",
				type: "link",
				icon: "calendar",
			},
		];
		
		const results = [];		
	
		
		// links
		results.push(...links.reduce((filtered, x) => {
			if(
				(x.searchstring.toLowerCase().indexOf(searchterm) !== -1	)
			) {
				filtered.push({
					title: x.title,
					icon: x.icon,
					action: () => history.push(x.link)
				})
			}
			return filtered;
		}, []));
		
		// customers
		results.push(...customers.reduce((filtered, x) => {
			if(
				(x.customerid ? x.customerid.toString().indexOf(searchterm) !== -1 : false) ||
				(x.title ? x.title.toLowerCase().indexOf(searchterm) !== -1 : false) ||
				(x.company ? x.company.toLowerCase().indexOf(searchterm) !== -1 : false) ||
				(x.firstname ? x.firstname.toLowerCase().indexOf(searchterm) !== -1 : false) ||
				(x.lastname ? x.lastname.toLowerCase().indexOf(searchterm) !== -1 : false)
			) {
				filtered.push({
					displayId: x.customerid,
					title: x.title,
					icon: 'customers',
					action: () => history.push(path('customers', x.id))
				})
			}
			return filtered;
		}, []));
		
		//invoices 
		results.push(...invoices.reduce((filtered, x) => {
			if(
				(x.invoicenumber ? x.invoicenumber.toString().indexOf(searchterm) !== -1 : false) ||
				(x.title ? x.title.toLowerCase().indexOf(searchterm) !== -1 : false)
			) {
				filtered.push({
					displayId: x.invoicenumber,
					title: x.title,
					icon: 'invoices',
					action: () => history.push(path('invoices', 'edit', x.id))
				})
			}
			return filtered;
		}, []));
		
		// files
		/*results.push(...files.reduce((filtered, x) => {
			if(
				(x.id ? x.id.toString().indexOf(searchterm) !== -1 : false) ||
				(x.invoiceid ? x.invoiceid.toString().indexOf(searchterm) !== -1 : false) ||
				(x.title ? x.title.toLowerCase().indexOf(searchterm) !== -1 : false)
			) {
				filtered.push({
					id: x.id,
					displayId: x.id,
					title: x.title,
					type: 'file'
				})
			}
			return filtered;
		}, [])); */
		
		this.setState({
			cursor: 0,
			searchterm,
			results
		})
	}
	
	onEmptySearchTerm = () => {
		this.setState({
			searchterm: ""
		})
	}
	
	onToggleSearchModal = val => {
		this.setState({
			showSearchModal: val
		})
	}
	
	render() {
		const { showSearchModal } = this.state;
		
		return (
			<div>
				<div className="flex items-center leading-tighter bg-gray-300 hover:bg-gray-400 p-2 pr-4 rounded text-gray-500 cursor-pointer transition hover:text-gray-800 select-none justify-between" onClick={() => this.onToggleSearchModal(!showSearchModal)}>
					<div className="flex items-center">
						<Icon icon="search" className="mr-2 w-7 h-7" svgClassName="w-full h-full"/>
						<span className="font-medium">Suche ...</span>
					</div>
					<span className="text-sm block">{`${getCommandKeySymbol()}+K`}</span>
				</div>
				{showSearchModal ? <this.SearchModal /> : null}
			</div>
		)
	}
}


export default withRouter(Search);
