import React, { Component } from 'react'
import { Page, Tag, ScrollToTop } from '../../components'
import { getTags } from '../../helpers'

class Tags extends Component {
	
	render() {
		const tags = getTags();
		
		return (
			<Page>
				<ScrollToTop />
				<h1>Tags</h1>
				
				{tags && Object.keys(tags).map((x,i) => {
					return <div key={i}>
						<h2>{x}</h2>
						<table>
							<thead>
								<tr>
									<th>Tag</th>
									<th>Beschreibung</th>
									<th>Beispiel</th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(tags[x]).map((y,i) => {
									return <tr key={i}>
										<td><Tag tag={y} brackets={false}/></td>
										<td><span>{tags[x][y].description}</span></td>
										<td><span className="whitespace-pre">{tags[x][y].example}</span></td>
									</tr>
								})}
							</tbody>
						</table>
					</div>
				})}
				
			</Page>
		)
	}
}

export default Tags;
