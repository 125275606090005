import React, { Component } from 'react'
import Button, { COLORS, SIZES } from '../../components/button'
import { SelectionCheckbox } from '../../components'
import { Link } from 'react-router-dom'
import { path, CropTable } from '../../helpers'
import tableStyles from '../../styles/tables.module.scss'
import { Helmet } from 'react-helmet'

class InvoiceTrash extends Component {	
	render() {
		const { list, onUdpateInvoice, onDeleteInvoice, limit = 500 } = this.props;
		
		return (
			<div className="relative">
				<Helmet>
					<title></title>
				</Helmet>
				<table>
					<thead>
						<tr>
							<th className={[tableStyles.Select, tableStyles.SelectHeader].join(' ')}>
								<SelectionCheckbox isHeader={true} {...this.props} />
							</th>
							<th className={tableStyles.InvoiceNumber}>
								<span>Nr.</span>
							</th>
							<th>
								<span>Titel</span>
							</th>
							<th className={tableStyles.Actions}></th>
						</tr>
					</thead>
					<tbody className="text-gray-800">
						{list.length > 0 && list.sort((x, y) => new Date(x.updated) < new Date(y.updated) ? 1 : -1)
						.map(({ id, title, updated, invoicedate, invoicenumber, status, deadline }) => (
							<tr key={id} id={id}>
								<td className={tableStyles.Select}>
									<SelectionCheckbox id={id} {...this.props} />
								</td>
								<td className={tableStyles.InvoiceNumber}>
									<Link to={path('invoices', 'edit', id)}>{invoicenumber}</Link>
								</td>
								<td className={tableStyles.Title}>
									<CropTable>
										<Link to={path('invoices', 'edit', id)}>{title ? title : <span className="text-gray-600">Kein Titel</span>}</Link>
									</CropTable>
								</td>
								<td className={tableStyles.Actions}>
									<div>
										<Button 
											text="Wiederherstellen"
											icon="restore"
											action={() => onUdpateInvoice(id, { intrash: false })} 
											color={COLORS.SECONDARY}
											size={SIZES.SMALL}
											className={["m-0"]}/>
										<Button 
											text="Endgültig löschen" 
											icon="delete-2"
											action={() => onDeleteInvoice(id)} 
											color={COLORS.DANGER}  
											size={SIZES.SMALL}
											className="mt-0"/>
									</div>
								</td>
							</tr>	
						))}
						{!list.length &&
							<tr>
								<td colSpan={10}>
									<span>Der Papierkorb ist leer.</span>
								</td>
							</tr>}
					</tbody>
					<tfoot>
						<tr className="text-gray-500">
							<td colSpan="3" className="">
								<span><span className="text-gray-700">{list.length}</span> Rechnungen gefunden</span>
								{list.length > limit && <span className="ml-1">({limit} angezeigt)</span>}
							</td>
							<td></td>
						</tr>
					</tfoot>
				</table>
			</div>
		);
	}
}

export default InvoiceTrash;
