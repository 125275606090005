import React, { Component } from 'react'
import { TagHint } from '../../components'
import { Field } from 'react-final-form'
import formatString from 'format-string-by-pattern'

class FormFieldIban extends Component {
	render() {
		const { name, label, placeholder, className, tag } = this.props;
		
		return (
			<Field name={name} id={name} {...this.props.parse} parse={formatString("XX99 9999 9999 9999 9999 99")}>
				{({ input, meta }) => (
	        <div className={[(meta.touched && (meta.error || meta.submitError)) ? "has-error" : null, "tnum", className, 'FormField'].join(' ')} >
	        	<div className="mt-6 mb-2">
	        		<div className="flex justify-between items-end mt-2 mb-1">
	        			<div className="flex items-center flex-wrap gap-1">
			        		<label htmlFor={name} className="leading-tight my-0">
			        			{label}
			        			
			        			
			        		</label>
			        		<TagHint tag={tag} />
			        	</div>
		        		{(meta.error || meta.submitError) && meta.touched && 
		        			<span className="text-red-500 text-sm leading-tight">{meta.error || meta.submitError}</span>	
		        		}
	        		</div>
		        	
		        	<div className="relative">
	        			<input {...input} 
	        				id={name} 
	        				placeholder={placeholder || 'DE00 0000 0000 0000 0000 00'} 
	        				type="text"/>
		        	</div>
		        </div>
	        </div>
	      )}
			</Field>
		)
	}
}

export default FormFieldIban;
