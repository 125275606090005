import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { ContextMenu, Icon, Search } from '../../components'

import styles from './styles.module.scss';

class Navigation extends Component {
	render() {
		const { auth, invoices, files, customers } = this.props;
	
		return (
			<nav className={[styles.Navigation, "bg-gray-100 text-gray-700"].join(' ')}>
				<div className="flex border-b justify-between border-gray-200 px-5">
					<ul className="flex m-0 p-0 gap-8">
						<li className="block p-0">
							<NavLink exact 
								to="/" 
								activeClassName={styles.Active}
								className="flex items-center h-full hover:no-underline transform hover:scale-105">
								{/*<Icon 
									icon="home" 
									className="mr-2 text-gray-500 -mt-1"/>*/}
								{/*<img src="/logo.png" className="w-12" alt="Billu Logo"/>*/}
								<Icon icon="logoIcon" className={styles.Logo} />
								{/*<strong className="text-gray-900 hover:text-gray-700 inline-table leading-none block text-xl font-semibold">Billu</strong>*/}
							</NavLink>
						</li>
						{/*<li className="block mr-8 p-0">
							<div className="flex items-center h-full">
								<input type="text" className="bg-gray-300 text-gray-700 h-10trip p-2 rounded" placeholder="Suche ..." />
							</div>
						</li>*/}
						<li className="block p-0">
							<NavLink 
								to="/invoices" 
								activeClassName={styles.Active}
								className="flex items-center h-full hover:no-underline"
								data-text="Rechnungen">
								<Icon 
									icon="invoices"
									className="mr-2 text-gray-500" />
								<span className="inline-table leading-none block">Rechnungen</span>
							</NavLink>
						</li>
						<li className="block p-0">
							<NavLink 
								to="/files" 
								activeClassName={styles.Active}
								className="flex items-center h-full hover:no-underline"
								data-text="Dateien">
								<Icon 
									icon="files"
									className="mr-2 text-gray-500" />
								<span className="inline-table leading-none block">Dateien</span>
							</NavLink>
						</li>
						<li className="block p-0">
							<NavLink 
								to="/customers" 
								activeClassName={styles.Active}
								className="flex items-center h-full hover:no-underline"
								data-text="Kund/innen">
								<Icon 
									icon="customers"
									className="mr-2 text-gray-500" />
								<span className="inline-table leading-none block">Kund/innen</span>
							</NavLink>
						</li>
					</ul>
					
					<div className="flex">
						<ul className="flex m-0 p-0 gap-8">
							<li className="flex items-center">
								<Search invoices={invoices} files={files} customers={customers} />
							</li>
							<li className="block mr-8 p-0">
								<NavLink 
									to="/settings" 
									activeClassName={styles.Active}
									className="flex items-center h-full hover:no-underline">
									<Icon 
										icon="settings"
										className="mr-2 text-gray-500" />
									<span className="inline-table leading-none block">Einstellungen</span>
								</NavLink>
							</li>
						</ul>
						<div className="hover:no-underline">
							<ContextMenu
								trigger={<div className={styles.User}>
										<Icon icon="chevronDownSmall" className="-mr-4 -mb-5 z-10 bg-white rounded-full shadow" svgClassName="w-5 h-5"/>
										<span className={["inline-flex font-medium items-center justify-center bg-blue-300 text-blue-800 relative shadow", styles.Avatar].join(' ')}>
											<div className={["absolute top-0 left-0 w-full h-full", styles.AvatarImage, styles[`_${auth.user.id}`]].join(" ")}></div>
											<div className="uppercase text-lg">{auth.user.username.substring(0,2)}</div>
										</span>
									</div>}
								items={[
									{
										label: 'Konto',
										icon: 'account',
										link: '/settings/account'
									},
									// {
									// 	label: 'Rechnungseinstellungen',
									// 	icon: 'settings',
									// 	link: '/invoices/settings'
									// },
									// {
									// 	label: 'Einstellungen',
									// 	icon: 'settings',
									// 	link: '/settings/your-data'
									// },
									{
										label: 'Support',
										icon: 'support',
										link: 'https://billu.app/support/',
										target: '_blank'
									},
									// {
									// 	label: 'Dokumentation',
									// 	icon: 'folders',
									// 	link: '/support'
									// },
									{
										label: 'Abmelden',
										icon: 'logout',
										onClick: () => this.props.logout()
									},
									{
										label: 'Datenschutzerklärung',
										icon: 'privacy',
										link: '/privacy-policy'
									}
								]}>
								<div className="p-3 border-b">
									<strong className="text-lg">{auth.user.username}</strong>
									<span className="block text-gray-600">{auth.user.email}</span>
								</div>
							</ContextMenu>
						</div>
					</div>
				</div>
			</nav>
		)
	}
}

export default Navigation;
